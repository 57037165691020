@import 'variables';

/* Payments page */
.gac-payments-page {
  padding-top: 80px;
  padding-bottom: 130px;
  &.gac-payments-empty {
    display: flex;
    flex-direction: column;
    &.gac-mobile-view {
      padding-bottom: 15px;
    }
  }
  .gac-empty-state {
    padding: 60px 0;
  }
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
  }
  .gac-payments-title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
  }
  .gac-radio-group {
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  .gac-search {
    margin-bottom: 24px;
  }
  .DayPickerInput-Overlay {
    bottom: auto;
    left: auto;
    top: 48px;
    right: 0;
  }
  .gac-range-wrap {
    position: relative;
    width: 270px;
    margin-left: 16px;
    .gac-select {
      width: 100%;
    }
    .gac-daypicker-wrap {
      position: absolute;
      top: 48px;
      right: 0;
      margin: 0;
      background-color: $white-color;
      box-shadow: 0 10px 100px 0 rgba(159, 177, 188, 0.3);
      z-index: 5;
    }
    .gac-input {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 55px);
      height: 48px;
      padding: 0 0 0 16px;
      border: 1px solid $border-color;
      border-right: 0;
      border-radius: 2px 0 0 2px;
      color: $dark-text-color;
      background-color: $white-color;
      font-size: 15px;
      box-shadow: none!important;
      z-index: 5;
      cursor: pointer;
      user-select: none;
    }
  }
  .gac-download-csv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-left: 16px;
    border: 1px solid $border-color;
    border-radius: 2px;
    background-color: $white-color;
    cursor: pointer;
    i {
      width: 20px;
      height: 20px;
      background: url("../../images/sprite.svg") -135px -50px;
    }
  }
}

.gac-accounts-list {
  display: flex;
  align-items: flex-start;
  min-height: 31px;
  padding: 12px 0 0 0;
  margin-bottom: 35px;
  .gac-account-details & {
    padding-top: 5px;
    min-height: 26px;
    margin-bottom: 33px;
  }
}
.gac-breadcrumbs-item {
  position: relative;
  line-height: 18px;
  margin-right: 6px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  &:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 6px);
    height: 1px;
    background-color: #1E1E1E;
    content: "";
  }
  &:hover {
    color: $dark-text-color;
  }
}

.gac-payments-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  .gac-payments-info-item {
    position: relative;
    line-height: 22px;
    margin-right: 24px;
    & > div:first-child {
      margin-bottom: 8px;
      color: $light-text-color;
    }
    p {
      margin-bottom: 4px;
    }
    .gac-add-bundle {
      position: absolute;
      right: -64px;
      top: 6px;
      width: 40px;
      height: 40px;
      border: 0;
      background: $green-color;
      border-radius: 100%;
      cursor: pointer;
      i {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 16px;
        height: 16px;
        margin: -8px 0 0 -8px;
        background: url('../../images/sprite.svg') no-repeat -65px -370px;
      }
    }
    .gac-no-bundles {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.gac-payments {
  border: 1px solid $border-color;
  border-bottom: 0;
  border-radius: 2px;
  background-color: $white-color;
  .gac-payments-head {
    display: flex;
    border-bottom: 1px solid $border-color;
    span {
      height: 71px;
      line-height: 24px;
      padding: 23px 20px 24px 0;
      &:first-child {
        padding-left: 23px;
      }
      &[data-col="date"] {
        flex: 0 0 154px;
      }
      &[data-col="account"] {
        flex: 0 0 140px;
      }
      &[data-col="details"] {
        flex: 1 1 auto;
      }
      &[data-col="credit"] {
        flex: 0 0 130px;
      }
      &[data-col="debit"] {
        flex: 0 0 130px;
      }
      &[data-col="balance"] {
        flex: 0 0 100px;
      }
    }
  }
  .gac-payments-item {
    display: flex;
    &:last-child {
      border-bottom: 1px solid $border-color;
    }
    span {
      display: flex;
      align-items: center;
      line-height: 32px;
      padding: 19px 20px 19px 0;
      a {
        font-size: 15px;
        margin: 0 4px 0;
        &:first-of-type {
          margin-left: 4px;
        }
        &.gac-payments-invoice {
          margin: 0;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &[data-col="date"] {
        padding-left: 23px;
        flex: 0 0 154px;
      }
      &[data-col="account"] {
        flex: 0 0 140px;
      }
      &[data-col="details"] {
        flex: 1 1 auto;
        line-height: 22px;
        padding: 13px 0 13px 0;
        margin-right: 20px;
        //overflow: hidden;
        .gac-item-topic {
          //display: flex;
          //flex-wrap: wrap;
          //align-items: center;
          font-size: 15px;
          color: rgba(111,111,112,0.8);
          .gac-project-type {
            float: left;
            margin-top: 3px;
            margin-right: 4px;
          }
        }
      }
      &[data-col="credit"] {
        flex: 0 0 130px;
        color: $green-color;
      }
      &[data-col="debit"] {
        flex: 0 0 130px;
        color: $light-text-color;
      }
      &[data-col="balance"] {
        flex: 0 0 100px;
        color: $light-text-color;
      }
    }
  }
}

/* Accounts page */
.gac-accounts-page {
  padding-top: 88px;
  padding-bottom: 130px;
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
  }
  &.gac-general-settings, &.gac-pt-80 {
    &.gac-mobile-view {
      padding-top: 80px;
    }
  }
  .gac-sub-plans {
    background-color: transparent;
  }
}

.gac-accounts-container {
  display: flex;
  flex-wrap: wrap;
  .gac-accounts-item {
    position: relative;
    display: flex;
    flex: 0 0 262.5px;
    align-items: center;
    max-width: 262.5px;
    min-height: 92px;
    padding: 0 46px 0 15px;
    margin: 0 0 30px 30px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    text-decoration: none;
    //&:hover {
    //  box-shadow: 0 30px 70px 0 rgba(159, 177, 188, 0.28);
    //}
    &:nth-child(4n+1) {
      margin-left: 0;
    }
    &.gac-add-new {
      padding: 11px 0 0 0;
      flex-direction: column;
      align-items: center;
      i {
        position: relative;
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
        background: $green-color;
        border-radius: 100%;
        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -8px 0 0 -8px;
          width: 16px;
          height: 16px;
          background: url("../../images/sprite.svg") -65px -370px;
          content: "";
        }
      }
      span {
        line-height: 22px;
        font-size: 15px;
        color: $dark-text-color;
      }
    }
    img {
      width: 48px;
      height: auto;
      border-radius: 100%;
      margin-right: 16px;
    }
    .gac-no-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 48px;
      height: 48px;
      margin-right: 16px;
      border-radius: 100%;
      background-color: #e9eff3;
      i {
        width: 14px;
        height: 16px;
        background: url("../../images/sprite.svg") -180px -460px;
      }
    }
    .gac-item-title {
      flex: 1 1 auto;
      line-height: 24px;
      color: $dark-text-color;
      font-weight: 600;
      font-size: 18px;
    }
    .gac-item-desc {
      flex: 1 1 auto;
      overflow: hidden;
      .gac-item-name {
        line-height: 26px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .gac-item-role {
        font-size: 12px;
        line-height: 16px;
        color: $light-text-color;
        text-transform: capitalize;
      }
    }
    .gac-edit {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 13px;
      height: 13px;
      background: url("../../images/sprite.svg") -25px -480px;
    }
    &:hover {
      //border-color: $white-color;
      .gac-edit {
        display: block;
      }
    }
  }
}
.gac-account-wrapper {
  display: flex;
  align-items: flex-start;
  .gac-account-sidebar {
    flex: 0 0 210px;
    padding-top: 16px;
    .gac-account-pages {
      list-style: none;
      li {
        position: relative;
        padding-left: 32px;
        margin-bottom: 24px;
        line-height: 24px;
        color: $light-text-color;
        transition: color .2s ease;
        cursor: default;
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          svg path {
            transition: fill .2s ease;
          }
        }
        a {
          color: $light-text-color;
          font-size: 15px;
          text-decoration: none;
        }
        &:not(.gac-disabled):hover, &.gac-active {
          color: $green-color;
          a {
            color: $green-color;
          }
          i {
            svg path {
              fill: $green-color;
            }
          }
        }
        &.gac-disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .gac-account-content {
    flex: 1 1 auto;
  }
  .gac-create-account-from {
    flex: 1 1 auto;
    padding: 32px 31px 32px 31px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.gac-role-strategist,
    &.gac-role-manager {
      padding-bottom: 8px;
    }
    .gac-form-row {
      flex: 1 1 50%;
      max-width: calc(50% - 15px);
      margin-bottom: 24px;
      &.gac-row-with-pass {
        flex: 1 1 100%;
        max-width: 100%;
      }
      &.gac-row-with-logo {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;
        max-width: inherit;
        margin-bottom: 0;
        .gac-form-field {
          margin-bottom: 24px;
          &:nth-child(1),
          &:nth-child(2){
            margin-right: 30px;
          }
        }
        .gac-error {
          width: 100%;
          margin-top: -15px;
          margin-bottom: 24px;
        }
      }
      &.gac-state-zip-code-row {
        display: flex;
      }

      .gac-form-field {
        &.gac-billing-state {
          max-width: 50%;
          margin-right: 24px;
        }
        &.gac-zip-code {
          max-width: 25%;
        }
        &.gac-currency-field {
          .gac-form-label {
            line-height: 16px;
            margin-bottom: 21px;
            font-size: 12px;
          }
        }
        &.gac-notes-field {
          margin-top: 24px;
        }
        &.gac-timezone-field {
          margin-bottom: 24px;
        }
        .gac-form-label {
          display: block;
          line-height: 24px;
          color: $dark-text-color;
          font-size: 14px;
          &.gac-label-with-hint {
            display: flex;
            .gac-hint {
              margin-left: 5px;
            }
          }
        }
        .gac-form-input {
          position: relative;
          input {
            width: 100%;
            height: 48px;
            padding: 0 16px;
            border: 1px solid $border-color;
            background-color: $white-color!important;
            color: $dark-text-color;
            border-radius: 2px;
            &:disabled {
              border-color: $disabled-color;
              color: #cfd0d6;
            }
            &.gac-invalid {
              border-color:$red-color;
            }
          }
          .gac-pass-icon-1 {
            position: absolute;
            right: 18px;
            top: 50%;
            width: 20px;
            height: 17px;
            background: url('../../images/sprite.svg') -50px -430px;
            transform: translateY(-50%);
            cursor: pointer;
            &.gac-visible {
              background-position: -70px -430px;
            }
          }
          .gac-warning {
            line-height: 16px;
            margin-top: 9px;
            font-size: 12px;
          }
          .gac-validate-cname-btn {
            position: absolute;
            right: -122px;
            top: 0;
            height: 48px;
            min-width: 98px;
            line-height: 48px;
            padding: 0 20px;
            background: $green-color;
            color: #ffffff;
            font-size: 15px;
            border-radius: 2px 0 0 2px;
            cursor: pointer;
            text-decoration: none;
            text-align: center;
            &.gac-confirmed {
              top: 16px;
              right: -100px;
              padding: 0;
              line-height: 16px;
              height: 16px;
              font-size: 12px;
              background: transparent;
              color: #43b993;
              cursor: default;
            }
          }
        }
        .gac-form-textarea {
          textarea {
            min-width: 100%;
            max-width: 100%;
            min-height: 186px;
            line-height: 23px;
            padding: 12px 16px 10px;
            border: 1px solid $border-color;
            color: $dark-text-color;
            border-radius: 2px 2px 0 0;
            vertical-align: top;
            &:disabled {
              background-color: white;
              border-color: $disabled-color;
              color: #cfd0d6;
            }
            &.gac-invalid {
              border-color: $red-color;
            }
            &:focus {
              outline: none;
            }
          }
        }
        .gac-password-restored {
          display: inline-block;
          height: 18px;
          line-height: 18px;
          margin-top: 7px;
        }
        .gac-change-password {
          display: inline-block;
          height: 18px;
          line-height: 18px;
          margin-top: 7px;
          border-bottom: 1px solid $dark-text-color;
          cursor: pointer;
          &:hover {
            border-bottom: 0;
          }
        }
        .gac-form-currency {
          display: flex;
          line-height: 22px;
          color: $dark-text-color;
          .gac-hint {
            position: relative;
            top: 1px;
            margin-left: 10px;
            color: $light-text-color;
            .gac-hint-wrap .gac-hint-text {
              width: 310px;
            }
          }
        }
      }
      .gac-error {
        line-height: 16px;
        margin-top: 9px;
        font-size: 12px;
      }
    }
    .gac-form-own-balance {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      margin-bottom: 24px;
      .gac-form-label {
        width: 100%;
        line-height: 24px;
        margin-bottom: 10px;
        color: $dark-text-color;
        font-size: 14px;
      }
      .gac-form-radio-label {
        display: flex;
        align-items: center;
        line-height: 22px;
        margin-bottom: 10px;
        margin-right: 10px;
        cursor: pointer;
        input {
          display: none;
        }
        span {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background: url('../../images/sprite.svg') no-repeat -110px -290px;
        }
        input:checked + span {
          background-position: -130px -290px;
        }
        .gac-hint {
          margin-left: 5px;
        }
      }
      &__currency {
        flex: 1 1 50%;
        max-width: calc(50% - 15px);
        margin-left: auto;
        margin-bottom: 10px;
      }
    }
    .gac-form-cname {
      //display: flex;
      //align-items: center;
      line-height: 22px;
      margin-bottom: 24px;
      flex: 0 0 100%;
      a {
        position: relative;
        top: 2px;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 4px;
        background: url("../../images/sprite.svg") -170px -345px;
        cursor: pointer;
        text-indent: -9999999px;
      }
    }
    .gac-form-confirm {
      flex: 0 0 100%;
      margin-bottom: 24px;
    }
    .gac-form-btns {
      display: flex;
      width: 100%;
      padding-top: 16px;
      .gac-btn {
        min-width: 192px;
        margin-right: 20px;
      }
      .gac-btn-v2 {
        min-width: 200px;
      }
    }
  }
  .gac-create-user-page {
    flex: 1 1 auto;
    .gac-create-account-from {
      flex: 0 0 auto;
      margin-bottom: 40px;
    }
    .gac-users-list {
      margin-bottom: 20px;
    }
    .gac-roles-list {
      margin-bottom: 50px;
    }
    .gac-title {
      line-height: 26px;
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 16px;
    }
    .gac-btn-wrap {
      display: flex;
      justify-content: center;
      .gac-btn {
        margin: 0;
        min-width: 192px;
      }
    }
  }
  .gac-users-page {
    flex: 1 1 auto;
    .gac-users-title {
      line-height: 26px;
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 16px;
    }
  }
  .gac-clients-page {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: flex-start;
    & > div:nth-child(3n+1) {
      margin-left: 0;
    }
    .gac-client {
      position: relative;
      width: calc(33.3333% - 20px);
      margin: 0 0 20px 30px;
      padding: 16px 16px 0;
      background-color: $white-color;
      border: 1px solid $border-color;
      border-radius: 2px;
      .gac-client-head {
        display: flex;
        align-items: center;
        padding-top: 5px;
        margin-bottom: 15px;
        img {
          width: 48px;
          height: auto;
          border-radius: 100%;
          margin-right: 16px;
        }
        .gac-no-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 48px;
          height: 48px;
          margin-right: 16px;
          border-radius: 100%;
          background-color: #e9eff3;
          i {
            width: 14px;
            height: 16px;
            background: url("../../images/sprite.svg") -180px -460px;
          }
        }
        .gac-client-name {
          flex: 1 1 auto;
          overflow: hidden;
          span {
            display: block;
            line-height: 26px;
            margin-bottom: 2px;
            font-size: 18px;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          p {
            font-size: 12px;
          }
        }
      }
      .gac-client-info {
        margin-bottom: 24px;
        p {
          position: relative;
          padding-left: 25px;
          line-height: 22px;
          margin-bottom: 4px;
          color: $light-text-color;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:last-child {
            margin-bottom: 0;
          }
          i {
            position: absolute;
            left: 0;
            top: 50%;
            width: 14px;
            height: 16px;
            margin-top: -8px;
            background: url('../../images/sprite.svg') 0 -630px;
            &.gac-icon-2 {
              background-position: -15px -630px;
            }
            &.gac-icon-3 {
              background-position: -30px -630px;
            }
          }
        }
      }
      .gac-client-activity {
        .gac-activity {
          margin-bottom: 16px;
          span {
            display: block;
            line-height: 16px;
            margin-bottom: 2px;
            font-size: 12px;
            color: $light-text-color;
          }
          p {
            line-height: 22px;
          }
        }
      }
      .gac-edit {
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 23px;
        height: 23px;
        border: 5px solid $white-color;
        background: url("../../images/sprite.svg") -25px -480px;
        background-clip: padding-box;
        cursor: pointer;
        &.is-editing {
          top: 15px;
          right: 15px;
          width: 24px;
          height: 24px;
          border-width: 4px;
          background-position: -45px -630px;
        }
      }
    }
    .gac-create-client-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(33.3333% - 20px);
      margin: 0 0 20px 30px;
      padding: 11px 16px;
      background-color: $white-color;
      border: 1px solid $border-color;
      border-radius: 2px;
      cursor: pointer;
      i {
        position: relative;
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
        background: $green-color;
        border-radius: 100%;
        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -8px 0 0 -8px;
          width: 16px;
          height: 16px;
          background: url("../../images/sprite.svg") -65px -370px;
          content: "";
        }
      }
      span {
        line-height: 22px;
        font-size: 15px;
        color: $dark-text-color;
        user-select: none;
      }
    }
    .gac-form-wrap {
      position: relative;
      width: calc(33.3333% - 20px);
      margin: 0 0 20px 30px;
      padding: 16px 16px 0;
      background-color: $white-color;
      border: 1px solid $border-color;
      border-radius: 2px;
      .gac-close {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 24px;
        height: 24px;
        border: 4px solid $white-color;
        background: url("../../images/sprite.svg") -45px -630px;
        cursor: pointer;
        z-index: 1;
      }
      .gac-form-title {
        line-height: 24px;
        margin-bottom: 24px;
      }
      .gac-form {
        padding-bottom: 24px;
        .gac-field {
          margin-bottom: 8px;
          &.gac-field-photo {
            margin-bottom: 24px;
          }
          &.gac-default {
            .gac-hint {
              & > span {
                color: $light-text-color;
              }
            }
          }
          label {
            display: flex;
            line-height: 24px;
            font-size: 14px;
            color: $light-text-color;
            .gac-hint {
              margin-left: 5px;
            }
          }
          input {
            width: 100%;
            height: 48px;
            padding: 0 16px;
            border: 1px solid $border-color;
            background-color: $white-color !important;
            color: $dark-text-color;
            border-radius: 2px;
            &.gac-invalid {
              border-color: $red-color;
            }
          }
          .gac-select {
            transition: none;
          }
          p {
            line-height: 18px;
            font-size: 15px;
            color: $light-text-color;
          }
        }
        .gac-error {
          line-height: 16px;
          padding-top: 1px;
          margin-bottom: 8px;
          font-size: 12px;
        }
        .gac-btn,
        .gac-btn-v2 {
          display: inline-block;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  .gac-role-page {
    flex: 1 1 auto;
    .gac-role-title {
      line-height: 26px;
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 16px;
    }
  }
}
.gac-form-radio-input {
  margin-bottom: 14px;
  flex: 0 0 100%;
  label {
    display: flex;
    line-height: 22px;
    margin-bottom: 10px;
    cursor: pointer;
    input {
      display: none;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      margin-top: 1px;
    }
  }
  ul {
    padding-left: 28px;
    li {
      position: relative;
      padding-left: 16px;
      margin-bottom: 6px;
      line-height: 22px;
      &:before {
        position: absolute;
        left: 0;
        top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $grey-color;
        content: "";
      }
    }
  }
}

.gac-users-list {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 60px;
  .gac-user-item {
    display: flex;
    position: relative;
    align-items: center;
    width: calc(33.3333% - 20px);
    max-width: 290px;
    min-height: 92px;
    padding: 0 66px 0 15px;
    margin: 0 0 20px 30px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    &.gac-invalid {
      border-color: $red-color;
    }
    //&.gac-disabled {
    //  opacity: 0.5;
    //}
    &.gac-add-new {
      padding: 11px 0 0 0;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      i {
        position: relative;
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
        background: $green-color;
        border-radius: 100%;
        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -8px 0 0 -8px;
          width: 16px;
          height: 16px;
          background: url("../../images/sprite.svg") -65px -370px;
          content: "";
        }
      }
      span {
        line-height: 22px;
        font-size: 15px;
        color: $dark-text-color;
      }
    }
    &.gac-close {
      padding: 12px 0 0 0;
      border: 0;
      flex-direction: column;
      align-items: center;
      background: $green-color;
      cursor: pointer;
      i {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
        //border: 1px solid $border-color;
        background-color: $white-color;
        border-radius: 100%;
        &:before {
          //position: absolute;
          //left: 50%;
          //top: 50%;
          //margin: -8px 0 0 -8px;
          //width: 16px;
          //height: 16px;
          //background: url("../../images/sprite.svg") -119px -314px;
          //content: "";
        }
      }
      span {
        line-height: 22px;
        font-size: 15px;
        color: $white-color;
      }
    }
    img {
      width: 48px;
      height: auto;
      border-radius: 100%;
      margin-right: 16px;
    }
    .gac-no-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 48px;
      height: 48px;
      margin-right: 16px;
      border-radius: 100%;
      background-color: #e9eff3;
      i {
        width: 14px;
        height: 16px;
        background: url("../../images/sprite.svg") -180px -460px;
      }
    }
    .gac-item-title {
      flex: 1 1 auto;
      line-height: 24px;
      color: $dark-text-color;
      font-weight: 600;
      font-size: 18px;
    }
    .gac-user-desc {
      flex: 1 1 auto;
      overflow: hidden;
      .gac-user-name {
        line-height: 26px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .gac-user-role {
        font-size: 12px;
        line-height: 16px;
        color: $light-text-color;
        text-transform: capitalize;
      }
    }
    .gac-edit-btn {
      position: absolute;
      right: 16px;
      top: 50%;
      margin-top: -20px;
    }
  }
}

.gac-users-page {
  .gac-writers-list {
    .gac-writer-item {
      position: relative;
      width: calc(33.3333% - 20px);
      min-height: 92px;
      padding: 18px 15px 1px 15px;
      margin: 0 0 20px 0;
      border: 1px solid $border-color;
      background-color: $white-color;
      border-radius: 2px;
      &:hover {
        .gac-writer-item-btns {
          visibility: visible;
          opacity: 1;
          z-index: 2;
        }
      }
      //&.gac-disabled {
      //  opacity: 0.5;
      //}
      &.gac-add-new {
        display: flex;
        min-height: 92px;
        padding: 11px 0 0 0;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        i {
          position: relative;
          width: 40px;
          height: 40px;
          margin-bottom: 8px;
          background: $green-color;
          border-radius: 100%;
          &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -8px 0 0 -8px;
            width: 16px;
            height: 16px;
            background: url("../../images/sprite.svg") -65px -370px;
            content: "";
          }
        }
        span {
          line-height: 22px;
          font-size: 15px;
          color: $dark-text-color;
        }
      }
      &.gac-close {
        display: flex;
        padding: 12px 0 0 0;
        min-height: 92px;
        flex-direction: column;
        align-items: center;
        border: 0;
        background: $green-color;
        cursor: pointer;
        i {
          position: relative;
          width: 40px;
          height: 40px;
          //margin-bottom: 8px;
          //border: 1px solid $border-color;
          &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -9px 0 0 -11px;
            width: 22px;
            height: 18px;
            background: url("../../images/sprite.svg") 0 -500px;
            content: "";
          }
        }
        span {
          line-height: 22px;
          padding-bottom: 10px;
          font-size: 15px;
          color: $white-color;
          text-align: center;
        }
      }
      .gac-writer-item-head {
        display: flex;
        margin-bottom: 17px;
        .gac-writer-item-avatar {
          flex: 0 0 48px;
          position: relative;
          height: 48px;
          margin-right: 17px;
          background-color: #e9eff3;
          border-radius: 100%;
          overflow: hidden;
          &.gac-no-avatar {
            &:before {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 14px;
              height: 16px;
              transform: translate(-50%,-50%);
              background: url('../../images/sprite.svg') no-repeat -13px -79px;
              content: '';
            }
          }
          i {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 14px;
            height: 10px;
            margin: -5px 0 0 -7px;
            background: url('../../images/sprite.svg') no-repeat -111px -373px;
          }
        }
        .gac-writer-info {
          position: relative;
          flex: 1 1 auto;
          .gac-writer-name-wrap {
            display: flex;
            .gac-writer-item-name {
              max-width: 100%;
              line-height: 24px;
              margin-right: 14px;
              font-size: 18px;
              font-weight: 600;
              overflow: hidden;
            }
          }
          .gac-writer-icons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 12px;
            & > * {
              margin-top: 6px;
            }
            .gac-writer-level {
              position: relative;
              display: flex;
              line-height: 15px;
              margin-right: 10px;
              &.pro {
                i {
                  background: url('../../images/sprite.svg') no-repeat 0 -455px;
                }
              }
              &.expert {
                i {
                  background: url('../../images/sprite.svg') no-repeat -20px -455px;
                }
              }
              i {
                width: 16px;
                height: 15px;
                margin-right: 6px;
              }
              span {
                //display: none;
                //min-width: 40px;
                //text-align: center;
                //position: absolute;
                //top: -33px;
                //left: 50%;
                //line-height: 20px;
                //padding: 2px 10px 3px;
                //background-color: $dark-text-color;
                //color: $white-color;
                //font-size: 12px;
                //white-space: nowrap;
                //border-radius: 4px;
                //transform: translateX(-50%);
                //&:before {
                //  position: absolute;
                //  left: 50%;
                //  bottom: -6px;
                //  margin-left: -6px;
                //  width: 0;
                //  height: 0;
                //  border-left: 6px solid transparent;
                //  border-right: 6px solid transparent;
                //  border-top: 6px solid $dark-text-color;
                //  content: "";
                //}
              }
            }
            .gac-writer-project-count {
              display: flex;
              align-items: center;
              line-height: 15px;
              margin-right: 10px;
              i {
                width: 12px;
                height: 15px;
                margin-right: 6px;
                background: url('../../images/sprite.svg') no-repeat 0 -410px;
              }
            }
            .gac-writer-project-rating {
              display: flex;
              align-items: center;
              line-height: 15px;
              i {
                width: 16px;
                height: 15px;
                margin-right: 6px;
                background: url('../../images/sprite.svg') -15px -410px;
              }
            }
            .gac-writer-status {
              position: relative;
              top: 2px;
              flex: 0 0 20px;
              height: 18px;
              margin: 0 0 0 8px;
              background: url('../../images/sprite.svg') no-repeat -150px -390px;
              cursor: pointer;
              &[data-status="favorite"] {
                background-position: -175px -390px;
              }
            }
          }
        }
      }
      .gac-writer-notes {
        line-height: 22px;
        padding-bottom: 13px;
        color: $light-text-color;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .gac-writer-notes + .gac-writer-notes {
        margin-top: -5px;
      }
      .gac-writer-notes-field {
        width: 100%;
        margin-bottom: 13px;
        padding: 6px 10px 6px 10px;
        line-height: 20px;
        border: 1px solid $border-color;
        border-radius: 2px;
        color: $light-text-color;
        font-size: 15px;
        &:focus {
          outline: none;
        }
      }
      .gac-writer-item-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -1px;
        top: -1px;
        bottom: -1px;
        right: -1px;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        transition: opacity .3s ease;
        .gac-btns-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $green-color;
          opacity: 0.7;
          border-radius: 2px;
        }
        span {
          position: relative;
          width: 40px;
          height: 40px;
          margin: 0 15px;
          background-color: #ffffff;
          border-radius: 100%;
          cursor: pointer;
          i {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 50%;
            top: 50%;
            margin: -10px 0 0 -10px;
            background: url('../../images/sprite.svg') -100px -490px;
            &.gac-icon-2 {
              background-position: -120px -490px;
            }
            &.gac-icon-3 {
              width: 21px;
              height: 21px;
              margin-top: -11px;
              background-position: -140px -490px;
            }
          }
          &[data-status="favorite"] {
            i.gac-icon-2 {
              background-position: -175px -389px;
            }
          }
        }
      }
      .gac-edit-btn {
        flex: 0 0 40px;
        margin-left: 16px;
      }
    }
  }
}

.gac-roles-list {
  .gac-role-item {
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    &:last-child {
      margin-bottom: 0;
    }
    .gac-role-name {
      display: flex;
      align-items: center;
      width: 263px;
      padding-left: 31px;
      min-height: 90px;
      margin-right: 33px;
      border: 1px solid $border-color;
      background-color: $white-color;
      border-radius: 2px;
      color: $light-text-color;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      i {
        width: 14px;
        height: 10px;
        margin-left: 14px;
        background: url("../../images/sprite.svg") -111px -373px;
      }
    }
    .gac-role-desc {
      flex: 1 1 auto;
      ul {
        li {
          position: relative;
          line-height: 24px;
          padding-left: 17px;
          margin-bottom: 2px;
          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            width: 10px;
            height: 8px;
            margin-top: -4px;
            background: url("../../images/sprite.svg") -80px -480px;
            content: "";
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &.gac-active {
      .gac-role-name {
        background: $green-color;
        border-color: transparent;
        color: $white-color;
      }
    }
    &.gac-invalid {
      .gac-role-name {
        border-color: $red-color;
      }
    }
    &:not(.gac-active).gac-disabled {
      .gac-role-name {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}

/* User Logo */
.gac-user-logo {
  display: flex;
  &.gac-logo-round {
    .gac-logo-wrap {
      img {
        border-radius: 100%;
      }
    }
    .gac-no-logo {
      border-radius: 100%;
    }
  }
  .gac-logo-wrap {
    position: relative;
    padding: 20px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    cursor: pointer;
    .gac-no-logo {
      position: relative;
      background-color: $disabled-color;
      i {
        position: absolute;
        width: 14px;
        height: 16px;
        top: 50%;
        left: 50%;
        background: url("../../images/sprite.svg") -180px -460px;
        margin: -8px 0 0 -7px;
      }
    }
    .gac-user-logo-btns {
      display: flex;
      position: absolute;
      right: 4px;
      top: 4px;
      visibility: hidden;
      opacity: 0;
      transition: opacity .3s ease;

      span {
        width: 22px;
        height: 22px;
        margin-left: 4px;
        border-radius: 2px;
        cursor: pointer;
        background: url("../../images/sprite.svg") -160px -490px;
        &.gac-btn-edit {
          background-color: $border-color;
          background-position: -161px -490px;
        }
        &.gac-btn-delete {
          background-color: $red-color;
          background-position: -183px -490px;
        }
      }
    }
    &.gac-invalid {
      border-color: $red-color;
    }
    &:hover {
      .gac-user-logo-btns {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

/* Color picker */
.gac-color-picker {
  position: relative;
  .gac-remove {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    background-color: $red-color;
    border-radius: 100%;
    color: $white-color;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
  .gac-color-picker-value {
    width: 50px;
    height: 36px;
    border: 1px solid $border-color;
    border-radius: 2px;
    cursor: pointer;
    &.gac-green {
      background: $green-color;
    }

  }
  .chrome-picker {
    position: absolute;
    left: 58px;
    top: 1px;
    z-index: 2;
  }
  .gac-remove {

  }
}
