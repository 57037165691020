@import 'variables';

.gac-cart {
  flex: 0 0 330px;
  .gac-cart-wrapper {
    padding: 0 15px 20px; // Decided to leave 20px
    margin-bottom: 8px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
  }
  .gac-cart-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 72px;
    color: $dark-text-color;
    font-size: 20px;
    font-weight: bold;
    .gac-clear {
      position: relative;
      line-height: 24px;
      padding: 0 1px;
      background: $green-color;
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
      border-radius: 2px;
      user-select: none;
      .gac-btn-overlay {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $white-color;
        z-index: 1;
      }
      span {
        position: relative;
        z-index: 2;
      }
    }
    .gac-empty-cart {
      width: 100%;
      display: block;
      position: absolute;
      bottom: -63px;
      left: 50%;
      line-height: 22px;
      padding: 8px 16px 12px;
      background-color: $dark-text-color;
      color: $white-color;
      font-size: 15px;
      font-weight: normal;
      border-radius: 4px;
      transform: translateX(-50%);
      z-index: 20;
      a {
        color: $white-color;
        font-size: 15px;
        font-weight: normal;
        &:hover {
          text-decoration: none;
        }
      }
      &:before {
        position: absolute;
        left: 50%;
        top: -6px;
        margin-left: -9px;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 6px solid $dark-text-color;
        content: "";
      }
    }
  }
  .gac-cart-projects {
    margin-bottom: 8px;
    .gac-cart-project {
      padding: 8px 16px 6px;
      margin-bottom: 8px;
      border: 1px solid $border-color;
      border-radius: 2px;
      cursor: pointer;
      .gac-cart-project-row {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        &:nth-child(1) {
          margin-bottom: 1px;
        }
        .gac-cart-project-type {
          position: relative;
          padding-left: 20px;
          flex: 1 1 auto;
          margin-right: 10px;
          color: $dark-text-color;
          font-size: 18px;
          font-weight: 600;
          .gac-project-type {
            position: absolute;
            left: 0;
            top: 4px;
          }
        }
        .gac-cart-project-topic {
          max-width: 230px;
          color: $light-text-color;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
        .gac-cart-project-more {
          position: relative;
          margin-left: 10px;
          i {
            display: block;
            width: 24px;
            height: 24px;
            background: url('../../images/sprite.svg') no-repeat -170px -290px;
            cursor: pointer;
          }
          ul {
            position: absolute;
            right: -17px;
            top: 21px;
            background-color: $white-color;
            border: 1px solid $border-color;
            box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
            z-index: 10;
            li {
              display: block;
              line-height: 40px;
              padding: 0 30px 0 16px;
              background-color: transparent;
              color: $light-text-color;
              transition: color .3s ease, background-color .3s ease;
              white-space: nowrap;
              cursor: pointer;
              &.gac-more-delete {
                background: $green-color;
                position: relative;
                .gac-btn-overlay {
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background-color: $white-color;
                  z-index: 1;
                }
                span {
                  position: relative;
                  z-index: 2;
                }
                &.gac-hover {
                  color: $green-color;
                  .gac-btn-overlay {
                    background-color: $border-color;
                  }
                }
              }
              &:hover {
                color: $green-color;
                background-color: $border-color;
                &.gac-more-delete {
                  .gac-btn-overlay {
                    background-color: $border-color;
                  }
                }
              }
            }
          }
        }
      }
      &.gac-active {
        background-color: $border-color;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .gac-cart-no-projects {
    min-height: 65px;
    border: 2px dashed $border-color;
    border-radius: 2px;
  }
  .gac-add-project {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    border: 2px dashed $border-color;
    color: $dark-text-color;
    cursor: pointer;
    border-radius: 2px;
    user-select: none;
    &.gac-green {
      margin-top: 16px;
      background-color: #f6fcfa;
      border: 2px dashed $green-color;
    }
    i {
      width: 13px;
      height: 18px;
      margin-right: 13px;
      //background: url('../../images/sprite.svg') no-repeat -40px -310px;
    }
  }
  .gac-cart-subtotal {
    padding-top: 12px;
    padding-bottom: 4px;
    margin-bottom: 12px;
    border-bottom: 1px solid $border-color;
    p {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .gac-cart-subtotal-label {
        color: $light-text-color;
      }
      .gac-cart-subtotal-value {
        color: $dark-text-color;
      }
    }
  }
  .gac-cart-balance {
    padding-bottom: 4px;
    margin-bottom: 14px;
    p {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .gac-cart-subtotal-label {
        color: $light-text-color;
      }
      .gac-cart-subtotal-value {
        color: $dark-text-color;
      }
    }
  }
  .gac-cart-with-bundles {
    font-size: 12px;
    color: $yellow-color-dark;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  .gac-cart-subtotal + .gac-cart-total {
    padding-top: 2px;
  }
  .gac-cart-total {
    padding-top: 26px;
    margin-bottom: 10px;
    p {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .gac-cart-total-label,
      .gac-cart-total-value {
        color: $dark-text-color;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .gac-cart-confirm-order {
    display: block;
    width: 250px;
    margin: 20px auto 0;
  }
  .gac-cart-money-back {
    position: relative;
    padding: 11px 0 11px 83px;
    margin-bottom: 24px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    i {
      position: absolute;
      top: 24px;
      left: 46px;
      width: 18px;
      height: 22px;
      background: url('../../images/sprite.svg') no-repeat -150px -290px;
    }
    p {
      line-height: 23px;
      color: $light-text-color;
      font-size: 12px;
    }
  }
  .gac-cart-text {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 23px;
    margin-bottom: 9px;
    color: $light-text-color;
    font-size: 12px;
    span + img {
      margin: 0 17px 0 25px;
    }
    img {
      max-height: 23px;
      width: auto;
    }
    svg {
      position: relative;
      top: -1px;
    }
  }
}
.gac-cart-payment-methods {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  &.gac-invalid {
    .gac-cart-methods-item {
      border-color: $red-color;
    }
  }
  .gac-cart-methods-item {
    display: flex;
    line-height: 20px;
    padding: 13px 15px 13px 17px;
    border: 1px solid $border-color;
    border-radius: 2px;
    cursor: pointer;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
    &.gac-invalid {
      span {
        background-position: -90px -410px;
      }
    }
    input {
      display: none;
    }
    span {
      width: 20px;
      height: 20px;
      margin-right: 18px;
      //background: url('../../images/sprite.svg') no-repeat -110px -290px;
    }
    input:checked + span {
      //background-position: -130px -290px;
    }
    .gac-cart-methods-item-card {
      width: 120px;
      background: url('../../images/sprite.svg') no-repeat 0 -390px;
    }
    .gac-cart-methods-item-paypal {
      width: 24px;
      background: url('../../images/sprite.svg') no-repeat -120px -390px;
    }
  }
  &.gac-method-braintree {
    .gac-cart-methods-item {
      .gac-cart-methods-item-card {
        width: 56px;
      }
    }
    .gac-cart-methods-item-paypal {
      width: 56px;
      background: url('../../images/sprite.svg') no-repeat -95px -460px;
    }
  }
}
.gac-cart-wrapper + .gac-sub-cta {
  margin-top: 89px;
}
.gac-cart-wrapper + .gac-sub-cta.gac-design {
  margin-top: 110px;
}
.gac-sub-cta {
  position: sticky;
  top: 162px;
  line-height: 26px;
  margin-top: 84px;
  padding: 47px 14px 20px;
  border: 1px solid $border-color;
  background-color: $white-color;
  color: $dark-text-color;
  font-size: 15px;
  text-align: center;
  a {
    font-size: 15px;
    &:hover {
      text-decoration: none;
    }
  }
  &__img {
    position: absolute;
    left: 50%;
    top: -82px;
    transform: translateX(-50%);
  }
  &__svg {
    position: absolute;
    left: 50%;
    bottom: -19px;
    transform: translateX(-50%);
  }
}