@import 'variables';

.gac-dropdown {
  position: relative;
  height: 48px;
  border: 1px solid $border-color;
  white-space: nowrap;
  border-radius: 0;
  cursor: pointer;
  transition: border-color .2s ease;
  &:before {
    position: absolute;
    top: 50%;
    right: 22px;
    width: 10px;
    height: 6px;
    margin-top: -3px;
    background: url('../../images/sprite.svg') no-repeat 0 0;
    z-index: 3;
    content: '';
  }
  .gac-dropdown__value {
    position: relative;
    line-height: 46px;
    padding: 0 56px 0 16px;
    background-color: $white-color;
    z-index: 2;
  }
  .gac-dropdown__menu {
    width: 100%;
    position: absolute;
    top: 46px;
    left: 0;
    box-shadow: 0 30px 70px 0 rgba(159, 177, 188, 0.3);
    background-color: $white-color;
    z-index: 1;
    border-radius: 0 0 2px 2px;
    li {
      display: block;
      .gac-dropdown__menu__link {
        position: relative;
        display: block;
        line-height: 47px;
        padding: 0 16px;
        border-top: 1px solid $border-color;
        background-color: $white-color;
        font-size: 15px;
        color: $dark-text-color;
        text-decoration: none;
        transition: color .2s ease;
        z-index: 1;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $green-color;
          opacity: 0;
          transition: opacity .2s ease;
          content: '';
          z-index: -1;
        }
        &:hover {
          color: $white-color;
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
  &.gac-dropdown-active {
    border-color: transparent;
    border-radius: 0 0 2px 2px;
    &:before {
      background-position: -10px 0;
    }
  }
}
