@import 'variables';

.gac-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white-color;
  z-index: 101;
  .gac-header__top {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid $border-color;
    .gac-logo {
      padding: 15px 20px 14px 32px;
      svg {
        vertical-align: top;
      }
    }
    .gac-align-right {
      display: flex;
    }
  }
}
.gac-main-menu {
  display: flex;
  margin-right: 20px;
  list-style: none;
  li {
    margin: 0 6px;
    & > div,
    a {
      display: block;
      position: relative;
      padding: 0 13px;
      height: 55px;
      line-height: 53px;
      color: $light-text-color;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      text-decoration: none;
      transition: color .2s ease;
      &:hover,
      &:focus {
        color: $green-color!important;
      }
      &:before {
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: transparent;
        transition: background-color .3s ease;
        border-radius: 1px;
        content: '';
      }
      &.gac-active {
        color: $dark-text-color;
        &:before {
          background: $green-color;
        }
      }
    }
    & > div {
      color: #9d9c9d;
      opacity: 0.4;
      &:hover,
      &:focus {
        color: #9d9c9d!important;
      }
    }
    span {
      position: absolute;
      top: 9px;
      right: -3px;
      display: block;
      padding: 0;
      min-width: 16px;
      line-height: 16px;
      height: 16px;
      //background: url("../../images/sprite.svg") -160px -460px;
      //background: $green-color;
      color: $white-color;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      &.gac-red {
        padding: 0 5px;
        background: $red-color;
        border-radius: 8px;
      }
    }
  }
}
.gac-sub-menu-wrap {
  min-height: 56px;
  border-bottom: 1px solid $border-color;
  .gac-sub-menu {
    display: flex;
    list-style: none;
    li {
      margin: 0 6px;
      &:first-child {
        margin-left: 0;
      }
      span,
      a {
        display: block;
        position: relative;
        padding: 0 13px;
        height: 55px;
        line-height: 55px;
        color: $light-text-color;
        font-size: 15px;
        text-decoration: none;
        transition: color .2s ease;
        &:hover,
        &:focus {
          color: $green-color!important;
        }
        &:before {
          position: absolute;
          bottom: -1px;
          left: 0;
          height: 2px;
          width: 100%;
          background-color: transparent;
          transition: background-color .3s ease;
          border-radius: 1px;
          content: '';
        }
        &.gac-active {
          color: $dark-text-color;
          &:before {
            background: $green-color;
          }
        }
      }
      span {
        color: #9d9c9d;
        opacity: 0.4;
        cursor: default;
        &:hover,
        &:focus {
          color: #9d9c9d!important;
        }
      }
    }
  }
}
/* Support phone */
.gac-support-phone-wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
}
.gac-support-phone {
  display: flex;
  align-items: center;
  line-height: 55px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: $dark-text-color;
    text-decoration: none;
  }
  img {
    max-width: 40px;
    height: auto;
    margin-right: 32px;
    border-radius: 100%;
  }
  i {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    background: url('../../images/sprite.svg') -200px 0;
  }
}
/* Mobile header */
.gac-mobile-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 56px;
  padding: 0 18px;
  border-bottom: 1px solid $border-color;
  background-color: #ffffff;
  z-index: 100;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  .gac-mobile-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 13px 0 0 -5px;
    cursor: pointer;
    i {
      width: 6px;
      height: 10px;
      background: url('../../images/sprite.svg') -154px -370px;
    }
  }
  .gac-mobile-menu-toggle {
    width: 30px;
    height: 24px;
    margin: 16px 0 0 -5px;
    padding: 5px;
    cursor: pointer;
    transform: rotate(0deg);
    //transition: .5s ease-in-out;
    span {
      position: absolute;
      left: 5px;
      height: 2px;
      width: 20px;
      background: $light-text-color;
      opacity: 1;
      //transition: 0.3s ease-in-out;
      transform: rotate(0deg);
      &:nth-child(1) {
        top: 5px;
      }
      &:nth-child(2), &:nth-child(3) {
        top: 11px;
      }
      &:nth-child(4) {
        top: 17px;
      }
    }
    /*
    &.gac-opened {
      span {
        &:nth-child(1) {
          top: 7px;
          width: 0;
          left: 50%;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 7px;
          width: 0;
          left: 50%;
        }
      }
    }
    */
  }
  &__title {
    position: absolute;
    left: 50%;
    top: 0;
    max-width: calc(100% - 120px);
    line-height: 55px;
    transform: translateX(-50%);
    color: $dark-text-color;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .gac-support-phone-wrap {
    margin-right: 0;
  }
  .gac-support-phone {
    margin-right: 20px;
    i {
      width: 22px;
      height: 22px;
      margin-right: 0;
      background-position: 0 -470px;
    }
  }
  &__messages-icon {
    margin-top: 19px;
    margin-left: auto;
    cursor: pointer;
  }
}
.gac-mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 320px;
  background-color: #ffffff;
  z-index: 99999;
  &__logo {
    display: block;
    svg {
      vertical-align: top;
      margin: 15px 0 14px 24px;
    }
    img {
      width: auto;
      margin: 15px 0 15px 24px;
    }
    border-bottom: 1px solid #f0f0f0;
  }
  .gac-mobile-accounts {
    height: 49px;
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    .gac-dropdown__value {
      padding-left: 23px;
    }
    .gac-dropdown__menu {
      top: 48px;
    }
  }
  &__content {
    padding-bottom: 10px;
    overflow: auto;
  }
  &__list {
    padding: 11px 0;
    margin-bottom: 6px;
    & > li {
      & > span,
      & > a {
        display: block;
        line-height: 42px;
        padding: 0 24px;
        color: $dark-text-color;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        &.gac-active {
          color: $green-color;
        }
        span {
          position: relative;
          i {
            position: absolute;
            top: -6px;
            right: -14px;
            display: block;
            padding: 0 0 0 1px;
            min-width: 16px;
            line-height: 16px;
            height: 16px;
            background: $green-color;
            color: $white-color;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            border-radius: 8px;
            font-style: normal;
            &.gac-red {
              padding: 0 5px;
              background: $red-color;
            }
          }
        }
      }
      ul {
        margin: 2px 0;
        li {
          padding-left: 24px;
          a {
            display: block;
            line-height: 40px;
            color: $light-text-color;
            font-size: 15px;
            text-decoration: none;
            transition: color .3s ease;
            &:hover {
              color: $green-color;
            }
            &.gac-active {
              color: $green-color;
            }
          }
        }
      }
    }
  }
  &__icons {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 20px;
    span {
      display: flex;
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
      &.gac-active {
        path {
          fill: $green-color;
        }
      }
      path {
        fill: #484B58;
      }
    }
  }
  &__settings {
    //padding: 0 0 20px 25px;
    & > span {
      position: relative;
      display: block;
      width: 100%;
      padding-left: 32px;
      line-height: 24px;
      font-size: 15px;
      color: $dark-text-color;
      cursor: pointer;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        //background: url('../../images/sprite.svg') -40px -150px;
      }
    }
    .gac-user-nav__info-menu {
      li {
        & > a,
        & > span {
          position: relative;
          display: block;
          line-height: 40px;
          padding: 0 24px 0 58px;
          color: $light-text-color;
          font-size: 15px;
          text-decoration: none;
          transition: color .3s ease;
          cursor: pointer;
          &:after {
            position: absolute;
            left: 32px;
            top: 50%;
            width: 15px;
            height: 16px;
            margin-top: -8px;
            background: url('../../images/sprite.svg') no-repeat 0 0;
            content: '';
          }
          &:hover {
            color: $green-color;
          }
          &.ic-support:after {
            background-position: -85px 0px;
          }
          &.ic-faq:after {
            background-position: -85px -20px;
          }
          &.ic-api:after {
            background-position: -85px -40px;
          }
          &.ic-billing:after {
            background-position: -85px -140px;
          }
          &.ic-clients:after {
            background-position: -85px -60px;
          }
          &.ic-users:after {
            background-position: -85px -80px;
          }
          &.ic-integrations:after {
            background-position: -85px -100px;
          }
          &.ic-referrals:after {
            background-position: -85px -120px;
          }
          &.ic-signout:after {
            background-position: -85px -160px;
          }
          &.ic-payments:after {
            width: 16px;
            height: 15px;
            background-position: 0 -290px;
          }
          &.ic-subscriptions:after {
            width: 13px;
            height: 16px;
            background-position: -200px -20px;
          }
          &.ic-settings:after {
            width: 16px;
            background-position: 0 -310px;
          }
        }
        &.gac-user-nav__account-info {
          padding: 16px 24px 10px 32px;
          color: $dark-text-color;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          .gac-user-nav__account-settings {
            padding-top: 6px;
          }
          a {
            font-size: 13px;
            color: $light-text-color;
            &:hover {
              color: $green-color;
            }
          }
        }
        &.gac-user-nav__manager {
          display: flex;
          flex-wrap: wrap;
          padding: 15px 24px 15px 32px;
          .gac-user-nav__manager-title {
            width: 100%;
            margin-bottom: 7px;
            color: $light-text-color;
            font-size: 13px;
          }
          .gac-user-nav__manager-img {
            width: 32px;
            height: 32px;
            margin: 6px 11px 0 0;
            background: url('../../images/sprite.svg') no-repeat 0 -20px;
            border-radius: 100%;
            overflow: hidden;
            img {
              transform: scale(1.1);
            }
          }
          .gac-user-nav__manager-name {
            padding-top: 4px;
            margin-bottom: 6px;
            color: $dark-text-color;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
          }
          .gac-user-nav__manager-id {
            color: $dark-text-color;
            font-size: 12px;
          }
        }
      }
    }
  }
  &__account {
    position: relative;
    padding: 14px 24px;
    margin-bottom: 9px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
  &__account-wrap {
    display: flex;
    justify-content: flex-end;
  }
  &__account-name {
    position: absolute;
    top: 10px;
    left: 24px;
    line-height: 22px;
    font-size: 15px;
    font-weight: bold;
    color: $dark-text-color;
  }
  &__account-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    a, span {
      line-height: 18px;
      margin-bottom: 6px;
      font-size: 12px;
      color: $light-text-color;
      text-decoration: underline;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__manager {
    display: flex;
    padding-top: 17px;
  }
  &__manager-img {
    width: 32px;
    margin-right: 8px;
    border-radius: 100%;
    overflow: hidden;
  }
  &__manager-name,
  &__manager-info {
    padding: 1px 0;
    line-height: 15px;
    font-size: 12px;
    font-weight: 600;
  }
  &__manager-info {
    margin-left: auto;
    text-align: right;
  }
  .gac-black-friday-img {
    padding: 10px 20px 0;
  }
}

/* User's accounts dropdown */
.gac-accounts {
  height: 55px;
  border: 0;
  border-left: 1px solid $border-color;
  border-radius: 0;
  .gac-dropdown__value {
    max-width: 240px;
    line-height: 55px;
    padding-left: 36px;
    padding-right: 64px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .gac-dropdown__menu {
    left: auto;
    right: 0;
    top: 55px;
    width: 260px;
    max-height: 576px;
    overflow: auto;
    padding-bottom: 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    li {
      .gac-dropdown__menu__link {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  &:before {
    right: 39px;
  }
  &:hover {
    border-left-color: $border-color;
  }
}
/* User's */
.gac-user-nav-wrap {
  display: flex;
  margin-left: auto;
  .gac-signout {
    padding: 10px;
    margin-top: 9px;
    cursor: pointer;
  }
  &.gac-client {
    .gac-user-nav {
      .gac-user-nav__account {
        .gac-user-nav__info-menu li.gac-user-nav__manager {
          margin-bottom: 0;
        }
        .gac-user-nav__info-menu li.gac-user-nav__signout {
          border-top: 0;
        }
      }
    }
  }
  .gac-black-friday-img {
    margin-right: 8px;
    display: flex;
    align-items: center;
    img {
      max-height: 40px;
      width: auto;
      cursor: pointer;
    }
  }
}
.gac-user-nav {
  height: 55px;
  display: flex;
  padding: 0 10px 0 6px;
  border-left: 1px solid $border-color;
  &__notifications {
    position: relative;
    width: 22px;
    height: 18px;
    margin: 19px 17px 0 0;
    background: url('../../images/sprite.svg') no-repeat -20px 0;
    cursor: pointer;
    span {
      position: absolute;
      bottom: 12px;
      left: 16px;
      min-width: 16px;
      height: 21px;
      padding: 0 5px;
      border: 2px solid $white-color;
      border-radius: 10px;
      background-color: $red-color;
      color: $white-color;
      font-size: 12px;
      text-align: center;
    }
  }
  &__referral-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    margin: 0 6px 0 16px;
    cursor: pointer;
    i {
      width: 20px;
      height: 20px;
      background: url("../../images/sprite.svg") -25px -500px;
    }
  }
  &__messages-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    margin: 0 16px 0 6px;
    cursor: pointer;
  }
  &__account {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    margin-right: 8px;
    cursor: pointer;
    .gac-user-nav-account-image {
      width: 40px;
      height: auto;
      border-radius: 100%;
    }
    .gac-user-nav-no-image {
      width: 40px;
      height: 100%;
      background: url('../../images/sprite.svg') no-repeat 0 -60px;
    }
    .gac-user-nav__info-menu {
      position: absolute;
      top: 55px;
      right: -4px;
      width: 260px;
      padding: 12px 0 0 0;
      margin-right: 0;
      border: 1px solid $border-color;
      border-radius: 0 0 2px 2px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      background-color: $white-color;
      cursor: default;
      opacity: 0;
      transition: opacity .2s ease;
      visibility: hidden;
      z-index: 1002;
      li {
        & > a,
        & > span {
          position: relative;
          display: block;
          line-height: 51px;
          padding: 0 16px 0 49px;
          font-size: 15px;
          color: $dark-text-color;
          text-decoration: none;
          transition: color .2s ease;
          z-index: 1;
          cursor: pointer;
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $green-color;
            opacity: 0;
            transition: opacity .2s ease;
            content: '';
            z-index: -1;
          }
          &:after {
            position: absolute;
            left: 20px;
            top: 50%;
            width: 15px;
            height: 16px;
            margin-top: -8px;
            background: url('../../images/sprite.svg') no-repeat 0 0;
            content: '';
          }
          &.ic-support:after {
            background-position: -85px 0px;
          }
          &.ic-faq:after {
            background-position: -85px -20px;
          }
          &.ic-api:after {
            background-position: -85px -40px;
          }
          &.ic-billing:after {
            background-position: -85px -140px;
          }
          &.ic-clients:after {
            background-position: -85px -60px;
          }
          &.ic-users:after {
            background-position: -85px -80px;
          }
          &.ic-integrations:after {
            background-position: -85px -100px;
          }
          &.ic-referrals:after {
            background-position: -85px -120px;
          }
          &.ic-signout:after {
            background-position: -85px -160px;
          }
          &.ic-payments:after {
            width: 16px;
            height: 15px;
            background-position: 0 -290px;
          }
          &.ic-subscriptions:after {
            width: 13px;
            height: 16px;
            background-position: -200px -20px;
          }
          &.ic-settings:after {
            width: 16px;
            background-position: 0 -310px;
          }
          &:hover {
            color: $white-color;
            &:before {
              opacity: 1;
            }
            &.ic-support:after {
              background-position: -100px 0px;
            }
            &.ic-faq:after {
              background-position: -100px -20px;
            }
            &.ic-api:after {
              background-position: -100px -40px;
            }
            &.ic-billing:after {
              background-position: -100px -140px;
            }
            &.ic-clients:after {
              background-position: -100px -60px;
            }
            &.ic-users:after {
              background-position: -100px -80px;
            }
            &.ic-integrations:after {
              background-position: -100px -100px;
            }
            &.ic-referrals:after {
              background-position: -100px -120px;
            }
            &.ic-signout:after {
              background-position: -100px -160px;
            }
            &.ic-payments:after {
              background-position: -20px -290px;
            }
            &.ic-subscriptions:after {
              background-position: -220px -20px;
            }
            &.ic-settings:after {
              background-position: -20px -310px;
            }
          }
        }
        &.gac-user-nav__manager {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid $border-color;
          padding: 7px 15px 10px;
          margin-bottom: 7px;
          .gac-user-nav__manager-title {
            width: 100%;
            margin-bottom: 7px;
            color: $light-text-color;
            font-size: 12px;
          }
          .gac-user-nav__manager-img {
            width: 32px;
            height: 32px;
            margin: 6px 10px 0 0;
            background: url('../../images/sprite.svg') no-repeat 0 -20px;
            border-radius: 100%;
            overflow: hidden;
            img {
              transform: scale(1.1);
            }
          }
          .gac-user-nav__manager-name {
            padding-top: 4px;
            margin-bottom: 6px;
            color: $dark-text-color;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
          }
          .gac-user-nav__manager-id {
            line-height: 16px;
            color: $dark-text-color;
            font-size: 12px;
          }
        }
        &.gac-user-nav__account-info {
          padding: 6px 22px 17px;
          border-bottom: 1px solid $border-color;
          color: $dark-text-color;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          .gac-user-nav__account-name {
            margin-bottom: 6px;
          }
          a {
            color: $light-text-color;
            &:hover {
              color: $green-color;
            }
          }
        }
        &.gac-user-nav__signout {
          border-top: 1px solid $border-color;
        }
      }
    }
    &:hover {
      .gac-user-nav__info-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

}

/* Messages Center */
.gac-messages-center {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  &__wrap {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 400px;
    background-color: $white-color;
    box-shadow: 0 4px 32px rgba(0, 0, 0, 0.16);
  }
  &__title {
    line-height: 22px;
    padding: 16px 16px 10px;
    font-weight: bold;
  }
  &__close {
    position: absolute;
    right: 6px;
    top: 14px;
    padding: 10px;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 40px;
    overflow: auto;
    height: calc(100% - 48px);
  }
  &__item {
    position: relative;
    padding: 16px 56px 10px 16px;
    margin-bottom: 16px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.gac-unread {
      &:before {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
        height: 16px;
        background-color: $green-color;
        border-radius: 100%;
        content: "";
      }
      .gac-messages-center__item-message {
        color: $green-color;
        font-weight: 600;
      }
    }
  }
  &__item-meta {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    img {
      width: 32px;
      margin-right: 8px;
      border-radius: 100%;
    }
    .gac-no-avatar {
      margin-right: 8px;
    }
  }
  &__item-author {
    line-height: 22px;
    font-weight: bold;
    color: $dark-text-color;
    font-size: 12px;
  }
  &__item-topic {
    line-height: 22px;
    margin-bottom: 5px;
    font-size: 12px;
    color: $dark-text-color;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__item-message {
    line-height: 16px;
    font-size: 12px;
    color: $dark-text-color;
    word-break: break-word;
  }
  &__item-time {
    position: absolute;
    right: 16px;
    bottom: 10px;
    line-height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: $dark-text-color;
  }
  &__emptyState {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    svg {
      margin-bottom: 20px;
    }
    p {
      line-height: 22px;
      color: $light-text-color;
    }
  }
}
.gac-messages-icon {
  position: relative;
  &__label {
    position: absolute;
    top: -7px;
    right: -6px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 2px solid $white-color;
    background-color: #DC5D5B;
    z-index: 1;
  }
  //&__label {
  //  position: absolute;
  //  top: -12px;
  //  left: 16px;
  //  min-width: 20px;
  //  line-height: 16px;
  //  height: 20px;
  //  padding: 0 4px;
  //  background-color: #DC5D5B;
  //  color: $white-color;
  //  font-size: 12px;
  //  text-align: center;
  //  border-radius: 100%;
  //  border: 2px solid $white-color;
  //  z-index: 1;
  //}
}