@import 'variables';

.gac-login-page {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  &.gac-white-label {
    background: $white-color;
    .gac-login-form {
      border: 1px solid $border-color;
      border-radius: 2px;
      .gac-btn {
        margin-bottom: 6px;
      }
    }
  }
  .gac-login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 328px;
    padding: 32px 20px;
    margin-left: -164px;
    background-color: $white-color;
    border-radius: 2px;
    .gac-title {
      line-height: 29px;
      padding-bottom: 47px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      span {
        color: $green-color;
      }
    }
    .gac-version {
      position: absolute;
      right: 14px;
      top: 10px;
      font-size: 15px;
      font-weight: bold;
      color: $red-color;
    }
    label {
      display: block;
      line-height: 26px;
      color: $dark-text-color;
      font-size: 17px;
    }
    .gac-input-wrap {
      position: relative;
      margin: 0 0 24px 0;
      .gac-error {
        position: absolute;
        top: auto;
        left: 0;
        bottom: -20px;
        margin: 0;
        font-size: 12px;
      }
      .gac-input {
        margin: 0;
      }
      .gac-login-icon-1 {
        position: absolute;
        right: 18px;
        top: 50%;
        width: 20px;
        height: 17px;
        background: url('../../images/sprite.svg') -50px -430px;
        transform: translateY(-50%);
        cursor: pointer;
        &.gac-visible {
          background-position: -70px -430px;
        }
      }
    }
    .gac-input {
      width: 100%;
      max-width: 100%;
      height: 40px;
      padding: 0 16px;
      margin: 0 0 21px 0;
      color: $dark-text-color;
      font-size: 15px;
      border: 1px solid $border-color;
      border-radius: 4px;
      transition: border-color .2s ease;
      &.gac-invalid {
        border-color: $red-color;
      }
    }
    .gac-restore-password {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 26px;
      color: $light-text-color;
      font-size: 14px;
      span {
        position: relative;
        top: -11px;
        cursor: pointer;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .gac-btn {
      display: block;
      width: 100%;
      margin: 0 0 40px;
      border-radius: 4px;
      &:before {
        border-radius: 4px;
      }
    }
    .gac-login-sign-up {
      color: $dark-text-color;
      font-size: 17px;
      text-align: center;
      a {
        font-size: 17px;
        font-weight: bold;
      }
    }
    #g-recaptcha + .gac-error {
      text-align: center;
    }
    .gac-error {
      position: relative;
      top: -11px;
      margin-bottom: 4px;
      font-size: 12px;
    }
    .gac-success-icon {
      margin: 20px auto;
    }
    .gac-link-error {
      line-height: 36px;
      margin-bottom: 32px;
      color: $dark-text-color;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      a {
        font-size: 18px;
        font-weight: 600;
      }
    }
    #g-recaptcha {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    h1 {
      line-height: 50px;
      margin: 0 0 35px;
      color: $dark-text-color;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
  }
}

body #hubspot-messages-iframe-container {
  z-index: 100!important;
}