@import 'variables';

.gac-empty-state {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $white-color;
  border: 1px solid $border-color;
  z-index: 1;
  transition: height .3s ease;
  border-radius: 2px;
  .gac-empty-state-img {
    margin-bottom: 26px;
  }
  .gac-empty-state-text {
    line-height: 26px;
    font-size: 19px;
    color: $light-text-color;
    p {
      margin-bottom: 20px;
    }
    ul {
      li {
        position: relative;
        display: block;
        line-height: 28px;
        padding-left: 30px;
        font-size: 16px;
        span {
          text-decoration: underline;
          cursor: pointer;
          transition: color .3s ease;
          &:hover {
            text-decoration: none;
            color: $green-color;
          }
        }
        i {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -8px;
          width: 14px;
          height: 16px;
          background: url('../../images/sprite.svg') no-repeat 0 0;
          &.gac-empty-state-icon-1 {
            height: 10px;
            margin-top: -5px;
            background-position: -60px -120px;
          }
          &.gac-empty-state-icon-2 {
            height: 14px;
            margin-top: -7px;
            background-position: -80px -290px;
          }
          &.gac-empty-state-icon-3 {
            background-position: -95px -290px;
          }
        }
      }
    }
  }
}
.gac-empty-state-v2 {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $border-color;
  padding: 30px 0;
  .gac-empty-state-img {
    margin-bottom: 30px;
  }
  .gac-empty-state-text {
    max-width: 490px;
    color: $light-text-color;
    h1 {
      line-height: 30px;
      margin: 0 0 14px 0;
      font-size: 20px;
      font-weight: bold;
      span {
        text-transform: capitalize;
      }
    }
    p {
      line-height: 24px;
      font-size: 16px;
    }
  }
}
.gac-messages-empty {
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
}
.gac-404-page {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 130px;
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 15px;
  }
  .gac-404-page-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: $white-color;
    border: 1px solid $border-color;
    z-index: 1;
    transition: height .3s ease;
    border-radius: 2px;
    overflow: hidden;
    .gac-404-page-text {
      margin-bottom: 60px;
      font-size: 28px;
      font-weight: bold;
      color: $light-text-color;
    }
    .gac-404-page-img-1 {
      margin-bottom: 50px;
    }
  }
}
