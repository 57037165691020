@import 'variables';

/* Modals */
.gac-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 40px 0;
  background: rgba(72, 75, 88, 0.55);
  overflow: auto;
  z-index: 99990;
}

.gac-modal {
  position: fixed;
  width: 555px;
  padding: 23px 23px 30px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  z-index: 99999;
  &.gac-message-support-modal {
    min-height: 333px;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px));
    h2 {
      margin-right: 34px;
    }
  }
  &.gac-feedback-modal {
    min-height: 333px;
    right: 40px;
  }
  &.gac-feedback-modal-an-enter-done {
    bottom: 100px;
  }
  &.gac-edit-project-modal {
    width: 472px;
    left: 50%;
    top: 50%;
    padding: 35px;
    transform: translate(-50%,-50%);
    h2 {
      margin-right: 38px;
      overflow: hidden;
    }
    .gac-btn {
      min-width: 120px;
      margin-right: 16px;
    }
    .gac-btn-link {
      margin-right: 16px;
      font-size: 15px;
      transition: all .3s ease;
      cursor: pointer;
      text-decoration: underline;
      white-space: nowrap;
      &:hover {
        color: $green-color;
        text-decoration: none;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.gac-create-project-modal {
    width: 556px;
    left: 50%;
    top: 50%;
    margin-left: -278px;
    .gac-modal-field {
      margin-bottom: 24px;
      &:nth-child(3) {
        width: 196px;
        margin-right: 0;
      }
    }
    .gac-modal-btns {
      padding-top: 16px;
    }
    h2 {
      margin-bottom: 16px;
    }
    textarea {
      margin-bottom: 24px;
      &.gac-invalid {
        border-color: $red-color;
      }
    }
    .gac-btn {
      margin-right: 8px;
    }
    .gac-btn-v2 {
      width: 100px;
      min-width: 100px;
    }
    .gac-filter-item__menu {
      box-shadow: -5px 0 100px 0 rgba(159, 177, 188, 0.3);
    }
    .gac-error {
      line-height: 16px;
      margin-top: -15px;
      margin-bottom: 24px;
      font-size: 12px;
    }
  }
  &.gac-bundles-modal {
    width: 1108px;
    left: 50%;
    top: 50%;
    padding: 0 0 36px 0;
    margin: -223px 0 0 -554px;
    h1 {
      font-size: 31px;
      line-height: 104px;
      margin: 0;
    }
    .gac-cart-money-back {
      width: 260px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      padding: 11px 0 11px 48px;
      i {
        position: absolute;
        top: 24px;
        left: 11px;
        width: 18px;
        height: 22px;
        background: url('../../images/sprite.svg') no-repeat -150px -290px;
      }
      p {
        line-height: 23px;
        color: $light-text-color;
        font-size: 12px;
      }
    }
  }
  &.gac-purchase-bundle-modal {
    width: 346px;
    left: 50%;
    top: 50%;
    padding: 0 24px 32px 24px;
    margin: 0 0 0 -173px;
    h1 {
      line-height: 68px;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }
    .gac-bundle-desc {
      display: flex;
      justify-content: space-between;
      margin-bottom: 27px;
      .gac-bundle-desc-item {
        span {
          display: block;
          margin-bottom: 9px;
          font-size: 18px;
          font-weight: bold;
        }
        p {
          font-size: 15px;
          color: $light-text-color;
        }
        &:last-child {
          text-align: right;
          span {
            color: $blue-color;
            font-weight: 600;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
    .gac-cart-payment-methods {
      margin-bottom: 27px;
    }
    & > label {
      display: flex;
      .gac-hint {
        margin-left: 8px;
        .gac-hint-wrap .gac-hint-text {
          width: 100px;
        }
      }
    }
    .gac-input {
      margin-bottom: 8px;
    }
    .gac-expiration-date {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      & > div:first-child {
        width: 150px;
      }
      & > div:last-child {
        width: 90px;
      }
    }
    .gac-stripe .gac-btn.gac-cart-confirm-order {
      margin-top: 0!important;
    }
    .gac-btn {
      width: 100%;
      margin: 0;
    }
    .paypal-button-container {
      height: 40px;
      &:empty {
        height: 0;
      }
    }
    .gac-bundle-accounts {
      margin-bottom: 16px;
      .gac-bundle-label {
        line-height: 24px;
        font-size: 14px;
      }
    }
  }
  &.gac-create-account-modal {
    width: 556px;
    left: 50%;
    top: 50%;
    padding: 0 90px 32px;
    margin: 0 0 0 -278px;
    .gac-close-modal {
      right: auto;
      left: 20px;
      background-position: -110px -410px;
    }
    h1 {
      line-height: 58px;
      margin: 0;
      padding-top: 6px;
      font-size: 18px;
      font-weight: 600;
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      height: 74px;
      margin-bottom: 16px;
    }
    .gac-btn {
      width: 100%;
    }
    .gac-truste-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      color: #444444;
      font-size: 14px;
      img {
        width: 38px;
        height: 37px;
        margin-right: 18px;
      }
    }
    .gac-have-account {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      padding: 33px 0 7px 0;
      span {
        height: 19px;
        margin-left: 25px;
        border-bottom: 1px solid $dark-text-color;
        cursor: pointer;
      }
    }
    .gac-input {
      margin-bottom: 16px;
    }
    .gac-select,
    .gac-custom-select {
      margin-bottom: 16px;
      .gac-select-menu {
        max-height: 282px!important;
      }
    }
    #g-recaptcha {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    #g-recaptcha + .gac-error {
      text-align: center;
    }
    .gac-custom-select.gac-invalid {
        margin-bottom: 10px;
    }
    .gac-custom-select + .gac-error {
      top: 0;
    }
    .gac-error {
      position: relative;
      top: -14px;
      margin-bottom: 4px;
      font-size: 12px;
    }
  }
  &.gac-writers-modal {
    width: 958px;
    left: 50%;
    top: 50%;
    padding: 0 12px 30px 30px;
    margin: 0 0 0 -479px;
    h1 {
      line-height: 111px;
      margin: 0;
      font-size: 31px;
      text-align: left;
    }
    .gac-writers-text-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      color: $light-text-color;
      font-size: 14px;
      & > span {
        line-height: 22px;
      }
      .gac-select {
        width: 120px;
        margin-left: 14px;
        margin-right: 4px;
        .gac-select-value {
          padding-right: 40px;
        }
        .gac-select-menu {
          width: 140px;
        }
      }
      .gac-select + .gac-writers-text {
        margin-right: 4px;
      }
    }
    .gac-writers-invalid {
      position: absolute;
      top: -40px;
      left: 18px;
      line-height: 22px;
      padding: 4px 16px 6px;
      background-color: $dark-text-color;
      color: $white-color;
      font-size: 15px;
      white-space: nowrap;
      border-radius: 4px;
      &:before {
        position: absolute;
        left: 50%;
        bottom: -6px;
        margin-left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $dark-text-color;
        content: "";
      }
    }
    .gac-writers-list-wrap {
      position: relative;
    }
    .gac-writers-list {
      .gac-writer-item-all {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 288px;
        min-height: 86px;
        margin-bottom: 16px;
        border: 1px solid $border-color;
        border-radius: 2px;
        cursor: pointer;
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: 1px;
          width: 14px;
          height: 10px;
          margin-right: 14px;
          svg {
            path {
              fill: $green-color;
            }
          }
          //background: url('../../images/sprite.svg') -55px -410px;
          &.gac-icon-deselect {
            top: 0;
            width: 12px;
            height: 12px;
            //background-position: -70px -410px;
            svg {
              path {
                fill: $green-color;
              }
            }
          }
        }
        span {
          line-height: 24px;
          font-size: 18px;
          font-weight: 600;
          color: $green-color;
        }
      }
      .gac-writer-item {
        position: relative;
        width: 288px;
        padding: 18px 15px 1px 15px;
        margin-bottom: 16px;
        border: 1px solid $border-color;
        border-radius: 2px;
        &:hover {
          .gac-writer-item-btns {
            visibility: visible;
            opacity: 1;
            z-index: 2;
          }
        }
        &.gac-writer-item-all {
          //min-height: ;
        }
        &.gac-active {
          &:before {
            position: absolute;
            left: -1px;
            top: -1px;
            right: -1px;
            bottom: -1px;
            border: 2px solid $green-color;
            border-radius: 2px;
            content: '';
          }
        }
        &.gac-disabled {
          opacity: 0.5;
        }
        .gac-writer-item-head {
          display: flex;
          margin-bottom: 17px;
          .gac-writer-item-avatar {
            flex: 0 0 48px;
            position: relative;
            height: 48px;
            margin-right: 17px;
            background-color: $disabled-color;
            border-radius: 100%;
            overflow: hidden;
            &.gac-no-avatar {
              &:before {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 14px;
                height: 16px;
                transform: translate(-50%,-50%);
                background: url('../../images/sprite.svg') no-repeat -13px -79px;
                content: '';
              }
            }
            .gac-active {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              background: $green-color;
              opacity: 0.5;
            }
            i {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 14px;
              height: 10px;
              margin: -5px 0 0 -7px;
              background: url('../../images/sprite.svg') no-repeat -111px -373px;
            }
          }
          .gac-writer-info {
            flex: 1 1 auto;
            .gac-writer-name-wrap {
              display: flex;
              .gac-writer-item-name {
                max-width: 100%;
                line-height: 24px;
                font-size: 18px;
                font-weight: 600;
                overflow: hidden;
              }

            }
            .gac-writer-icons {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              font-size: 12px;
              & > * {
                margin-top: 6px;
              }
              .gac-writer-level {
                position: relative;
                display: flex;
                line-height: 15px;
                margin-right: 10px;
                &.pro {
                  i {
                    background: url('../../images/sprite.svg') no-repeat 0 -455px;
                  }
                }
                &.expert {
                  i {
                    background: url('../../images/sprite.svg') no-repeat -20px -455px;
                  }
                }
                i {
                  width: 16px;
                  height: 15px;
                  margin-right: 6px;
                }
              }
              .gac-writer-project-count {
                display: flex;
                align-items: center;
                line-height: 15px;
                margin-right: 10px;
                i {
                  width: 12px;
                  height: 15px;
                  margin-right: 6px;
                  background: url('../../images/sprite.svg') no-repeat 0 -410px;
                }
              }
              .gac-writer-project-rating {
                display: flex;
                align-items: center;
                line-height: 15px;
                i {
                  width: 16px;
                  height: 15px;
                  margin-right: 6px;
                  background: url('../../images/sprite.svg') -15px -410px;
                }
              }
              .gac-writer-status {
                position: relative;
                top: 2px;
                flex: 0 0 20px;
                height: 18px;
                margin: 0 0 0 8px;
                background: url('../../images/sprite.svg') no-repeat -150px -390px;
                &[data-status="favorite"] {
                  background-position: -175px -390px;
                }
              }
            }
          }
        }
        .gac-writer-notes {
          line-height: 22px;
          padding-bottom: 13px;
          color: $light-text-color;
        }
        .gac-writer-item-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: -1px;
          top: -1px;
          bottom: -1px;
          right: -1px;
          visibility: hidden;
          opacity: 0;
          z-index: -1;
          transition: opacity .3s ease;
          .gac-btns-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $green-color;
            opacity: 0.7;
            border-radius: 2px;
          }
          span {
            position: relative;
            width: 40px;
            height: 40px;
            margin: 0 10px;
            background-color: #ffffff;
            border-radius: 100%;
            cursor: pointer;
            i {
              position: absolute;
              width: 20px;
              height: 20px;
              left: 50%;
              top: 50%;
              margin: -10px 0 0 -10px;
              background: url('../../images/sprite.svg') -100px -490px;
              &.gac-icon-1 {
                background-position: -100px -490px;
              }
              &.gac-icon-2 {
                background-position: -120px -490px;
              }
            }
            &[data-status="favorite"] {
              i.gac-icon-2 {
                background-position: -175px -389px;
              }
            }
          }
        }
        .gac-writer-item-hint {
          position: absolute;
          top: -40px;
          left: 50%;
          line-height: 22px;
          padding: 4px 16px 6px;
          background-color: $dark-text-color;
          color: $white-color;
          font-size: 15px;
          white-space: nowrap;
          border-radius: 4px;
          transform: translateX(-50%);
          &:before {
            position: absolute;
            left: 50%;
            bottom: -6px;
            margin-left: -6px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $dark-text-color;
            content: "";
          }
        }
      }
    }
    .gac-writers-btn {
      padding-top: 24px;
      text-align: center;
      .gac-btn {
        min-width: 156px;
      }
    }
  }
  &.gac-deactivate-account-modal {
    width: 555px;
    left: 50%;
    top: 50%;
    padding-bottom: 23px;
    margin-left: -277px;
    .gac-modal-text {
      line-height: 26px;
      padding-right: 37px;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  &.gac-upload-image-modal {
    position: relative;
    width: 480px;
    padding: 10px 30px 30px;
    top: 50%;
    //margin: 40px 0;
    //margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    .gac-close-modal {
      top: 33px;
    }
    h1 {
      line-height: 68px;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
    }
    p {
      line-height: 24px;
      margin-bottom: 18px;
      //color: $light-text-color;
    }
    .gac-upload-btn-wrap {
      display: flex;
      justify-content: center;
      .gac-btn {
        margin-right: 0;
      }
    }
  }
  &.gac-referral-modal {
    width: 556px;
    left: 50%;
    top: 50%;
    padding: 40px 48px 40px 48px;
    margin: 0 0 0 -278px;
    h1 {
      line-height: 40px;
      margin-bottom: 42px;
      font-size: 31px;
      text-align: left;
    }
    .gac-referral-desc {
      padding-bottom: 18px;
      div {
        display: flex;
        align-items: center;
        min-height: 40px;
        margin-bottom: 30px;
        padding-left: 64px;
        position: relative;
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          height: 40px;
          //background: url("../../images/sprite.svg") -45px -500px;
          &.gac-referral-icon-2 {
            background-position: -45px -540px;
          }
        }
        span {
          color: $green-color;
        }
      }
    }
    .gac-referral-link {
      margin-bottom: 32px;
      p {
        line-height: 24px;
        color: $light-text-color;
        font-size: 14px;
      }
      .gac-referral-link-input {
        position: relative;
        input {
          width: 100%;
          height: 48px;
          padding: 0 16px;
          border: 1px solid $border-color;
          color: $light-text-color;
          border-radius: 2px;
        }
        span {
          position: absolute;
          right: 0;
          top: 0;
          height: 48px;
          line-height: 48px;
          padding: 0 35px;
          background: $green-color;
          color: $white-color;
          border-radius: 2px 0 0 2px;
          cursor: pointer;
          &.gac-copied {
            background: transparent;
            color: $green-color;
            cursor: default;
          }
        }
      }
      .gac-hint {
        margin-top: 9px;
      }
    }
    .gac-referral-links {
      text-align: center;
      margin-bottom: 15px;
      p {
        line-height: 16px;
        margin-bottom: 12px;
        font-size: 12px;
        color: $light-text-color;
      }
    }
    .gac-referral-links-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      padding-bottom: 9px;
      &:before {
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -154px;
        width: 308px;
        height: 1px;
        background-color: $disabled-color;
        content: "";
      }
      a {
        padding: 10px;
        margin: 0 12px;
        i {
          display: block;
          width: 20px;
          height: 20px;
          background: url("../../images/sprite.svg") 0 0;
          &.gac-icon-1 {
            width: 22px;
            background-position: 0 -520px;
          }
          &.gac-icon-2 {
            background-position: 0 -540px;
          }
          &.gac-icon-3 {
            background-position: 0 -560px;
          }
          &.gac-icon-4 {
            background-position: 0 -580px;
          }
        }
      }
    }
    .gac-referral-data {
      display: flex;
      justify-content: center;
      margin-bottom: 31px;
      p {
        line-height: 24px;
        margin: 0 33px;
        color: $light-text-color;
        font-size: 12px;
        text-transform: uppercase;
        span {
          position: relative;
          top: 1px;
          margin-left: 6px;
          color: $dark-text-color;
          font-size: 15px;
        }
      }
    }
    .gac-referral-dashboard {
      display: flex;
      justify-content: center;
      .gac-show-dashboard {
        line-height: 22px;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .gac-referral-dashboard-data {
      width: 100%;
      padding: 32px 41px;
      background-color: #f7f9fb;
      a {
        display: inline-block;
        line-height: 22px;
        margin-bottom: 14px;
        color: $yellow-color-dark;
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      p {
        line-height: 22px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        strong {
          margin-right: 4px;
        }
        a {
          font-weight: normal;
          color: $dark-text-color;
          &:hover {
            color: $green-color;
          }
        }
      }
    }
    .gac-referral-get-link {
      display: flex;
      justify-content: center;
      .gac-btn {
        margin: 0;
        padding: 0 30px;
      }
    }
  }
  &.gac-rate-writer-modal {
    width: 588px;
    left: 50%;
    top: 50%;
    padding: 40px 48px 40px 48px;
    margin: 0 0 0 -294px;
    h1 {
      line-height: 40px;
      margin-bottom: 42px;
      font-size: 31px;
      text-align: left;
      &.gac-nps-modal-title {
        line-height: 26px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .gac-rate-desc {
      margin-bottom: 12px;
    }
    textarea {
      height: 120px;
      margin-bottom: 0;
    }
    .gac-share-feedback,
    .gac-remove-writer {
      display: flex;
      align-items: center;
      line-height: 22px;
      margin-bottom: 38px;
      cursor: pointer;
      user-select: none;
      input[type="checkbox"] {
        display: none;
      }
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        //background: url("../../images/sprite.svg") -40px -90px;
      }
    }
    .gac-remove-writer {
      margin-bottom: 8px;
    }
    .gac-rate-writer-btns {
      display: flex;
      justify-content: center;
      .gac-btn {
        min-width: 140px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
  &.gac-client-feedback-modal {
    width: 500px;
    left: 50%;
    top: 50%;
    padding: 36px 48px 40px 48px;
    margin: 0 0 0 -250px;
    h1.gac-client-feedback-title {
      line-height: 36px;
      font-size: 18px;
      font-weight: 600;
      i {
        display: inline-block;
        position: relative;
        top: 6px;
        margin-right: 10px;
        width: 32px;
        height: 31px;
      }
      a {
        margin-left: 5px;
        color: $green-color;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
  &.gac-download-file {
    width: 392px;
    left: 50%;
    top: 50%;
    margin: 0 0 0 -196px;
    padding: 36px 20px 18px;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
    .gac-close-modal {
      top: 12px;
      right: 12px;
    }
    .gac-download-file-svg {
      position: absolute;
      left: 50%;
      top: -36px;
      transform: translateX(-50%);
    }
    .gac-download-file-h1 {
      font-weight: 700;
    }
    .gac-download-file-p {
      margin-bottom: 12px;
    }
    a {
      color: $green-color;
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.gac-request-revision-modal {
    width: 500px;
    left: 50%;
    top: 50%;
    padding: 36px 48px 40px 48px;
    margin: 0 0 0 -250px;
    .gac-request-revision-modal__icon {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      img {
        max-width: 80px;
      }
    }
    h2 {
      text-align: center;
    }
    .gac-btn-wrap {
      display: flex;
      justify-content: center;
      .gac-btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .gac-create-project-modal-fields {
    display: flex;
    flex-wrap: wrap;
    .gac-create-project-modal-topic {
      flex: 1 1 auto;
      margin-right: 16px;
    }
    .gac-create-project-modal-type {
      width: 216px;
      .gac-filter-item__menu {
        width: calc(100% + 2px);
      }
    }
  }
  .gac-modal-additional-fields {
    display: flex;
    flex-wrap: wrap;
    .gac-modal-field {
      width: 174px;
      margin-right: 16px;
      input {
        margin-bottom: 0;
      }
      &.gac-order-on {
        position: relative;
        .gac-daypicker-wrap {
          position: absolute;
          bottom: 48px;
          left: 0;
          margin: 0;
          background-color: $white-color;
          box-shadow: 0 -10px 100px 0 rgba(159, 177, 188, 0.3);
          z-index: 11;
        }
      }
      &.gac-word-count {
        width: 105px;
      }
      .gac-select-menu {
        width: 300px;
        bottom: 46px;
      }
    }
  }
  .gac-modal-project-fields {
    margin-bottom: 24px;
    .gac-modal-project-field {
      display: flex;
      height: 38px;
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      .gac-field-right {
        display: flex;
        align-items: center;
        .gac-project-type {
          margin-right: 9px;
        }
      }
      .gac-modal-project-field__label,
      .gac-modal-project-field__value {
        line-height: 36px;
        color: $dark-text-color;
        &.gac-modal__avatar {
          position: relative;
          padding-right: 30px;
          span {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -11px;
            width: 22px;
            height: 22px;
            background: url('../../images/sprite.svg') 0 -200px;
          }
          .gac-creator-logo {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -11px;
            width: 22px;
            height: 22px;
            border-radius: 100%;
          }
        }
      }
      .gac-modal-project-field__label {
        margin-right: 30px;
        color: $light-text-color;
      }
      .gac-modal-project-field__status {
        position: relative;
        line-height: 36px;
        padding-left: 16px;
        text-transform: capitalize;
        i {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          background: url('../../images/sprite.svg') 0 0;
          &.auto,
          &.approved {
            background-position: -175px -115px;
          }
          &.designing,
          &.writing,
          &.editing {
            background-position: -165px -115px;
          }
          &.published {
            background-position: -185px -105px;
          }
          &.revision {
            background-position: -165px -115px;
          }
          &.draft {
            background-position: -155px -125px;
          }
          &.matching {
            background-position: -155px -115px;
          }
          &.pitching {
            background-position: -175px -105px;
          }
          &.approval {
            background-position: -165px -105px;
          }
          &.cancelled {
            background-position: -155px -105px;
          }
        }
      }
    }
  }
  .gac-modal-project-time {
    margin-bottom: 24px;
    .gac-modal-project-time-field {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      .gac-modal-project-time-field__label,
      .gac-modal-project-time-field__value {
        line-height: 22px;
        color: $dark-text-color;
        font-size: 15px;
      }
    }
  }
  h1 {
    line-height: 50px;
    margin: 0 0 35px;
    color: $dark-text-color;
    font-size: 45px;
    font-weight: bold;
    text-align: center;
  }
  h2 {
    line-height: 26px;
    margin: 0 0 24px;
    color: $dark-text-color;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
  }
  label {
    display: block;
    line-height: 24px;
    color: $dark-text-color;
    font-size: 14px;
  }
  .gac-input {
    width: 100%;
    max-width: 100%;
    height: 48px;
    padding: 0 16px;
    margin: 0 0 24px 0;
    color: $dark-text-color;
    font-size: 15px;
    border: 1px solid $border-color;
    border-radius: 2px;
    transition: border-color .2s ease;
    &.gac-invalid {
      border-color: $red-color;
    }
    &.gac-input-calendar {
      background: url('../../images/calendar.svg') no-repeat center right;
    }
    &:disabled {
      background-color: #fafafa;
    }
  }
  textarea {
    width: 100%;
    max-width: 100%;
    line-height: 22px;
    height: 158px;
    padding: 12px 16px;
    margin: 0 0 30px 0;
    color: $dark-text-color;
    font-size: 15px;
    border: 1px solid $border-color;
    border-radius: 2px;
    transition: border-color .2s ease;
    box-shadow: none!important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &.gac-invalid {
      border-color: $red-color;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: #fafafa;
    }
  }
  .gac-modal-btns {
    display: flex;
    align-items: center;
  }
  .gac-sending-failed {
    line-height: 40px;
    color: $red-color;
    font-size: 14px;
  }
  .gac-btn {
    min-width: 178px;
    margin-right: 20px;
  }
  .gac-close-modal {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -175px -50px #ffffff;
    cursor: pointer;
    z-index: 10;
  }
  .gac-change-modal{
    position: absolute;
    top: 23px;
    left: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -7px -122px #ffffff;
    cursor: pointer;
  }
  .gac-success-icon {
    margin: 60px auto 33px;
  }
  .gac-agreement {
    display: flex;
    flex-wrap: wrap;
    padding-top: 17px;
    margin-bottom: 33px;
    input {
      display: none;
    }
    label {
      width: 16px;
      height: 16px;
      margin: 0 13px 0 3px;
      background: url('../../images/sprite.svg') -40px -90px;
      cursor: pointer;
      &.gac-invalid {
        background-position: -140px -415px;
      }
    }
    input:checked + label {
      background-position: -40px -110px;
    }
    a {
      font-size: 15px;
      margin: 0 4px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .gac-forgot-password {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 11px;
    font-size: 12px;
    text-decoration: underline;
    span {
      position: relative;
      top: -11px;
      cursor: pointer;
    }
  }
  .gac-input-wrap {
    position: relative;
    margin: 0 0 24px 0;
    .gac-input {
      margin: 0;
    }
    .gac-login-icon-1 {
      position: absolute;
      right: 18px;
      top: 50%;
      width: 20px;
      height: 17px;
      background: url('../../images/sprite.svg') -50px -430px;
      transform: translateY(-50%);
      cursor: pointer;
      &.gac-visible {
        background-position: -70px -430px;
      }
    }
  }
}

/* AI results modal */
.gac-ai-results-modal {
  position: fixed;
  width: 100%;
  max-height: calc(100% - 20px);
  max-width: 1024px;
  left: 50%;
  top: 10px;
  padding: 23px 23px 30px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  z-index: 99999;
  overflow: auto;
  transform: translateX(-50%);
  .gac-close-modal {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -175px -50px #ffffff;
    cursor: pointer;
    z-index: 10;
  }
  &__title {
    line-height: 26px;
    margin-bottom: 8px;
    color: $dark-text-color;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    span {
      color: $green-color;
    }
  }
  &__table {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__head {
    display: flex;
    div {
      &:first-child {
        padding-left: 20px;
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        padding-right: 20px;
        border-radius: 0 2px 2px 0;
      }
      &[data-name="block"] {
        flex: 1 1 auto;
      }
      &[data-name="score"] {
        flex: 0 0 80px;
        text-align: right;
      }
      line-height: 40px;
      background-color: $green-color;
      color: $white-color;
    }
  }
  &__table-item {
    display: flex;
    &.gac-empty {
      line-height: 40px;
      justify-content: center;
    }
    &:nth-child(even) {
      background-color: #f5f5f5;
    }
    &:last-child {
      margin-bottom: 0;
    }
    div {
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
      &[data-name="block"] {
        flex: 1 1 auto;
        padding-top: 10px;
      }
      &[data-name="score"] {
        flex: 0 0 80px;
        padding-top: 10px;
        text-align: right;
      }
    }
  }
  &__phrase {
    line-height: 40px;
    padding: 0 20px;
    background-color: #b6b1c2;
    border-radius: 2px;
  }
  &__website {
    line-height: 40px;
    padding: 0 20px;
    a {
      font-size: 14px;
    }
  }
}

/* Modal subsciptions */
.gac-modal-subscriptions {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 32px;
  background-color: $white-color;
  z-index: 99999;
  .gac-close-modal {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -175px -50px #ffffff;
    cursor: pointer;
    z-index: 10;
  }
}
/* Modal confirm subscription */
.gac-modal-subscription,
.gac-modal-create-brand,
.gac-modal-update-account-list {
  position: fixed;
  width: 580px;
  left: 50%;
  top: 50%;
  margin: 0 0 0 -290px;
  padding: 32px 72px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  z-index: 99999;
  &.gac-keywords {
    width: 680px;
    margin: 0 0 0 -340px;
    padding-left: 40px;
    padding-right: 40px;
    h1 {
      margin-bottom: 32px;
      font-size: 26px;
    }
    .gac-btn-wrap {
      display: flex;
      justify-content: center;
    }
  }
  &.gac-setup {
    padding-left: 62px;
    padding-right: 62px;
    h1 {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      font-size: 26px;
      i {
        position: relative;
        top: 5px;
        width: 32px;
        height: 31px;
        margin-left: 16px;
        //background: url('../../images/sprite.svg') -190px -630px #ffffff;
      }
    }
    h3 {
      margin: -10px 0 20px 0;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
    }
  }
  &.gac-pause-subscription,
  &.gac-cancel-subscription,
  &.gac-cancel-project,
  &.gac-update-acc-list {
    h1 {
      margin-bottom: 28px;
      font-size: 26px;
    }
    .gac-btn-wrap {
      display: flex;
      justify-content: center;
    }
  }
  .gac-change-modal{
    position: absolute;
    top: 23px;
    left: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -7px -122px #ffffff;
    cursor: pointer;
  }
  .gac-close-modal {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -175px -50px #ffffff;
    cursor: pointer;
    z-index: 10;
  }
  h1 {
    line-height: 40px;
    margin: 0 0 64px 0;
    font-size: 31px;
    text-align: center;
  }
  h2:not(.rdp-caption_label) {
    line-height: 26px;
    margin: 0 0 50px 0;
    font-size: 20px;
    text-align: center;
  }
  .gac-subscription-info {
    &:nth-child(n+1) {

    }
    display: flex;
    justify-content: space-between;
    max-width: 430px;
    width: 100%;
    margin: 0 auto 40px;
    div:last-child {
      p, .gac-info-div {
        position: relative;
        padding-left: 17px;
        user-select: none;
        i {
          position: absolute;
          top: 10px;
          left: 0;
          background: url("../../images/sprite.svg") 0 0;
          &.gac-mark {
            width: 9px;
            height: 7px;
            background-position: -65px -630px;
          }
        }
        .gac-start-date {
          position: relative;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }
        .gac-daypicker-wrap {
          position: absolute;
          top: 29px;
          right: 27px;
          z-index: 7;
          background-color: $white-color;
          box-shadow: 0 10px 40px 0 rgba(159, 177, 188, 0.3);
        }
      }
    }
    p, .gac-info-div {
      line-height: 24px;
      margin-bottom: 8px;
      &.uni-current-plan {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .gac-keywords-options {
    max-width: 430px;
    width: 100%;
    margin: 0 auto;
    p {
      line-height: 24px;
      margin-bottom: 17px;
    }
  }
  .gac-keywords-radio {
    margin-bottom: 48px;
    &.gac-invalid {
      span {
        svg {
          path {
            fill: $red-color;
          }
        }
        //background-position: -90px -410px;
      }
    }
    .gac-keywords-label {
      display: flex;
      align-items: center;
      line-height: 22px;
      margin-bottom: 16px;
      cursor: pointer;
      .gac-hint {
        margin-left: 4px;
        .gac-hint-wrap .gac-hint-text {
          margin-left: -106px;
          &:before {
            margin-left: 97px;
          }
        }
      }
    }
    input {
      display: none;
    }
    span {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      //background: url('../../images/sprite.svg') no-repeat -110px -290px;
    }
    input:checked + span {
      //background-position: -130px -290px;
    }
  }
  .gac-sub-dropin-wrap {
    max-width: 298px;
    margin: 0 auto;
  }
  .gac-setup-steps {
    .gac-steps-item {
      position: relative;
      min-height: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-left: 49px;
      margin-bottom: 40px;
      &.gac-disabled {
        p {
          color: $light-text-color;
        }
      }
      &.gac-active {
        .gac-step-number {
          border-color: $green-color;
        }
      }
      &.gac-filled {
        .gac-step-number {
          border-color: transparent;
          span {
            width: 19px;
            height: 13px;
            //background: url('../../images/sprite.svg') no-repeat -225px -630px;
            text-indent: -9999999px;
          }
          i {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 19px;
            height: 13px;
            margin: -6px 0 0 -10px;
          }
        }
        p {
          color: $green-color;
        }
      }
      .gac-step-number {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border: 1px solid $disabled-color;
        border-radius: 100%;
        font-size: 18px;
        color: $light-text-color;
        font-weight: 600;
        span {
          i {
            display: none;
          }
        }
      }
      p {
        display: flex;
        align-items: center;
        width: 100%;
        line-height: 22px;
        &.gac-with-input {
          display: flex;
          align-items: center;
        }
        .gac-input {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 200px;
          margin-left: 11px;
          .gac-error {
            position: absolute;
            left: 0;
            top: 40px;
            line-height: 16px;
            font-size: 12px;
          }
        }
        input {
          height: 36px;
          width: 100%;
          padding: 0 10px;
          font-size: 15px;
          color: $dark-text-color;

          border: 1px solid $border-color;
          border-radius: 2px;
          &.gac-invalid {
            border-color: $red-color;
          }
        }
        .gac-btn {
          margin-right: 7px;
          &.gac-disabled {
            color: $light-text-color;
          }
        }
        .gac-secondary-btn {
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .gac-link-wrap {
    display: flex;
    justify-content: center;
    span {
      line-height: 22px;
      font-size: 15px;
      color: $yellow-color-dark;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .gac-keywords-header {
    display: flex;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 20px;
      padding: 4px 10px;
      border: 1px solid $border-color;
      &:first-child {
        flex: 0 0 80px;
        border-radius: 2px 0 0 0;
        border-right: 0;
      }
      &:last-child {
        border-radius: 0 2px 0 0;
        flex: 1 1 auto;
      }
    }
  }
  .gac-textarea-wrap {
    max-height: 373px;
    margin-bottom: 24px;
    border: 1px solid $border-color;
    border-top: 0;
    border-radius: 0 0 2px 2px;
    overflow: auto;
  }
  .gac-keywords-textarea {
    display: flex;
    position: relative;
    .gac-keywords-order {
      flex: 0 0 80px;
      padding-top: 8px;
      padding-bottom: 12px;
      border-right: 1px solid $border-color;
      span {
        display: block;
        line-height: 22px;
        text-align: center;
      }
    }
    textarea {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 80px;
      line-height: 22px;
      border: 0;
      padding: 8px 10px 0;
      min-width: calc(100% - 80px);
      max-width: calc(100% - 80px);
      min-height: 100%;
      max-height: 100%;
      &:focus {
        border: 0;
        outline: none;
      }
    }
  }
  .gac-cancel-desc {
    line-height: 24px;
    margin-bottom: 24px;
    i {
      display: inline-block;
      position: relative;
      top: 6px;
      margin-left: 2px;
      width: 24px;
      height: 24px;
      background: url("../../images/sprite.svg") -20px -650px;
    }
  }
  .gac-reason-textarea {
    textarea {
      width: 100%;
      max-width: 100%;
      line-height: 22px;
      height: 116px;
      padding: 12px 16px;
      margin: 0 0 10px 0;
      color: $dark-text-color;
      font-size: 15px;
      border: 1px solid $border-color;
      border-radius: 2px;
      transition: border-color .2s ease;
      box-shadow: none!important;
      &.gac-invalid {
        border-color: $red-color;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .gac-pause-desc {
    margin-bottom: 24px;
    & > div {
      position: relative;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .gac-start-date-wrap {
      position: relative;
      .gac-start-date {
        display: inline-block;
        cursor: pointer;
        &:hover {
          span {
            text-decoration: none;
          }
        }
        span {
          text-decoration: underline;
        }
        i {
          display: inline-block;
          width: 16px;
          height: 12px;
          background: url("../../images/sprite.svg") -190px -680px;
        }
      }
      .gac-daypicker-wrap {
        position: absolute;
        top: 24px;
        left: 0;
        margin: 0!important;
        z-index: 7;
        background-color: $white-color;
        box-shadow: 0 10px 40px 0 rgba(159, 177, 188, 0.3);
      }
    }
  }
}
.gac-modal-subscription:not(.gac-pause-subscription) {
  max-height: calc(100vh - 30px);
  overflow: auto;
}
.gac-modal-create-brand {
  max-height: calc(100vh - 30px);
  overflow: auto;
  h1 {
    font-size: 18px;
    margin-bottom: 24px;
  }
  .gac-btn-wrap {
    display: flex;
    justify-content: center;
    .gac-btn {
      min-width: 180px;
      margin-right: 10px;
    }
    .gac-btn-v2 {
      margin-left: 10px;
    }
  }
  .gac-hint .gac-hint-wrap .gac-hint-text {
    width: 450px;
    left: -141px;
    transform: none;
    &:before {
      left: 140px;
      margin-left: 0;
    }
  }
}
.gac-modal-images {
  position: absolute;
  width: 1230px;
  left: 50%;
  top: 24px;
  margin: 0 0 0 -615px;
  padding: 38px 50px;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: 2px;
  z-index: 99999;
  .gac-close-modal {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -175px -50px #ffffff;
    cursor: pointer;
  }
}
@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, .gac-modal.gac-edit-project-modal {
    transform: translate(-50%, -50%);
  }
  *::-ms-backdrop, .gac-modal.gac-create-project-modal {
    transform: translate(-50%, -50%);
  }
}

/* Score html */
.gac-score-html {
  display: flex;
  margin-bottom: 16px;
  .gac-score-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 5px;
    border: 1px solid $disabled-color;
    border-radius: 100%;
    cursor: pointer;
    user-select: none;
    &:hover,
    &.gac-active {
      border: 0;
      background: $green-color;
      color: $white-color;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.gac-score-desc {
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  margin-bottom: 32px;
  color: $light-text-color;
  font-size: 12px;
}

/* Full image modal */
.gac-full-image-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(72, 75, 88, 0.55);
  z-index: 99999;
  outline: none;
  cursor: pointer;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

/* Daypicker */
.gac-daypicker-wrap {
  *:focus {
    outline: none;
  }
  .rdp-months {
    padding: 20px 17px 20px;
  }
  .rdp-caption {
    padding: 0;
    margin-bottom: 16px;
    .rdp-caption_label {
      border: 0;
      padding: 0 0 0 12px;
      color: $dark-text-color;
      font-size: 15px;
      font-weight: normal;
    }
  }
  .rdp-nav_button {
    padding: 0;
    width: 30px;
    height: 30px;
  }
  .rdp-nav_button:focus-visible:not([disabled]),
  .rdp-nav_button:active:not([disabled]) {
    border: 0;
    background-color: transparent;
  }
  .rdp-nav_button:hover:not([disabled]) {
    background-color: transparent;
  }
  .rdp-head_cell {
    color: #6f6f7a;
    opacity: .65;
  }
  .rdp-day {
    width: 37px;
    height: 37px;
    line-height: 37px;
    padding: 0;
    margin: 0 2px;
    font-size: 12px;
    color: #1e1e1e;
  }
  .rdp-day:hover:not([disabled]) {
    background-color: #74a3d2!important;
    color: #fff!important;
  }
  .rdp-day_selected:not([disabled]), .rdp-day_selected:focus:not([disabled]), .rdp-day_selected:active:not([disabled]), .rdp-day_selected:hover:not([disabled]) {
    background-color: #74a3d2!important;
    color: $white-color!important;
  }
  .rdp-day_selected:focus:not([disabled]) {
    outline: none;
  }
  .rdp-button:focus-visible:not([disabled]), .rdp-button:active:not([disabled]) {
    border: 0;
    background-color: #74a3d2!important;
    color: #fff;
  }

  .DayPickerInput-Overlay {
    bottom: 48px;
    box-shadow: -5px 0 100px 0 rgba(159, 177, 188, 0.3);
    z-index: 10;
  }
  .DayPicker-NavButton {
    top: 24px;
    width: 24px;
    height: 24px;
    margin: 0;
    background: url('../../images/sprite.svg') no-repeat -155px -155px;
    &.DayPicker-NavButton--prev {
      right: 64px;
    }
    &.DayPicker-NavButton--next {
      right: 32px;
      background-position: -155px -180px;
    }
  }
  .DayPicker-Month {
    margin: 0;
    .DayPicker-Weekdays {
      display: flex;
      margin: 0 0 14px;
      .DayPicker-WeekdaysRow {
        display: flex;
      }
      .DayPicker-Weekday {
        width: 37px;
        margin: 0 6px;
        line-height: 20px;
        padding: 0;
        font-size: 14px;
        color: $light-text-color;
        opacity: 0.65;
      }
    }
    .DayPicker-Week {
      display: flex;
      .DayPicker-Day {
        width: 37px;
        height: 37px;
        line-height: 37px;
        padding: 0;
        margin: 0 6px;
        font-size: 12px;
        color: $dark-text-color;
        &.DayPicker-Day--disabled {
          color: $light-text-color;
          opacity: 0.4;
        }
        &.DayPicker-Day--selected:not(.DayPicker-Day--outside),
        &:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):hover {
          background-color: $blue-color!important;
          color: $white-color!important;
        }
        &.DayPicker-Day--today:not(.DayPicker-Day--outside) {
          font-weight: normal;
          line-height: 35px;
          border: 1px solid $blue-color!important;
        }
      }
    }
  }
}

.gac-track-vertical {
  position: absolute;
  right: 0;
  top: 0;
  width: 4px;
  height: 100%;
  & > div {
    background-color: $border-color;
    border-radius: 2px;
  }
}
.gac-track-horizontal {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  & > div {
    background-color: $border-color;
    border-radius: 2px;
  }
}

/* Upload image */
.gac-upload-image-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 274px;
  margin: 0 0 30px 0;
  border: 1px solid $border-color;
  border-radius: 2px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  p {
    line-height: 38px!important;
    margin-bottom: 7px!important;
    color: $light-text-color;
  }
  p + p {
    margin-top: -4px;
  }
  span {
    line-height: 40px;
    padding: 0 20px;
    background-color: $disabled-color;
    color: $light-text-color;
    border-radius: 2px;
  }
}

/* React crop */
.gac-react-crop {
  margin: 0 0 8px 0;
  text-align: center;
  .ReactCrop {
    background-color: transparent;
  }
}
.gac-react-crop + .gac-error {
  margin-bottom: 20px;
  font-size: 14px;
}
.ReactCrop__image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/* Modal form */
.gac-modal-row {
  margin-bottom: 24px;
  &__label {
    display: block;
    line-height: 24px;
    color: $dark-text-color;
    font-size: 14px;
    &.gac-label-with-hint {
      display: flex;
      .gac-hint {
        margin-left: 5px;
      }
    }
  }
  &__field {
    & > input {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      border: 1px solid $border-color;
      background-color: #ffffff !important;
      color: $dark-text-color;
      border-radius: 2px;
      &.gac-invalid {
        border-color: $red-color;
      }
      &:focus {
        outline: none;
      }
    }
    & > textarea {
      width: 100%;
      max-width: 100%;
      line-height: 22px;
      height: 116px;
      padding: 12px 16px;
      color: $dark-text-color;
      font-size: 15px;
      border: 1px solid $border-color;
      border-radius: 2px;
      transition: border-color .2s ease;
      box-shadow: none!important;
      &:focus {
        outline: none;
      }
    }
    &.gac-brand-colors {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -8px;
      .gac-color-picker {
        margin-right: 8px;
        margin-bottom: 8px;
        .gac-color-picker-value {
          border: 2px solid $green-color;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.gac-brand-assets {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -8px;
      .gac-brand-asset {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 0 0 70px;
        max-width: 70px;
        min-height: 70px;
        padding: 0 10px;
        margin-right: 8px;
        margin-bottom: 8px;
        background-color: $border-color;
        border-radius: 2px;
        img {
          max-width: 70px;
          max-height: 70px;
          width: auto;
          height: auto;
        }
        .gac-filename {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .gac-remove {
          position: absolute;
          right: -7px;
          top: -7px;
          width: 14px;
          height: 14px;
          background-color: $red-color;
          border-radius: 100%;
          color: $white-color;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 10px;
          cursor: pointer;
        }
      }
    }
    & > label {
      width: 50px;
      height: 36px;
      border: 2px solid $green-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      cursor: pointer;
      input[type="file"] {
        display: none;
      }
    }
  }
}