@import "variables";

.gac-stripe {
    .gac-stripe-cards {
        position: relative;
        margin-bottom: 17px;
        &__edit-btn {
            position: absolute;
            right: 0;
            top: 0;
            line-height: 25px;
            color: $dark-text-color;
            font-size: 15px;
            text-decoration: underline;
            cursor: pointer;
        }
        &__title {
            line-height: 25px;
            margin-bottom: 9px;
            color: $dark-text-color;
            font-size: 15px;
        }
        &__list {
            margin-bottom: 17px;
            li {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .gac-stripe-card-item {
            &.gac-invalid {
                label {
                    border-color: $red-color;
                }
            }
            input[type="radio"] {
                display: none;
            }
            input[type="radio"]:checked + label .gac-stripe-card-item__icon {
                background: url("../../images/sprite.svg") -130px -290px;
            }
            label {
                position: relative;
                display: block;
                min-height: 64px;
                padding: 11px 50px 10px 73px;
                border: 1px solid $border-color;
                border-radius: 4px;
                cursor: pointer;
            }
            &__type-icon {
                position: absolute;
                left: 16px;
                top: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 41px;
                height: 32px;
                background-color: $border-color;
                border-radius: 4px;
                color: #fff;
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                &.visa {
                    background: url("../../images/sprite.svg") -50px -680px;
                    text-indent: -99999px;
                }
                &.mastercard {
                    background: url("../../images/sprite.svg") -95px -680px;
                    text-indent: -99999px;
                }
                &.amex {
                    background: url("../../images/sprite.svg") -140px -680px;
                    text-indent: -99999px;
                }
            }
            &__number,
            &__type {
                line-height: 22.5px;
                color: $dark-text-color;
                font-weight: 600;
                font-size: 15px;
            }
            &__type {
                margin-top: -3px;
                font-weight: 400;
                text-transform: capitalize;
            }
            &__icon {
                position: absolute;
                top: 22px;
                right: 16px;
                width: 20px;
                height: 20px;
                background: url("../../images/sprite.svg") -110px -290px;
                &[data-default="true"] {
                    background: url("../../images/sprite.svg") -130px -290px;
                }
            }
            &__remove {
                position: absolute;
                top: 22px;
                right: 16px;
                width: 20px;
                height: 20px;
                background-image: url("../../images/sprite.svg");
                background-position: -200px -390px;
                background-color: $red-color;
                border-radius: 100%;
                cursor: pointer;
            }
        }
        &__wrap {
            text-align: center;
        }
        &__btn {
            display: inline-block;
            padding: 0;
            border: 0;
            margin: 0;
            line-height: 21px;
            background-color: transparent;
            color: $light-text-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .gac-stripe-form {
        padding: 16px;
        border: 1px solid #f7c300;
        border-radius: 4px;
        &__title {
            position: relative;
            line-height: 24px;
            margin-bottom: 13px;
            padding-left: 48px;
            color: $dark-text-color;
            font-size: 16px;
            font-weight: bold;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                width: 32px;
                height: 24px;
                background: url("../../images/sprite.svg") 0 -705px;
                content: "";
            }
        }
        &__item {
            margin-bottom: 13px;
            label {
                display: block;
                line-height: 25.5px;
                margin-bottom: 1px;
                color: $dark-text-color;
                font-size: 15px;
                span {
                    color: $border-color;
                }
            }
            .StripeElement {
                padding: 10px 0 10px 12px;
                border: 1px solid $border-color;
                border-radius: 4px;
                cursor: text;
            }
            .StripeElement--invalid {
                border-color: $red-color;
            }
        }
        &__checkbox {
            display: flex;
            align-items: center;
            line-height: 16px;
            color: $dark-text-color;
            font-size: 14px;
            cursor: pointer;
            user-select: none;
            input {
                display: none;
            }
            input:checked + span {
                background: url("../../images/sprite.svg") -40px -110px;
            }
            span {
                flex: 0 0 16px;
                height: 16px;
                margin-right: 6px;
                background: url("../../images/sprite.svg") -40px -90px;
            }
        }
    }
    .gac-cart-confirm-order {
        margin-top: 50px !important;
    }
    .gac-modal-subscription & {
        max-width: 298px;
        margin: 0 auto;
        padding: 34px 0 20px;
        .gac-btn {
            width: 100%;
        }
    }
}
.gac-cart-total + .gac-stripe {
    margin-top: 24px;
}
