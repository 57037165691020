@import 'variables';

/* Icons */
.gac-success-icon {
  display: flex;
  width: 80px;
  height: 80px;
  background-color: $green-color;
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  i {
    width: 28px;
    height: 20px;
    margin: 0 auto;
    background: url('../../images/sprite.svg') -125px -180px;
  }
}
.gac-feedback-icon {
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  background: url('../../images/sprite.svg') -60px -290px;
}
.gac-close-icon {
  display: block;
  width: 32px;
  height: 32px;
  background: url('../../images/sprite.svg') -55px -310px;
  cursor: pointer;
}
.gac-star-icon {
  display: block;
  width: 20px;
  height: 19px;
  background: url('../../images/sprite.svg') 0 -370px;
}
.gac-expert-icon {
  display: block;
  width: 22px;
  height: 14px;
  background: url('../../images/sprite.svg') -20px -370px;
}
.gac-hurray-icon {
  width: 32px;
  height: 31px;
  background: url('../../images/sprite.svg') -190px -630px;
}

.gac-hint {
  display: flex;
  align-items: center;
  color: $dark-text-color;
  font-size: 12px;
  &.gac-hint-default {
    color: $light-text-color;
    .gac-hint-wrap {
      i {
        background-position: -190px -345px;
      }
    }
  }
  &.gac-hint-warning {
    color: $yellow-color-dark-2;
    .gac-hint-wrap {
      i {
        background-position: -35px -410px;
      }
    }
  }
  &.gac-hint-success {
    color: $green-color;
    .gac-hint-wrap {
      i {
        background-position: -55px -455px;
      }
    }
  }
  span {
    line-height: 16px;
    cursor: pointer;
  }
  .gac-hint-wrap {
    position: relative;
    i {
      display: block;
      width: 16px;
      height: 16px;
      background: url('../../images/sprite.svg') -45px -370px;
      cursor: pointer;
    }
    .gac-hint-text {
      width: 400px;
      display: block;
      position: absolute;
      left: 50%;
      line-height: 22px;
      padding: 8px 16px 12px 16px;
      background-color: $dark-text-color;
      color: $white-color;
      font-size: 15px;
      border-radius: 4px;
      transform: translateX(-50%);
      z-index: 20;
      cursor: default;
      user-select: text;
      &.gac-bottom {
        &:before {
          bottom: auto;
          top: -6px;
          border-bottom: 6px solid $dark-text-color;
          border-top: 0;
        }
      }
      &:before {
        position: absolute;
        left: 50%;
        bottom: -6px;
        margin-left: -9px;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 6px solid $dark-text-color;
        content: "";
      }
      div {
        padding-bottom: 10px;
        &:last-child {
          padding-bottom: 0;
        }
        strong {
          font-weight: bold;
        }
        span {
          line-height: inherit;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        a {
          font-size: 15px;
          color: #fff;
        }
      }
      ul {
        li {
          position: relative;
          padding-left: 24px;
          line-height: 22px;
          font-size: 13px;
          &.gac-list-title {
            padding-left: 0;
            &:before {
              display: none;
            }
          }
          &.gac-mark {
            &:before {
              width: 11px;
              height: 8px;
              background-position: -132px -715px;
              margin-top: -4px;
            }
          }
          &.gac-plus {
            &:before {
              background-position: -200px -610px;
            }
          }
          &:before {
            position: absolute;
            left: 2px;
            top: 50%;
            width: 11px;
            height: 11px;
            background: url("../../images/sprite.svg") -180px -610px;
            margin-top: -5px;
            content: "";
          }
        }
      }
    }
  }
  span + .gac-hint-wrap {
    margin-left: 6px;
  }
}
.gac-assign-hint {
  position: absolute;
  top: 50%;
  left: 100%;
  line-height: 30px;
  margin-left: 13px;
  margin-top: -16px;
  padding: 0 16px 2px 16px;
  background-color: $dark-text-color;
  color: $white-color;
  font-size: 15px;
  border-radius: 4px;
  z-index: 20;
  white-space: nowrap;
  &:before {
    position: absolute;
    left: -6px;
    top: 50%;
    margin-top: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 9px solid $dark-text-color;
    content: "";
  }
  &.gac-bottom {
    top: auto;
    bottom: -43px;
    left: 50%;
    margin-left: auto;
    transform: translateX(-50%);
    &:before {
      top: -6px;
      left: 50%;
      margin-left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 9px solid $dark-text-color;
    }
  }
  &.gac-hint-warning {
    background-color: $yellow-color-dark-2;
    &.gac-bottom {
      &:before {
        border-bottom-color: $yellow-color-dark-2;
        border-right-color: transparent;
      }
    }
    &:before {
      border-right-color: $yellow-color-dark-2;
    }
  }
}

.gac-checkbox {
  display: flex;
  align-items: flex-start;
  line-height: 22px;
  cursor: pointer;
  user-select: none;
  &.gac-invalid {
    i {
      svg {
        path {
          fill: $red-color;
        }
      }
      //background-position: -140px -415px;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  i {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 12px;
    //background: url("../images/sprite.svg") -40px -90px;
  }
  input[type="checkbox"]:checked + i {
    //background: url("../images/sprite.svg") -40px -110px;
  }
}