@import "variables";

.gac-company-new-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.apply-designers-page {
    .gac-company-new-page__agreement {
      position: relative;
      width: 600px;
      line-height: 16px;
      padding: 0 0 0 23px;
      font-size: 15px;
      label {
        position: absolute;
        left: 0;
        top: 0;

      }
      a {
        font-size: 15px;
      }
    }
    .gac-error {
      margin-top: -11px;
      margin-bottom: 21px;
    }
  }
  &.apply-designers-page-success {
    height: 100vh;
    justify-content: center;
    .gac-company-new-page__svg {
      padding: 0 42px 42px;
    }
    .gac-company-new-page__title-v2 {
      padding-top: 0;
    }
  }
  &__title {
    line-height: 28px;
    padding: 57px 20px 47px;
    background-color: $white-color;
    color: $dark-text-color;
    font-size: 24px;
    font-weight: bold;
    span {
      color: $green-color;
    }
  }
  &__title-v2 {
    width: 100%;
    max-width: 600px;
    line-height: 38px;
    padding-top: 42px;
    background-color: $white-color;
    color: $dark-text-color;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  &__p {
    line-height: 30px;
    font-size: 20px;
    text-align: center;
  }
  &__svg {
    background-color: $white-color;
  }
  &__agreement {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 11px 20px 36px;
    background-color: $white-color;
    font-size: 17px;
    input {
      display: none;
    }
    label {
      width: 16px;
      height: 16px;
      margin: 1px 7px 0 0;
      background: url('../../images/sprite.svg') -60px -715px;
      cursor: pointer;
      &.gac-invalid {
        background-position: -95px -715px;
      }
    }
    input:checked + label {
      background-position: -76px -715px;
    }
    a {
      margin: 0 4px;
      font-size: 17px;
    }
  }
  &__btns {
    padding: 0 20px 40px;
    background-color: $white-color;
  }
  &__login {
    line-height: 20px;
    padding: 0 20px 35px;
    background-color: $white-color;
    font-size: 17px;
    text-align: center;
    a {
      display: inline-block;
      line-height: 24px;
      margin-top: 2px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  &__apply {
    display: flex;
    justify-content: center;
    background-color: $white-color;
    padding-bottom: 80px;
    &.host-epiic {
      div {
        path[fill="#59C69F"] {
          fill: #FF3666!important;
        }
        [stroke="#59C69F"] {
          stroke: #FF3666!important;
        }
      }
      a {
        border-color: #FF3666;
        &:hover {
          background-color: #FF3666;
          color: $white-color;
        }
      }
    }
    div {
      padding-top: 11px;
      margin-right: 12px;
    }
    a {
      line-height: 38px;
      padding: 0 20px;
      margin-left: 12px;
      border: 1px solid $green-color;
      border-radius: 4px ;
      font-size: 17px;
      text-decoration: none;
      transition: all .3s ease;
      &:hover {
        background-color: $green-color;
        color: $white-color;
      }
    }
  }
  &__safe {
    display: flex;
    align-items: center;
    padding: 0 20px 28px;
    background-color: $white-color;
    font-size: 16px;
    i {
      width: 22px;
      height: 34px;
      margin-right: 16px;
      background: url("../../images/sprite.svg") -35px -715px;
    }
  }
  #g-recaptcha {
    margin-bottom: 21px;
  }

}
.gac-company-new-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $white-color;
  width: 672px;
  &__row {
    position: relative;
    flex: 0 0 calc(50% - 12px);
    margin-bottom: 21px;
    &.gac-country-row {
      display: flex;
      justify-content: space-between;
      & > div:first-child {
        flex: 0 0 236px;
      }
      & > div:last-child {
        flex: 0 0 80px;
      }
    }
    &.gac-company-row {
      display: flex;
      justify-content: space-between;
      & > div:first-child {
        flex: 0 0 163px;
      }
      & > div:last-child {
        flex: 0 0 150px;
      }
      .gac-select .gac-select-menu {
        width: 180px;
      }
    }
    &.gac-industry-row {
      display: flex;
      justify-content: space-between;
      & > div:first-child {
        flex: 0 0 calc(50% - 6px);
        max-width: calc(50% - 6px);
      }
      & > div:last-child {
        flex: 0 0 calc(50% - 5px);
      }
      .gac-select {
        max-width: 100%;
        .gac-select-value {
          max-width: 100%;
        }
        .gac-select-menu {
          width: 280px;
          max-height: 378px!important;
        }
      }
    }
    .gac-error {
      position: absolute;
      left: 0;
      top: 100%;
      line-height: 21px;
      padding-top: 1px;
      font-size: 14px;
    }
    .gac-company-new-form__referral-code,
    & > input[type="text"],
    & > input[type="email"],
    & > input[type="password"] {
      width: 100%;
      height: 40px;
      padding: 0 15px;
      border: 1px solid $border-color;
      color: $dark-text-color;
      font-size: 16px;
      border-radius: 4px;
      &.gac-invalid {
        border-color: $red-color;
      }
    }
  }
  &__label {
    line-height: 26px;
    margin-bottom: 1px;
    color: $dark-text-color;
    font-size: 16px;
  }
  &__textarea {
    flex: 0 0 100%;
    margin-bottom: 21px;
    &.gac-english-level {
      flex: 0 0 calc(50% - 12px);
    }
    &.gac-textarea-as-input {
      textarea {
        height: 40px;
      }
    }
    textarea {
      width: 100%;
      max-width: 100%;
      height: 72px;
      padding: 10px 15px;
      border: 1px solid $border-color;
      color: $dark-text-color;
      font-size: 16px;
      border-radius: 4px;
      &:focus {
        outline: none;
      }
      &.gac-invalid {
        border-color: $red-color;
      }
    }
  }
  &__title {
    width: 100%;
    max-width: 600px;
    line-height: 28px;
    padding: 60px 0 23px 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .gac-custom-select {
    .gac-select__menu {
      margin-top: 0;
      border: 0;
      border-radius: 4px;
      box-shadow: 0 0 24px 0 rgba(0,0,0,0.16)!important;
    }
    .gac-select__control {
      border-radius: 4px;
      .gac-select__value-container {
        padding: 5px 15px;
        .gac-select__single-value {
          font-size: 16px;
        }
      }
    }
    .gac-select__indicators {
      padding-right: 10px;
      .gac-select__dropdown-indicator {
        &:after {
          left: auto;
          right: 0;
          width: 10px;
          height: 6px;
          margin-top: -3px;
          margin-left: -7px;
          background: url("../../images/sprite.svg") -160px -715px;
        }
      }
    }
  }
  .gac-select {
    height: 40px;
    border-radius: 4px;
    &:after {
      right: 8px;
      background: url("../../images/sprite.svg") -160px -715px;
    }
    &:not(#industry) {
      .gac-select-menu {
        li {
          .gac-select-link {
            &.gac-active:before {
              top: 11px;
              right: 14px;
            }
          }
        }
      }
    }
    .gac-select-menu {
      top: 39px!important;
      border: 0;
      border-radius: 4px;
      box-shadow: 0 0 24px 0 rgba(0,0,0,0.16)!important;
      li {
        .gac-select-link {
          padding-right: 24px;
          padding-left: 13px;
        }
      }
    }
    .gac-select-value {
      height: 38px;
      line-height: 38px;
      padding-right: 26px;
      padding-left: 12px;
    }
  }
}
.gac-company-new-textarea {
  width: 672px;
  &__label {
    line-height: 26px;
    margin-bottom: 1px;
    color: $dark-text-color;
    font-size: 16px;
  }
  textarea {
    min-width: 100%;
    max-width: 100%;
    height: 74px;
    line-height: 24px;
    padding: 12px 16px 10px;
    border: 1px solid $border-color;
    color: $dark-text-color;
    font-size: 16px;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
    &.gac-invalid {
      border-color: $red-color;
    }
  }
}
