@import "variables";

// Media queries
@media only screen and (max-width: 1229px) {
    .gac-modal-images {
        width: auto;
        left: 0;
        right: 0;
        top: 0;
        margin-left: 0;
        padding: 30px 15px;
        .gac-close-modal {
            top: 8px;
            right: 8px;
        }
    }
}
@media only screen and (max-width: 1179px) {
    /* Free images */
    .gac-free-images,
    .gac-library {
        .gac-categories-carousel-wrap {
            .slick-arrow {
                &.slick-prev {
                    left: -15px;
                }
                &.slick-next {
                    right: -15px;
                }
            }
        }
    }
    /* Order page */
    .gac-new-project {
        .gac-form-row {
            &.gac-row-multiple {
                &.gac-row-order_on {
                    flex-wrap: wrap;
                }
            }
            .gac-form-field.gac-field-1-2.gac-field-word-count {
                width: 100%;
            }
            .gac-form-field {
                .gac-form-radio {
                    &.gac-radio-job-pay-type {
                        .gac-radio-item {
                            width: 50%;
                        }
                    }
                }
            }
        }
        &.gac-not-logged {
            .gac-form-row {
                .gac-form-field.gac-writer-level {
                    .gac-form-field-content {
                        flex-wrap: wrap;
                        .gac-hint {
                            width: 100%;
                            margin: 9px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1169px) {
    /* Header */
    .gac-header {
        .gac-header__top {
            .gac-logo {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .gac-sub-menu-wrap {
        .gac-sub-menu {
            &.main-container {
                padding: 0 15px;
            }
        }
    }
    .gac-accounts {
        .gac-dropdown__value {
            padding-left: 28px;
            padding-right: 52px;
        }
        &:before {
            right: 29px;
        }
    }
    /* Container */
    .main-container {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    /* Free images */
    .gac-free-images,
    .gac-library {
        .gac-categories-carousel-wrap {
            .slick-arrow {
                width: 30px;
                height: 30px;
                margin-top: -15px;
                &.slick-next {
                    background-position: -120px -115px;
                }
                &.slick-prev {
                    background-position: -120px -75px;
                }
            }
        }
    }
    /* Calendar */
    .gac-calendar {
        .gac-calendar-content {
            .gac-calendar-content__grid {
                .gac-calendar__cell:hover {
                    .gac-day-today-label {
                        display: none;
                    }
                }
            }
        }
    }
    /* Settings */
    .gac-accounts-container {
        .gac-accounts-item {
            flex: 0 0 calc(33.3333% - 20px);
            max-width: calc(33.3333% - 20px);
            &:nth-child(4n + 1) {
                margin-left: 30px;
            }
            &:nth-child(3n + 1) {
                margin-left: 0;
            }
        }
    }
    .gac-clients {
        .gac-accounts-container {
            .gac-accounts-item {
                &:nth-child(3n + 1) {
                    margin-left: 30px;
                }
            }
        }
    }
    .gac-users-list {
        .gac-user-item {
            width: calc(50% - 15px);
            max-width: 377px;
            &:nth-child(3n + 1) {
                margin-left: 30px;
            }
            &:nth-child(2n + 1) {
                margin-left: 0;
            }
        }
    }
    .gac-account-wrapper {
        .gac-clients-page {
            .gac-create-client-btn,
            .gac-form-wrap,
            .gac-client {
                width: calc(50% - 15px);
            }
            & > div {
                &:nth-child(3n + 1) {
                    margin-left: 30px;
                }
                &:nth-child(2n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
    .gac-users-page {
        .gac-writers-list {
            .gac-writer-item {
                width: calc(50% - 15px);
                max-width: 377px;
            }
        }
    }
    /* Project details */
    .gac-approve-project-wrap {
        display: flex;
        width: 100%;
        padding: 0 0 8px 0;
        .gac-btn.gac-btn-s {
            margin-right: 8px !important;
        }
    }
    /* Design plans */
    .gac-subscription-single {
        .gac-design-plans {
            justify-content: center;
            .gac-design-plans-item {
                margin-bottom: 80px;
                margin-left: 12px;
                margin-right: 12px;
                background-color: #ffffff;
            }
        }
        .gac-assistants-plans {
            &__item {
                margin-bottom: 130px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    /* Subscriptions page */
    .gac-cta-banners {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 100px;
        &__item {
            margin-left: 15px;
            margin-right: 15px;
            &:nth-of-type(1),
            &:nth-of-type(2) {
                margin-bottom: 102px;
            }
        }
    }
}
@media only screen and (max-width: 1099px) {
    /* Header */
    .gac-main-menu {
        margin-right: 0;
    }
    /* Calendar */
    .gac-calendar-project__footer {
        .gac-calendar-project__author {
            margin-right: 6px;
        }
    }
    .gac-project-wrapper {
        .gac-project-content {
            .gac-project-sharing {
                .gac-sharing-wrap {
                    display: block;
                    .gac-sharing-links {
                        margin-right: 0;
                        margin-bottom: 40px;
                    }
                }
                .gac-clients .gac-accounts-container .gac-accounts-item {
                    //width: 50%;
                }
            }
        }
    }
}
@media only screen and (max-width: 1063px) {
    /* Design plans */
    .gac-design-plans {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        &-item {
            margin: 0 16px 30px;
        }
    }
}
@media only screen and (max-width: 1023px) {
    /* Header */
    .gac-main-menu li {
        margin: 0;
        & > div,
        a {
            font-size: 16px;
        }
    }
    .gac-accounts {
        &:before {
            right: 10px;
        }
        .gac-dropdown__value {
            padding-left: 18px;
            padding-right: 32px;
        }
    }
    .gac-user-nav {
        padding-left: 0;
        padding-right: 0;
        &__account {
            margin-right: 0;
        }
    }
    /* Free images */
    .gac-free-images,
    .gac-library {
        .gac-images-filters {
            flex-wrap: wrap;
            justify-content: space-between;
            .gac-filter-item {
                width: calc(33.3333% - 10.6666px);
                margin: 0 0 16px 16px;
                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
                &:nth-child(4) {
                    margin-bottom: 24px;
                }
                .gac-filter-item__menu {
                    width: calc(100% + 2px);
                }
            }
            .gac-filter-item__safesearch {
                margin-left: 16px;
                margin-right: auto;
            }
        }
        .gac-free-images-wrap {
            margin-bottom: 40px;
        }
        .gac-free-images-pagination-wrap {
            .gac-free-images-pagination {
                order: 1;
                width: 100%;
                justify-content: center;
                margin-bottom: 40px;
                span:nth-child(1) {
                    order: 1;
                    margin-right: auto;
                }
                input {
                    margin-left: 0;
                    order: 2;
                    margin-right: 3px;
                }
                .gac-free-images__total-pages {
                    order: 3;
                    margin-left: 3px;
                    margin-right: 0;
                }
                span:nth-child(4) {
                    order: 4;
                    margin-left: auto;
                }
            }
            .gac-free-images-text {
                order: 2;
            }
        }
    }
    .gac-library {
        .gac-free-images-pagination-wrap {
            .gac-free-images-pagination {
                input {
                    margin-left: 0;
                }
            }
            .gac-btn.gac-btn-s {
                display: inline-block;
                &:first-child {
                    order: 1;
                    margin-right: auto;
                }
                &:last-child {
                    order: 3;
                    margin-left: auto;
                }
            }
        }
    }
    /* Manage */
    .gac-manage {
        .gac-manage-content {
            .gac-manage-content__head {
                span {
                    &.gac-manage-content__head-format {
                        flex: 0 0 150px;
                    }
                    &.gac-manage-content__head-writer {
                        flex: 0 0 130px;
                    }
                    &.gac-manage-content__head-order {
                        flex: 0 0 90px;
                    }
                    &.gac-manage-content__head-due {
                        flex: 0 0 90px;
                    }
                    &.gac-manage-content__head-status {
                        flex: 0 0 160px;
                        &.gac-completed-page {
                            flex: 0 0 160px;
                        }
                    }
                    &.gac-manage-content__head-approval {
                        flex: 0 0 120px;
                    }
                    &.gac-manage-content__head-rating {
                        flex: 0 0 80px;
                    }
                }
            }
            .gac-manage-project {
                span {
                    &.gac-manage-project-format {
                        flex: 0 0 150px;
                    }
                    &.gac-manage-project-writer {
                        flex: 0 0 130px;
                    }
                    &.gac-manage-project-order {
                        flex: 0 0 90px;
                    }
                    &.gac-manage-project-due {
                        flex: 0 0 90px;
                    }
                    &.gac-manage-project-status {
                        flex: 0 0 160px;
                        &.gac-completed-page {
                            flex: 0 0 160px;
                        }
                    }
                    &.gac-manage-project-approval {
                        flex: 0 0 120px;
                    }
                    &.gac-manage-project-rating {
                        flex: 0 0 80px;
                    }
                }
            }
        }
    }
    /* Order */
    .gac-order-page {
        &.gac-empty-cart {
            .gac-order-step-create,
            .gac-new-project,
            .gac-order-projects-wrap {
                max-width: 100%;
            }
        }
        flex-direction: column;
        .gac-order-step-create {
            width: 100%;
            flex: 0 0 auto;
            margin: 0 0 24px 0;
        }
        .gac-new-project {
            width: 100%;
            flex: 0 0 auto;
            margin: 0 0 24px 0;
            .gac-form-row {
                &.gac-row-multiple {
                    &.gac-row-quantity {
                        flex-wrap: wrap;
                    }
                }
            }
        }
        .gac-order-projects-wrap {
            width: 100%;
            max-width: 100%;
            flex: 0 0 auto;
            margin: 0 0 24px 0;
            &.gac-duplicate {
                margin-bottom: 0;
            }
        }
        .gac-order-projects-wrap.gac-duplicate + .gac-cart {
            display: none;
        }
        .gac-confirm {
            width: 100%;
            flex: 0 0 auto;
            margin: 0 0 24px 0;
        }
        .gac-cart {
            width: 100%;
            flex: 0 0 auto;
            .gac-cart-wrapper {
                margin-bottom: 0;
            }
            .gac-cart-projects {
                .gac-cart-project {
                    &:last-child {
                        .gac-cart-project-row {
                            .gac-cart-project-more {
                                ul {
                                    top: auto;
                                    bottom: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .gac-brand-profiles ul li .gac-edit {
        opacity: 1;
    }
    /* Modals */
    .gac-modal {
        &.gac-bundles-modal {
            top: 0 !important;
            right: 0 !important;
            left: 0 !important;
            width: auto !important;
            max-width: inherit !important;
            height: 100vh;
            overflow: hidden;
            padding: 16px 16px 0;
            margin: 0;
            transform: translate(0, 0) !important;
            display: flex;
            flex-direction: column;
            .gac-bundles {
                .gac-bundle-item {
                    width: 134px;
                    padding-left: 10px;
                    padding-right: 10px;
                    h4 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .gac-ai-results-modal {
        left: 0;
        top: 0;
        height: 100%;
        transform: translateX(0);
    }
    /* Settings */
    .gac-account-wrapper {
        .gac-create-account-from {
            .gac-form-own-balance__currency {
                flex: 0 0 auto;
                max-width: inherit;
            }
            .gac-form-row {
                flex: 1 1 100%;
                max-width: 100%;
                .gac-form-field .gac-form-input#cname {
                    margin-right: 122px;
                }
            }
        }
    }
    .gac-roles-list {
        .gac-role-item {
            flex-wrap: wrap;
            margin-bottom: 39px;
            .gac-role-name {
                width: 100%;
                margin-bottom: 15px;
                margin-right: 0;
            }
        }
    }
    .gac-payments {
        .gac-payments-head {
            span {
                padding-right: 15px;
                &:first-child {
                    padding-left: 15px;
                }
                &[data-col="date"] {
                    flex: 0 0 140px;
                }
                &[data-col="credit"] {
                    flex: 0 0 120px;
                }
                &[data-col="debit"] {
                    flex: 0 0 120px;
                }
                &[data-col="balance"] {
                    flex: 0 0 90px;
                }
            }
        }
        .gac-payments-item {
            span {
                padding-right: 15px;
                &[data-col="date"] {
                    padding-left: 15px;
                    flex: 0 0 140px;
                }
                &[data-col="details"] {
                    margin-right: 15px;
                }
                &[data-col="credit"] {
                    flex: 0 0 120px;
                }
                &[data-col="debit"] {
                    flex: 0 0 120px;
                }
                &[data-col="balance"] {
                    flex: 0 0 90px;
                }
            }
        }
    }
    /* Project details */
    .gac-project-wrapper {
        .gac-project-content {
            .gac-project-overview {
                .gac-overview-wrap {
                    .gac-overview-field {
                        flex: 1 1 100%;
                        width: auto;
                        max-width: 100%;
                        &:nth-child(2) {
                            order: 1;
                        }
                        &:nth-child(4) {
                            order: 2;
                        }
                        &:nth-child(6) {
                            order: 3;
                        }
                        &:nth-child(8) {
                            order: 4;
                        }
                        &.gac-field-project-id {
                            order: 5;
                        }
                        &.gac-mb-4,
                        &.gac-mb-6 {
                            margin-bottom: 32px;
                        }
                    }
                }
            }
            .gac-project-brief {
                .gac-project-files {
                    grid-template-columns: 100%;
                }
                .gac-form-row.gac-row-multiple {
                    &.gac-industry {
                        margin-bottom: 0;
                    }
                    flex-wrap: wrap;
                }
                .gac-brief-wrap {
                    .gac-form-row {
                        .gac-form-field {
                            &.gac-field-1-2 {
                                width: 100% !important;
                                margin-left: 0 !important;
                                margin-bottom: 24px;
                            }
                            &.gac-field-1-2 + .gac-field-1-2 {
                                margin-bottom: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .gac-project-content-wrap {
        .gac-project-content-meta {
            display: block;
            .gac-meta-wordcount {
                margin-bottom: 10px;
            }
        }
    }
    .gac-share-project {
        flex-wrap: wrap;
        span {
            width: 100%;
            margin-bottom: 10px;
        }
        input {
            min-width: auto;
            width: calc(100% - 21px);
        }
    }
    .gac-approve-project-wrap {
        flex-wrap: wrap;
    }
    /* Message */
    .gac-message-block {
        .gac-message-wrap {
            max-width: 90%;
            min-width: 90%;
        }
        .gac-message-wrap-2 {
            display: block;
        }
        .gac-message-content {
            width: 100%;
            min-width: 100%;
        }
    }
    /* Note */
    .gac-note {
        padding-left: 82px;
        .gac-note-icon {
            left: 30px;
        }
    }
    /* Empty state */
    .gac-empty-state-v2 {
        .gac-empty-state-img {
            img {
                width: auto;
                max-height: 200px;
            }
        }
    }
    /* Subscriptions page */
    .gac-subscriptions-page {
        .gac-table {
            &-head {
                display: none;
            }
            &-item {
                flex-direction: column;
                border: 1px solid $border-color;
                padding: 10px 0;
                margin-bottom: 16px;
                border-radius: 2px;
                background-color: $white-color;
                span {
                    display: flex;
                    justify-content: flex-end;
                    flex: 0 0 100% !important;
                    height: auto;
                    line-height: 22px;
                    padding: 5px 15px !important;
                    border: 0 !important;
                    &[data-name="interval"] {
                        text-transform: none !important;
                    }
                    &[data-name="account"] {
                        white-space: normal;
                        overflow: auto;
                        .gac-table-item__mobile-label {
                            padding-right: 20px;
                        }
                    }
                }
                &__links {
                    padding: 15px;
                    i {
                        margin-right: 0;
                        margin-bottom: 4px;
                    }
                    div {
                        width: 100%;
                        line-height: 20px;
                        text-align: center;
                    }
                }
                &__mobile-label {
                    margin-right: auto;
                    font-style: normal;
                }
            }
        }
    }
    .gac-subscription-single {
        .gac-subscriptions-list {
            flex-direction: column;
            align-items: center;
            .gac-breadcrumbs-item {
                margin-bottom: 16px;
            }
        }
    }
    .gac-sub-btns {
        flex-wrap: wrap;
        margin-bottom: -15px;
        .gac-btn {
            margin-bottom: 15px;
        }
    }
    .gac-assistants-plans {
        &__item {
            margin-bottom: 130px;
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
}
@media only screen and (max-width: 983px) {
    /* Design plans */
    .gac-design-plans {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 40px;
        .gac-design-plans-item {
            margin-bottom: 80px;
            margin-left: 12px;
            margin-right: 12px;
            background-color: $white-color;
            &.motion {
                margin-bottom: 140px;
            }
            &__content {
                padding-top: 4px !important;
            }
            &:last-child {
                margin-right: 12px;
            }
        }
        .gac-design-plans-item__hint {
            .gac-hint-v2 {
                padding: 0 0 24px;
                left: 0;
            }
            .gac-hint-v2__wrap {
                flex-direction: column;
                .gac-hint-v2__col-1 {
                    padding-right: 24px;
                    padding-left: 24px;
                    border: 0;
                    .gac-hint-v2-content {
                        flex-direction: column;
                        p:first-child {
                            margin-right: 0;
                        }
                    }
                }
                .gac-hint-v2__col-2 {
                    padding-right: 24px;
                    padding-left: 24px;
                    border-top: 1px solid $green-color;
                    h3 {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 933px) {
    .gac-custom-sub-wrap {
        flex-direction: column;
        max-width: 442px;
        .gac-item-custom-sub {
            max-width: 100%;
            &:first-child {
                margin-bottom: 30px;
            }
        }
    }
}
@media only screen and (max-width: 849px) {
    /* Calendar */
    .gac-calendar {
        .gac-calendar-content {
            .gac-calendar-content__grid {
                .gac-calendar__cell {
                    .gac-calendar__cell__header {
                        padding-left: 8px;
                    }
                    .gac-calendar__cell__add {
                        padding-left: 30px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    /* Modals */
    .gac-modal {
        &.gac-writers-modal {
            width: 100%;
            height: 100%;
            left: 0;
            margin: 0 !important;
            top: 0;
            transform: translateX(0);
            h1 {
                line-height: 50px;
                padding: 12px 0 5px;
                font-size: 20px;
            }
        }
    }
}
@media only screen and (max-width: 809px) {
    .gac-cta-banners {
        flex-direction: column;
        &__item {
            flex: 0 0 auto;
            margin: 0 0 90px 0 !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

@media only screen and (max-width: 779px) {
    /* Modals */
    .gac-modal {
        &.gac-writers-modal {
            padding: 0 20px;
            .gac-writers-list {
                .gac-writer-item-all,
                .gac-writer-item {
                    width: 231px;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    /* Login */
    .gac-login-page {
        .gac-login-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0 !important;
            padding: 0;
            background-color: transparent;
            .gac-wrap {
                padding: 0 16px;
                background-color: $white-color;
            }
            .gac-login-footer {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
            }
        }
    }
    .gac-login-page,
    .gac-body-payment-confirm,
    .gac-body-company-new,
    .gac-body-plans-page {
        background: none;
    }
    .gac-login-page {
        background-color: $white-color;
    }
    /* Filters */
    .gac-filters-btn {
        min-width: 48px;
        text-indent: -999999px;
        padding: 0 23px;
        background-clip: border-box;
        i {
            left: 50%;
            top: 13px;
            width: 22px;
            height: 20px;
            margin-left: -11px;
            background-position: -22px -200px;
        }
        &:hover {
            i {
                background-position: -44px -200px;
            }
        }
        &.gac-filters-btn-active {
            i {
                background-position: -66px -200px;
            }
        }
    }
    /* Free images */
    .gac-free-images,
    .gac-library {
        .gac-images-search {
            &.gac-filters-on {
                margin-bottom: 24px;
                transition: none;
            }
            .gac-filters-btn {
                margin-left: 10px;
            }
        }
        .gac-images-filters {
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: flex-start;
            position: absolute;
            left: 0;
            right: 0;
            top: 245px;
            background-color: $bg-color;
            z-index: 10000;
            overflow-x: hidden;
            .gac-filter-item {
                width: calc(100% - 30px);
                margin: 0 15px 16px !important;
                &:nth-child(5) {
                    margin-bottom: 8px !important;
                }
            }
            .gac-filter-item__safesearch {
                margin-bottom: 16px;
                margin-left: 25px;
            }
        }
    }
    .gac-library {
        .gac-images-filters {
            top: 94px;
        }
    }
    .gac-free-images__item {
        & > span {
            opacity: 1;
        }
        .gac-free-images__item-btns {
            bottom: 24px;
            left: 24px;
            right: auto;
            .gac-free-images-btns-wrap {
                .gac-plus-btn,
                .gac-download-btn {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
    .gac-free-images .gac-categories-carousel-wrap .gac-carousel-btns {
        display: none;
    }
    /* ResourceSelect */
    .gac-library__resourse-wrap {
        justify-content: space-between;
    }
    .gac-library__resourse-item {
        height: 52px;
        margin-right: 0;
        flex: 0 0 104px;
        &[data-platform="pixabay"] {
            img {
                width: 82px !important;
            }
        }
        &[data-platform="pexels"] {
            img {
                width: 72px !important;
            }
        }
        &[data-platform="unsplash"] {
            img {
                width: 77px !important;
            }
        }
    }

    /* Calendar */
    .gac-calendar {
        .gac-calendar-nav {
            position: fixed;
            top: 56px;
            left: 0;
            width: 100%;
            min-height: 129px;
            padding: 16px 15px 14px;
            border-bottom: 1px solid $border-color;
            background: $bg-color;
            z-index: 99;
            .gac-calendar-filters {
                flex-direction: column;
                justify-content: flex-start;
                overflow: auto;
                .gac-filter-item {
                    width: 100%;
                    margin: 0 0 16px 0;
                    .gac-filter-item__menu {
                        width: calc(100% + 2px);
                    }
                }
            }
            .gac-calendar-nav__header {
                justify-content: flex-end;
                margin-bottom: 16px;
                .gac-calendar-nav__buttons {
                    width: auto;
                    .gac-calendar-nav__mobile-btn,
                    .gac-filters-btn {
                        margin-right: 10px;
                    }
                }
                .gac-calendar-nav__date {
                    width: auto;
                    margin-left: 1px;
                    margin-right: auto;
                }
            }
            .gac-calendar-mobile-nav {
                .gac-calendar-mobile-nav__header {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 -15px 12px;
                    .gac-calendar-mobile-nav__header__day {
                        width: 14.28%;
                        line-height: 22px;
                        color: $light-text-color;
                        font-size: 12px;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
                .gac-calendar-mobile-nav__grid {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 0 -15px;
                    padding-bottom: 1px;
                    .gac-calendar-mobile-nav__cell {
                        width: 14.28%;
                        padding-bottom: 5px;
                        color: $dark-text-color;
                        font-size: 12px;
                        text-align: center;
                        cursor: pointer;
                        &.gac-mobile-cell-today {
                            .gac-calendar-mobile-nav__day {
                                border-color: $blue-color;
                            }
                        }
                        &.gac-mobile-cell-disabled {
                            .gac-calendar-mobile-nav__day {
                                color: $dark-text-color;
                                opacity: 0.5;
                            }
                            .gac-calendar-mobile-nav__projects {
                                .gac-calendar-mobile-nav__dot {
                                    background: $dark-text-color !important;
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
                .gac-calendar-mobile-nav__slider {
                    margin: 0 -15px;
                    .gac-calendar-mobile-nav__cell {
                        padding-bottom: 5px;
                        color: $dark-text-color;
                        font-size: 12px;
                        text-align: center;
                        cursor: pointer;
                        &.gac-mobile-cell-today {
                            .gac-calendar-mobile-nav__day {
                                border-color: $blue-color;
                            }
                        }
                        &.gac-mobile-cell-disabled {
                            .gac-calendar-mobile-nav__day {
                                color: $dark-text-color;
                                opacity: 0.5;
                            }
                            .gac-calendar-mobile-nav__projects {
                                .gac-calendar-mobile-nav__dot {
                                    background: $dark-text-color !important;
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
                .gac-calendar-mobile-nav__day {
                    width: 24px;
                    height: 24px;
                    line-height: 22px;
                    border: 1px solid transparent;
                    margin: 0 auto;
                    border-radius: 100%;
                    .gac-mobile-cell-disabled & {
                        color: $grey-color;
                    }
                }
                .gac-calendar-mobile-nav__projects {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    padding-top: 3px;
                    .gac-calendar-mobile-nav__dot {
                        width: 4px;
                        height: 4px;
                        margin: 3px 2px 0;
                        background: $green-color;
                        border-radius: 100%;
                    }
                    .gac-calendar-mobile-nav__more {
                        height: 8px;
                        line-height: 8px;
                        margin: 0 1px;
                        color: $light-text-color;
                        font-size: 12px;
                    }
                }
            }
        }
        .gac-calendar-content {
            margin: 0 -15px;
            border: 0;
            .gac-calendar-content__grid {
                min-height: auto;
                .gac-calendar__cell {
                    display: flex;
                    width: 100%;
                    min-height: 110px;
                    max-width: none;
                    margin-bottom: 0 !important;
                    border-bottom: 1px solid $border-color;
                    .gac-calendar__cell__header {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        min-width: 54px;
                        border-right: 1px solid $border-color;
                        .gac-day {
                            flex-direction: column;
                            margin-left: 0;
                            text-align: center;
                            .gac-day-label {
                                height: 48px;
                                font-size: 14px;
                            }
                            .gac-day-today-label {
                                order: 2;
                            }
                        }
                    }
                    .gac-calendar__cell__content {
                        flex: 1 1 auto;
                        padding: 16px 16px 12px 16px;
                        .gac-calendar-project {
                            padding: 15px 15px 12px 15px;
                            .gac-calendar-project__title {
                                line-height: 22px;
                                font-size: 15px;
                            }
                        }
                    }
                    &.today {
                        box-shadow: 0 0 10px 0 rgba(159, 177, 188, 0.4);
                        background-color: $bg-color;
                        .gac-day {
                            padding-bottom: 10px;
                            .gac-day-label {
                                height: 48px;
                                line-height: 24px;
                                color: $blue-color;
                            }
                            .gac-day-today-label {
                                position: relative;
                                line-height: 24px;
                                font-size: 14px;
                                &:before {
                                    position: absolute;
                                    left: 50%;
                                    bottom: -8px;
                                    width: 24px;
                                    height: 3px;
                                    margin-left: -12px;
                                    border-radius: 2px;
                                    background-color: $blue-color;
                                    content: "";
                                }
                            }
                        }
                    }
                    &:hover {
                        .gac-day-today-label {
                            display: block;
                        }
                    }
                }
            }
        }
        .gac-calendar-nothing {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            min-height: 200px;
            color: $light-text-color;
            font-size: 18px;
        }
    }
    .gac-calendar-project__footer {
        font-size: 14px;
        .gac-project-type {
            margin-top: 2px;
        }
        .gac-calendar-project__author {
            height: 18px;
            line-height: 16px;
            padding-top: 2px;
        }
        .gac-calendar-project__status {
            height: 18px;
            padding-top: 1px;
        }
    }
    /* Manage */
    .gac-manage {
        .gac-manage-search {
            .gac-filters-btn {
                margin-left: 10px;
            }
        }
        .gac-manage-content {
            border: 0;
            background: none;
            .gac-manage-project {
                flex-wrap: wrap;
                margin-bottom: 16px;
                padding-bottom: 6px;
                border: 1px solid $border-color;
                background: $white-color;
                cursor: default;
                &:hover,
                &:active,
                &:focus {
                    background: $white-color;
                    span.gac-manage-project-status {
                        .gac-manage-project-status-wrapper {
                            background-color: $border-color;
                        }
                    }
                }
                &:first-child {
                    border-top: 1px solid $border-color;
                    border-radius: 2px 2px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 2px 2px;
                }
                span,
                a.gac-manage-project-topic {
                    &.gac-manage-project-topic {
                        position: relative;
                        width: calc(100% - 55px);
                        //min-height: 68px;
                        padding: 10px 56px 10px 90px;
                        font-size: 15px;
                        text-decoration: none;
                        &:hover,
                        &:active,
                        &:focus {
                            color: inherit;
                        }
                        .gac-topic {
                            margin-right: 4px;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                        }
                        .gac-revision-label {
                            margin-left: 0;
                        }
                        .gac-messages {
                            position: absolute;
                            top: 28px;
                            right: 16px;
                        }
                        .gac-project-type {
                            left: 66px;
                        }
                    }
                }
                .gac-manage-project-details {
                    position: relative;
                    display: block;
                    flex-wrap: wrap;
                    width: 100%;
                    overflow: hidden;
                    &.gac-project-details-default-enter-done,
                    &.gac-project-details-current-enter-done {
                        top: -5px;
                        overflow: visible;
                    }
                    span {
                        height: 32px !important;
                        display: flex;
                        justify-content: flex-end;
                        padding: 0 15px;
                        .gac-manage-project-label {
                            margin-right: auto;
                            color: $light-text-color;
                        }
                        &.gac-manage-project-writer {
                            .gac-img {
                                width: 24px;
                                height: 24px;
                                margin-top: 4px;
                            }
                            .gac-writer-avatar {
                                background-color: $border-color;
                            }
                            .gac-writer-name {
                                flex: 0 0 auto;
                            }
                        }
                    }
                    .gac-my-team {
                        position: relative;
                        top: -2px;
                        .gac-my-team-item .gac-my-team-item-name {
                            top: -36px;
                            padding: 2px 14px 4px;
                        }
                        .gac-my-team-rest .gac-my-team-rest-list {
                            display: none !important;
                        }
                    }
                }
                .gac-project-actions-wrap {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    right: 0;
                    top: 0;
                    width: 60px;
                    height: 50px;
                }
                span.gac-manage-project-rating {
                    .gac-manage-project-feedback {
                        top: 6px;
                    }
                }
            }
        }
        .gac-manage-filters {
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: flex-start;
            position: absolute;
            left: 0;
            right: 0;
            top: 136px;
            background-color: $bg-color;
            z-index: 10000;
            overflow-x: hidden;
            .gac-filter-item {
                width: calc(100% - 30px);
                margin: 0 15px 16px !important;
                .gac-filter-item__menu {
                    width: calc(100% + 2px);
                }
                &:nth-child(5) {
                    margin-bottom: 8px !important;
                }
            }
        }
    }
    .gac-project-actions {
        ul {
            top: 8px;
            right: 8px;
            margin-top: 0;
        }
        .gac-actions-icon {
            width: 25px;
            padding-right: 7px;
        }
    }
    .gac-sorting-icon {
        top: 19px;
        margin-top: 0;
        width: 40px;
        height: 18px;
        i {
            height: 4px;
            background-color: $border-color;
            border-radius: 2px;
            &:nth-child(2) {
                top: 7px;
            }
            &:nth-child(3) {
                top: 14px;
            }
        }
    }
    .gac-disable-sorting-mode {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin-left: 10px;
        background-color: $green-color;
        border-radius: 2px;
    }
    /* Modals */
    .gac-modal {
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        width: auto !important;
        max-width: inherit !important;
        overflow: hidden;
        padding: 16px 16px 0;
        transform: translate(0, 0) !important;
        display: flex;
        flex-direction: column;
        .gac-modal-mobile-wrap {
            flex: 1 1 auto;
            overflow: auto;
        }
        &.gac-create-project-modal {
            margin-left: 0 !important;
            margin-top: 0 !important;
            border: 0;
            border-radius: 0;
            .gac-filter-item__menu {
                width: 328px;
            }
        }

        .gac-create-project-modal-fields {
            .gac-create-project-modal-topic {
                width: 100%;
                margin-right: 0;
                .gac-input {
                    margin-bottom: 10px;
                }
            }
            .gac-create-project-modal-type {
                width: 100%;
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
        .gac-modal-additional-fields {
            .gac-modal-field {
                width: auto;
                flex: 1 1 auto;
                margin-bottom: 10px;
                .gac-filter-item {
                    .gac-filter-item__menu {
                        width: calc(100% + 2px);
                    }
                }
                &.gac-word-count {
                    flex: 0 0 auto;
                    margin-right: 0;
                }
                &.gac-order-on {
                    width: 100%;
                }
            }
            .gac-create-project-modal-type {
                width: 100%;
            }
        }
        .gac-modal-btns {
            flex: 0 0 auto;
            padding: 16px 0 20px !important;
            background-color: #ffffff;
            .gac-btn {
                min-width: 0;
                width: calc(50% - 5px);
                margin-right: 5px;
            }
            .gac-btn-v2 {
                width: calc(50% - 5px);
                margin-left: 5px;
            }
        }
        &.gac-edit-project-modal {
            padding: 16px 16px 0;
            .gac-modal-btns {
                .gac-btn {
                    min-width: 180px;
                    width: auto;
                    margin-right: 16px;
                }
            }
        }
        &.gac-writers-modal {
            background-color: $bg-color;
            padding: 0 15px;
            .gac-close-modal {
                background-color: transparent;
            }
            .gac-writers-text-wrap {
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
            .gac-writers-invalid {
                left: 50%;
                transform: translateX(-50%);
            }
            .gac-writers-list {
                .gac-writer-item-all,
                .gac-writer-item {
                    width: 100%;
                    margin-bottom: 8px;
                    background-color: $white-color;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .gac-writers-btn {
                padding-top: 20px;
                .gac-btn {
                    width: 100%;
                }
            }
        }
        &.gac-bundles-modal {
            display: block;
            margin: 0;
            padding: 0 15px 29px;
            background-color: $bg-color;
            overflow: auto;
            .gac-close-modal {
                background-color: transparent;
            }
            h1 {
                line-height: 50px;
                padding: 12px 0 5px;
                font-size: 20px;
                text-align: left;
            }
            .gac-bundles {
                flex-direction: column;
                .gac-bundle-item {
                    height: auto;
                    width: 100%;
                    padding: 0 10px 23px;
                    margin: 0 0 24px 0;
                    background-color: $white-color;
                    &:last-child {
                        margin-bottom: 8px;
                    }
                    &.gac-item-popular {
                        margin-top: 44px;
                        border: 2px solid $yellow-color;
                    }
                    h3 {
                        line-height: 58px;
                        font-size: 23px;
                        margin-left: -10px;
                        margin-right: -10px;
                        margin-bottom: 27px;
                        border-bottom: 1px solid $border-color;
                    }
                    h4 {
                        margin-bottom: 9px;
                        color: $light-text-color;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    span {
                        margin-bottom: 21px;
                        color: #74a3d2;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .gac-popular-label {
                        width: calc(100% + 4px);
                        left: -2px;
                        top: -46px;
                        line-height: 46px;
                        height: 46px;
                        font-size: 16px;
                    }
                    .gac-btn {
                        width: auto;
                        min-width: 140px;
                    }
                }
            }
        }
        &.gac-purchase-bundle-modal {
            display: block;
            min-height: 100vh;
            bottom: 0;
            margin: 0 !important;
            overflow: auto;
        }
        &.gac-create-account-modal {
            display: block;
            overflow: auto;
            background-color: $bg-color;
            padding: 0 15px 20px;
            margin: 0 !important;
            .gac-close-modal {
                background-color: transparent;
            }
            .gac-btn {
                margin-right: 0;
            }
            .gac-agreement {
                align-items: center;
                line-height: 24px;
            }
        }
        &.gac-deactivate-account-modal {
            margin: 0 !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .gac-btn {
                margin-right: 0;
            }
        }
        &.gac-upload-image-modal {
            margin: 0 !important;
            padding: 10px 16px 16px;
        }
        &.gac-message-support-modal {
            .gac-modal-btns {
                .gac-btn {
                    width: 100%;
                    margin: 0;
                }
            }
        }
        &.gac-referral-modal {
            display: block;
            min-height: 100vh;
            bottom: 0;
            margin: 0 !important;
            padding: 12px 16px 16px;
            overflow: auto;
            h1 {
                line-height: 50px;
                margin-bottom: 20px;
                font-size: 20px;
            }
            .gac-referral-desc div {
                margin-bottom: 20px;
                padding-left: 54px;
                font-size: 16px;
            }
            .gac-referral-data {
                margin-bottom: 16px;
            }
            .gac-referral-dashboard-data {
                padding: 22px 26px;
            }
            .gac-referral-link {
                .gac-referral-link-input {
                    .gac-copied {
                        top: 56px;
                        padding: 0;
                        line-height: 16px;
                        height: 16px;
                        font-size: 12px;
                    }
                }
            }
        }
        &.gac-rate-writer-modal {
            min-height: 100vh;
            bottom: 0;
            margin: 0 !important;
            padding: 12px 16px 16px;
            overflow: auto;
            h1 {
                line-height: 50px;
                margin-bottom: 20px;
                font-size: 20px;
            }
        }
        &.gac-request-revision-modal {
            justify-content: center;
            margin: 0 !important;
            padding: 15px;
        }
    }
    .gac-modal-overlay {
        padding: 0;
    }
    .gac-modal-create-brand {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 100vh;
        padding: 16px;
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        .gac-modal-mobile-wrap {
            flex: 1 1 auto;
            overflow: auto;
            .gac-modal-row:last-child {
                margin-bottom: 0;
            }
        }
        .gac-btn-wrap {
            padding-top: 16px;
        }
        .gac-hint .gac-hint-wrap .gac-hint-text {
            width: 341px;
            left: -80px;
            &:before {
                left: 79px;
            }
        }
    }
    .gac-modal-subscription {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 100% !important;
        padding: 16px;
        margin: 0 !important;
        .gac-confirm-mobile-btn {
            position: static;
            background-color: white;
        }
        .gac-pause-desc .gac-start-date-wrap {
            //display: inline-block;
            .gac-daypicker-wrap {
                .DayPicker-wrapper {
                    padding: 14px 9px 6px;
                }
                .DayPicker-Month {
                    .DayPicker-Weekdays {
                        .DayPicker-Weekday {
                            margin: 0 1px;
                        }
                    }
                    .DayPicker-Week {
                        .DayPicker-Day {
                            margin: 0 1px;
                            height: 34px;
                            line-height: 34px;
                        }
                    }
                }
                .DayPicker-NavButton {
                    top: 10px;
                    &.DayPicker-NavButton--prev {
                        right: 54px;
                    }
                    &.DayPicker-NavButton--next {
                        right: 24px;
                    }
                }
            }
        }
    }
    /* Empty state */
    .gac-empty-state {
        .gac-empty-state-img {
            margin-bottom: 0;
            transform: scale(0.7);
        }
        .gac-empty-state-text {
            padding: 0 20px;

            font-size: 16px;
            p {
                line-height: 24px;
                margin-bottom: 10px;
            }
            ul li {
                line-height: 26px;
                padding-left: 24px;
                font-size: 13px;
            }
        }
    }
    .gac-empty-state-v2 {
        border-top: 0;
        .gac-empty-state-text {
            max-width: 100%;
            width: 100%;
            padding: 0 15px;
            text-align: center;
        }
    }
    /* 404 page */
    .gac-404-page {
        .gac-404-page-content {
            .gac-404-page-img-1 {
                transform: scale(0.8);
                margin-bottom: 20px;
            }
            .gac-404-page-text {
                font-size: 22px;
                margin-bottom: 30px;
            }
            .gac-404-page-img-2 {
                transform: scale(0.8);
            }
        }
    }
    /* Order */
    .gac-order-page {
        .gac-order-projects-wrap {
            margin: -6px 0 16px 0;
            border: 0;
            background-color: transparent;
            .gac-order-projects-list {
                border: 0;
                background-color: transparent;
                .gac-projects-list-head {
                    padding: 0;
                    .gac-projects-list-title {
                        line-height: 56px;
                    }
                }
                .gac-search {
                    padding: 0;
                    margin-bottom: 16px;
                    .gac-filters-btn {
                        margin-left: 10px;
                    }
                }
                .gac-projects-list {
                    .gac-project-item {
                        flex-wrap: wrap;
                        min-height: inherit;
                        padding: 0;
                        border: 1px solid $border-color;
                        border-top: 0;
                        background-color: $white-color;
                        transition: margin 0.3s ease;
                        &:last-child {
                            &.gac-is-open {
                                margin-bottom: 0;
                            }
                        }
                        &.gac-is-open {
                            margin-top: 8px;
                            margin-bottom: 8px;
                            border-top: 1px solid $border-color;
                            height: auto !important;
                            border-radius: 2px;
                        }
                        &.gac-is-open + .gac-project-item {
                            border-top: 1px solid $border-color;
                            border-radius: 2px 2px 0 0;
                        }
                        &:first-child {
                            border-top: 1px solid $border-color;
                            border-radius: 2px 2px 0 0;
                        }
                        &:last-child {
                            border-radius: 0 0 2px 2px;
                            &.gac-is-open {
                                border-radius: 2px;
                            }
                        }
                        &.gac-project-item-draft {
                            .gac-project-item-topic {
                                width: calc(100% - 107px);
                            }
                        }
                        .gac-project-type {
                            left: 15px;
                            top: 26px;
                        }
                        .gac-project-item-topic {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            height: 68px;
                            width: calc(100% - 55px);
                            padding: 12px 15px 12px 40px;
                            margin-right: 0;
                            .gac-project-item-title,
                            .gac-project-item-title a {
                                line-height: 22px;
                                margin: 0;
                                padding: 0;
                                font-size: 15px;
                                font-weight: normal;
                            }
                        }
                        .gac-show-project-details-btn {
                            margin-right: 12px;
                        }
                        .gac-project-item-btn {
                            flex: 0 0 40px;
                            margin-right: 15px;
                            .gac-btn-rounded {
                                width: 40px;
                                height: 40px;
                            }
                            .gac-btn-add-info {
                                display: block;
                                span {
                                    display: none;
                                }
                            }
                            .gac-btn-timelapse {
                                width: 40px;
                                height: 40px;
                            }
                        }
                        .gac-project-details {
                            position: relative;
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            padding-top: 0;
                            padding-bottom: 0;
                            overflow: hidden;
                            & > div {
                                height: 38px !important;
                                line-height: 32px;
                                display: flex;
                                justify-content: flex-end;
                                flex: 0 0 100%;
                                padding: 3px 15px;
                                &:last-child {
                                    margin-bottom: 10px;
                                }
                                .gac-project-detail-label {
                                    margin-right: auto;
                                    color: $light-text-color;
                                }
                                .gac-project-item-team {
                                    flex: 0 0 auto;
                                }
                            }
                        }
                    }
                }
                .gac-show-more {
                    border: 1px solid $border-color;
                    border-top: 0;
                    background-color: $white-color;
                    border-radius: 0 0 2px 2px;
                }
            }
            .gac-empty-state {
                background-color: $white-color;
                border: 1px solid $border-color;
                border-radius: 2px;
            }
        }
        .gac-order-step-create {
            margin: 0 0 16px 0;
        }
        .gac-new-project {
            padding: 7px 0 0 0;
            border: 0;
            background-color: transparent;
            margin: 0;
            &.gac-not-logged {
                .gac-form-btns {
                    span {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
            #currency {
                width: 170px;
            }
            .gac-form-row {
                margin-bottom: 21px;
                &.gac-row-multiple {
                    flex-wrap: wrap;
                    &.gac-row-details {
                        flex-direction: column;
                        .gac-more-details {
                            margin-left: 0;
                            margin-top: 21px;
                            span {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }
                    &.gac-row-quantity {
                        flex-wrap: wrap;
                        .gac-field-quantity {
                            margin-right: 0;
                        }
                        .gac-field-word-count {
                            width: 100%;
                            flex: 0 0 auto;
                            margin: 21px 0 0 0;
                        }
                    }
                    .gac-writer-level {
                        width: 100%;
                    }
                }
                &.gac-form-level {
                    .gac-writer-level {
                        margin-bottom: 21px;
                    }
                    .gac-form-field-content {
                        flex-direction: column;
                        align-items: flex-start;
                        .gac-hint {
                            margin: 9px 0 0 0;
                            .gac-hint-wrap {
                                .gac-hint-text {
                                    width: 345px;
                                }
                            }
                        }
                    }
                }
                &.gac-used-services-row {
                    .gac-form-field .gac-form-radio .gac-radio-item {
                        width: 170px;
                    }
                    .gac-hint .gac-hint-wrap .gac-hint-text {
                        &:before {
                            left: 238px;
                        }
                        width: 345px;
                        left: -58px;
                    }
                }
                .gac-form-field {
                    &.gac-field-written_by {
                        margin-right: 0;
                    }
                    &.gac-field-word-count {
                        .gac-form-label {
                            margin-bottom: -1px;
                        }
                        .gac-word-count-wrap {
                            .gac-word-count-item {
                                margin-right: 0;
                                padding: 10px 8px 11px;
                            }
                        }
                    }
                    &.gac-field-planned_publish {
                        width: 100%;
                    }
                    &.gac-field-1-2 {
                        width: 100%;
                        margin-left: 0;
                    }
                    &.gac-field-1-2 + .gac-field-1-2 {
                        margin-top: 21px;
                    }
                    &.gac-field-word-count .gac-word-count-wrap .gac-word-count-item:before {
                        background-color: $bg-color;
                    }
                    &.gac-field-quantity {
                        .gac-form-label {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .gac-form-btns {
                flex-wrap: wrap;
                padding-top: 0;
                span {
                    width: calc(50% - 10px);
                }
                .gac-btn-v2:before {
                    background-color: $bg-color;
                }
            }
            .gac-cutoff-time {
                width: 100% !important;
                margin-top: 16px;
                .gac-hint .gac-hint-wrap .gac-hint-text {
                    width: 345px;
                    left: -138px;
                    transform: none;
                    &:before {
                        margin-left: 0;
                        left: 137px;
                    }
                }
            }
        }
        .gac-confirm {
            border: 1px solid $border-color;
            padding: 0 15px 16px;
            margin: 0 0 62px 0;
            .gac-cart-money-back {
                width: 100%;
                max-width: 298px;
                margin: 0 auto;
            }
            .gac-cart-payment-methods {
                margin-bottom: 24px;
            }
            .gac-confirm-wrap {
                width: 100%;
                //max-width: 298px;
            }
        }
        .gac-cart {
            .gac-cart-wrapper.uni-cart-empty {
                padding-bottom: 0;
            }
        }
    }
    .gac-order-filters {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        left: 0;
        right: 0;
        top: 120px;
        padding: 0;
        background-color: $bg-color;
        z-index: 10000;
        overflow-x: hidden;
        .gac-filter-item {
            flex: 0 0 auto;
            max-width: 100%;
            width: 100%;
            margin: 0 0 16px !important;
            .gac-filter-item__menu {
                width: calc(100% + 2px);
            }
            &:nth-child(5) {
                margin-bottom: 8px !important;
            }
        }
        .gac-filters-btns {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .gac-invite-writers {
        .gac-writers-list .gac-writer-item:before {
            border-color: $bg-color;
        }
        .gac-writers-plus:before {
            border-color: $bg-color;
        }
    }
    .gac-new-project {
        &.gac-epiic {
            .gac-form-row {
                &.gac-row-job-type {
                    .gac-form-field {
                        .gac-form-field-content {
                            flex: 0 0 100% !important;
                        }
                    }
                }
            }
        }
        .gac-form-row {
            &.gac-row-job-type {
                .gac-form-field {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    padding-top: 24px;
                    .gac-form-label {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .gac-form-field-content {
                        flex: 0 0 calc(50% - 4px);
                        align-items: flex-start;
                        &:first-of-type {
                            margin-bottom: 0 !important;
                        }
                    }
                    .gac-form-radio {
                        width: 100%;
                        .gac-radio-item {
                            width: auto;
                            flex: 0 0 100%;
                            border: 1px solid $border-color !important;
                            margin-bottom: 8px;
                            border-radius: 2px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .gac-radio-item[data-value="proofreading"] .gac-hint .gac-hint-wrap .gac-hint-text {
                            left: -140px;
                            &:before {
                                left: 139px;
                            }
                        }
                    }
                }
            }
            &.gac-form-level {
                .gac-form-field {
                    .gac-form-radio {
                        width: 100%;
                        .gac-radio-item {
                            width: auto;
                            flex: 0 0 33.3333%;
                        }
                    }
                }
            }
            &.gac-row-turnaround {
                .gac-form-field {
                    .gac-form-radio {
                        width: 100%;
                        flex-direction: column;
                        .gac-radio-item {
                            width: 100% !important;
                            border: 1px solid $border-color;
                            margin-bottom: 8px;
                            border-radius: 2px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .gac-hint {
                        .gac-hint-wrap {
                            .gac-hint-text {
                                width: 345px;
                            }
                        }
                    }
                }
            }
            &.gac-row-topic-ideas-type {
                .gac-form-field {
                    .gac-form-radio {
                        width: 100%;
                        flex-direction: column;
                        .gac-radio-item {
                            width: 100%;
                            margin-bottom: 8px;
                            border-radius: 2px;
                            border: 1px solid $border-color;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .gac-form-field {
                .gac-form-radio {
                    .gac-radio-item {
                        .gac-hint .gac-hint-wrap .gac-hint-text {
                            width: 345px;
                        }
                    }
                }
                .gac-attachment.gac-attachment-motion {
                    background-color: transparent;
                }
            }
        }
    }

    .gac-attachment {
        .gac-attachment-files {
            grid-template-columns: 100%;
        }
    }
    .gac-confirm-mobile-btn {
        .paypal-button-container {
            max-height: 48px;
        }
    }

    /* Settings */
    .gac-account-details .gac-accounts-list {
        padding-top: 13px;
        margin-bottom: 27px;
    }
    .gac-accounts-container {
        .gac-accounts-item {
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0;
            margin-bottom: 8px;
            &:nth-child(4n + 1) {
                margin-left: 0;
            }
        }
    }
    .gac-clients {
        .gac-accounts-container {
            .gac-accounts-item {
                max-width: 100% !important;
                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .gac-account-wrapper {
        display: block;
        .gac-account-sidebar {
            padding-top: 0;
            .gac-account-pages {
                display: flex;
                margin-bottom: 17px;
                overflow: hidden;
                li {
                    position: relative;
                    padding: 0;
                    margin: 0;
                    border: 1px solid $border-color;
                    border-left: 0;
                    white-space: nowrap;
                    &:first-child {
                        border-left: 1px solid $border-color;
                        border-radius: 2px 0 0 2px;
                    }
                    &:last-child {
                        border-radius: 0 2px 2px 0;
                    }
                    &.gac-accounts-link {
                        line-height: 46px;
                        padding-left: 52px;
                        padding-right: 35px;
                    }
                    &.gac-disabled {
                        line-height: 46px;
                        padding-left: 52px;
                        padding-right: 35px;
                    }
                    &.gac-active {
                        line-height: 46px;
                        padding-left: 52px;
                        padding-right: 35px;
                        background-color: $white-color;
                        &:before {
                            position: absolute;
                            left: -1px;
                            right: -1px;
                            bottom: -1px;
                            height: 2px;
                            background: $green-color;
                            content: "";
                        }
                    }
                    a {
                        display: block;
                        line-height: 46px;
                        padding-left: 52px;
                        padding-right: 35px;
                    }
                    i {
                        left: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .gac-create-account-from {
            padding: 0;
            border: 0;
            background-color: transparent;
            .gac-form-btns {
                &.gac-two {
                    .gac-btn {
                        width: 50%;
                        margin-right: 20px;
                    }
                    .gac-btn-v2 {
                        width: 50%;
                    }
                }
                .gac-btn {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
    .gac-general-settings {
        .gac-account-wrapper {
            .gac-account-sidebar {
                .gac-account-pages {
                    li {
                        width: 50%;
                    }
                }
            }
        }
    }
    .gac-users-list {
        margin-bottom: 24px;
        .gac-user-item {
            width: 100%;
            max-width: 100%;
            margin-left: 0 !important;
            margin-bottom: 8px;
        }
    }
    .gac-users-page {
        padding-top: 6px;
        .gac-writers-list {
            margin-bottom: 0;
            .gac-writer-item {
                width: 100%;
                max-width: 100%;
                margin-bottom: 8px;
            }
        }
    }
    .gac-payments-page {
        .gac-accounts-list {
            margin-bottom: 28px;
        }
    }
    .gac-payments-info {
        flex-wrap: wrap;
        margin-bottom: 8px;
        &.gac-payments-info-bundles {
            min-height: 40px;
            .gac-payments-info-item {
                padding-right: 50px;
                .gac-add-bundle {
                    top: 2px;
                    right: 0;
                }
            }
        }
        .gac-payments-info-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: 0;
            & > div:first-child {
                min-width: 180px;
            }
            .gac-radio-group {
                flex-wrap: wrap;
                margin-bottom: 0;
                .gac-item {
                    width: 100%;
                    margin-bottom: 8px;
                    border-left: 1px solid $border-color;
                }
            }
        }
    }
    .gac-payments {
        .gac-payments-head {
            display: none;
        }
        .gac-payments-item {
            border-bottom: 1px solid $border-color;
        }
        .gac-payments-mobile-item {
            padding: 10px 16px 15px;
            border-bottom: 1px solid $border-color;
            .gac-item-desc {
                display: flex;
                line-height: 24px;
                .gac-item-details {
                    .gac-item-topic {
                        position: relative;
                        padding-left: 20px;
                        .gac-project-type {
                            position: absolute;
                            left: 0;
                            top: 4px;
                        }
                    }
                    a {
                        font-size: 15px;
                    }
                }
                .gac-item-data {
                    margin-left: auto;
                    &.gac-debit {
                        color: $light-text-color;
                    }
                    &.gac-credit {
                        color: $green-color;
                    }
                }
            }
            .gac-item-date {
                padding-top: 3px;
                font-size: 12px;
                color: $light-text-color;
            }
        }
    }
    .gac-user-logo .gac-logo-wrap .gac-user-logo-btns {
        visibility: visible;
        opacity: 1;
    }
    /* Logos hint */
    .gac-field-account-logo,
    .gac-field-company-logo {
        .gac-hint .gac-hint-wrap .gac-hint-text {
            width: 290px;
            left: 108px;
            &:before {
                left: 36px;
                margin-left: 0;
            }
        }
    }
    .gac-field-favicon {
        .gac-hint .gac-hint-wrap .gac-hint-text {
            width: 290px;
        }
    }
    .gac-field-user-logo {
        .gac-hint .gac-hint-wrap .gac-hint-text {
            width: 290px;
            left: 101px;
            &:before {
                left: 43px;
                margin-left: 0;
            }
        }
    }

    .gac-assign-hint {
        top: auto;
        bottom: -43px;
        left: 50%;
        margin-left: auto;
        transform: translateX(-50%);
        &:before {
            top: -6px;
            left: 50%;
            margin-left: -10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 9px solid $dark-text-color;
        }
        &.gac-hint-warning {
            &:before {
                border-bottom-color: $yellow-color-dark-2;
                border-right-color: transparent;
            }
        }
    }

    /* Project details */
    .gac-project-wrapper {
        display: block;
        .gac-project-title {
            line-height: 26px;
            padding: 8px 0 0;
            font-size: 20px;
            font-weight: bold;
        }
        .gac-project-sidebar {
            padding-top: 9px;
            .gac-project-pages {
                position: static !important;
                margin-bottom: 17px;
                li {
                    position: relative;
                    padding: 0;
                    margin: 0;
                    border: 1px solid $border-color;
                    border-right: 0;
                    white-space: nowrap;
                    .gac-count {
                        right: 2px;
                    }
                    &:first-child {
                        border-radius: 2px 0 0 2px;
                    }
                    &:last-child {
                        border-right: 1px solid $border-color;
                        border-radius: 0 2px 2px 0;
                    }
                    &.gac-active {
                        background-color: $white-color;
                        &:before {
                            position: absolute;
                            left: -1px;
                            right: -1px;
                            bottom: -1px;
                            height: 2px;
                            background: $green-color;
                            content: "";
                        }
                    }
                    &.gac-disabled {
                        line-height: 46px;
                        padding-left: 52px;
                        padding-right: 35px;
                        span {
                            margin-left: -52px;
                            margin-right: -35px;
                        }
                    }
                    a,
                    span {
                        display: block;
                        line-height: 46px;
                        padding-left: 52px;
                        padding-right: 35px;
                        //visibility: hidden;
                    }
                    i {
                        left: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .gac-project-content {
            &.gac-brief-content.gac-editing {
                border: 0;
                .gac-project-brief {
                    padding: 0;
                    .gac-brief-wrap {
                        padding-bottom: 24px;
                        background-color: $bg-color;
                    }
                }
            }
            &.gac-brief-content.gac-keywords {
                .gac-project-brief {
                    .gac-brief-wrap {
                        .gac-brief-field {
                            flex-direction: column;
                            align-items: flex-start;
                            padding-bottom: 10px;
                            .gac-brief-data {
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .gac-project-overview {
                padding: 0;
                .gac-overview-wrap {
                    .gac-overview-field {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 !important;
                        padding: 0 16px;
                        .gac-overview-label {
                            line-height: 38px;
                            margin: 0 20px 0 0;
                            font-size: 15px;
                        }
                        .gac-overview-data {
                            text-align: right;
                            .gac-daypicker-container {
                                input {
                                    height: 38px;
                                    padding: 0;
                                    border: 0;
                                }
                                .gac-daypicker-wrap .DayPickerInput-Overlay {
                                    left: auto;
                                    right: 0;
                                    bottom: 38px;
                                }
                            }
                            .gac-tags-select {
                                .gac-select__control {
                                    border: 0;
                                }
                                .gac-select__value-container {
                                    padding-top: 4px;
                                    padding-bottom: 4px;
                                }
                                .gac-select__indicators {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
            .gac-project-brief {
                padding: 0;
                .gac-brief-wrap {
                    .gac-form-row:last-child {
                        margin-bottom: 0;
                    }
                    .gac-brief-field {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0;
                        padding: 0 16px;
                        .gac-brief-label {
                            line-height: 38px;
                            margin: 0 20px 0 0;
                            font-size: 15px;
                            flex: 0 0 auto;
                        }
                        .gac-brief-data {
                            display: flex;
                            justify-content: flex-end;
                            text-align: right;
                            &.gac-with-files {
                                flex-direction: column;
                                .gac-brief-content {
                                    padding-top: 8px;
                                    padding-bottom: 8px;
                                }
                            }
                            .gac-project-files {
                                text-align: left;
                            }
                            .gac-brief-content {
                                justify-content: flex-start;
                                text-align: left;
                            }
                            .gac-invite-writers {
                                padding-top: 0;
                            }
                        }
                        &:last-child {
                            border-bottom: 0;
                        }
                        &.gac-field-writers {
                            .gac-brief-label {
                                line-height: 38px;
                            }
                        }
                    }
                    .gac-project-files {
                        margin-bottom: 8px;
                    }
                }
            }
            .gac-project-btns {
                padding: 16px 16px 8px 16px;
                border: 0;
                background-color: transparent;
                &.gac-brief-btns {
                    position: static;
                    border-top: 0 !important;
                    padding: 0 !important;
                    background-color: $bg-color !important;
                }
                &.gac-brief-page-btns,
                &.gac-content-page-btns {
                    border-top: 1px solid $border-color;
                    background-color: $white-color;
                }
                .gac-btn-v3,
                .gac-btn {
                    margin: 0 8px 8px 0;
                }
            }
            .gac-message-client {
                .gac-pitches .gac-pitch-item {
                    border: 0;
                }
                .gac-project-btns {
                    background-color: $white-color;
                    border-top: 1px solid $border-color;
                }
            }
            .gac-pitches {
                .gac-pitch-item {
                    .gac-pitch-content {
                        padding: 16px 16px 0;
                    }
                }
            }
            .gac-share-project {
                padding: 0 16px 16px;
            }
            .gac-project-sharing {
                padding: 16px 16px 0;
                .gac-clients .gac-accounts-container .gac-accounts-item {
                    flex: 0 0 100% !important;
                }
                .gac-activity .gac-activity-list .gac-activity-empty {
                    margin-bottom: 24px;
                }
            }
        }
    }
    .main-container {
        &.gac-mobile-view {
            .gac-project-data-wrap .gac-empty-comments-hint {
                top: -136px;
                right: 50%;
                margin-right: -120px;
                &:before {
                    top: auto;
                    bottom: -15px;
                    left: 50%;
                    margin-top: 0;
                    margin-left: -9px;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-top: 6px solid $dark-text-color;
                }
            }
        }
        &.gac-project-details.gac-empty-content {
            height: auto;
        }
        .gac-project-wrapper.gac-content-page {
            .gac-project-sidebar {
                .gac-project-pages li {
                    text-indent: inherit;
                }
            }
            .gac-project-content {
                margin-right: 0;
            }
        }
    }

    .gac-project-ideas-wrap {
        padding: 0 16px;
    }
    .gac-project-content-wrap {
        padding: 16px 16px 0;
        .gac-show-changes-btn {
            margin-bottom: 8px;
        }
        .gac-project-content-meta {
            padding-bottom: 16px;
        }
    }
    .gac-preview-images__item.gac-with-border:before {
        left: -16px;
        right: -16px;
    }
    .gac-revision-btns {
        margin-bottom: 16px;
        span {
            width: 104px;
        }
    }
    .gac-account-wrapper {
        .gac-clients-page {
            .gac-create-client-btn,
            .gac-form-wrap,
            .gac-client {
                width: 100%;
                margin-left: 0 !important;
            }
        }
    }
    .gac-scroll-up-btn {
        right: 92px;
    }

    /* Message */
    .gac-message-form {
        padding: 16px;
    }
    .gac-message-block {
        padding: 0 46px 0 16px;
        margin-bottom: 16px;
        &.gac-message-right {
            padding-left: 46px;
            padding-right: 16px;
        }
        .gac-message-wrap {
            max-width: 100%;
            min-width: 100%;
        }
    }
    .gac-note {
        padding-left: 68px;
        padding-right: 16px;
        margin-bottom: 16px;
        .gac-note-icon {
            left: 16px;
        }
    }
    .gac-status-changed {
        padding: 0 16px;
    }

    /* Writing subs */
    .gac-plans-page {
        &__title {
            width: 100%;
            padding: 40px 90px;
            text-align: center;
        }
        .gac-sub-interval {
            padding: 0 0 32px;

            &--webbuilding-on-change-plan {
                margin-top: 40px;
            }
        }
    }
    .gac-radio-group {
        .gac-item {
            width: 112px;
        }
    }
    .gac-sub-plans {
        flex-direction: column;
        padding: 0 16px 0;

        &.gac-web-building-plans {
            .gac-item {
                padding-top: 16px !important;

                .gac-title {
                    line-height: 24px;
                    font-size: 20px;
                    text-align: left;
                }
                .gac-item-content {
                    margin-bottom: 0;
                    text-align: left;
                    .gac-item-content__p {
                        line-height: 22px;
                        font-size: 14px;
                    }
                }
            }
        }

        .gac-item {
            width: 343px !important;
            padding: 78px 15px 16px !important;
            margin-left: 0;
            margin-right: 0;
            &__discount-image {
                top: -23px;
                img {
                    max-width: 50px;
                    height: auto;
                }
            }
            .gac-label {
                justify-content: flex-end;
                svg {
                    transform: scale(0.68);
                    margin-right: -18px;
                }
            }
            .gac-svg {
                position: absolute;
                top: -13px;
                left: 15px;
            }
            .gac-title {
                position: absolute;
                top: 12px;
                left: 85px;
                margin: 0;
                font-size: 24px;
            }
            .gac-sub-price {
                position: absolute;
                top: 20px;
                right: 15px;
                margin: 0;
                padding: 0;
                font-size: 20px;
                line-height: 30px;
            }
            .gac-sub-full-price,
            .gac-total {
                position: absolute;
                top: 50px;
                right: 15px;
                margin: 0 !important;
                padding: 0;
            }
            .gac-subscribe-btn {
                position: absolute;
                bottom: 16px;
                right: 15px;
                width: 130px;
                height: 40px;
                line-height: 38px;
                font-size: 14px;
            }
        }
    }
    .gac-custom-sub-wrap {
        max-width: 343px;
        .gac-item-custom-sub {
            padding: 78px 15px 16px !important;
            .gac-svg {
                position: absolute;
                top: -13px;
                left: 15px;
            }
            .gac-title {
                position: absolute;
                top: 12px;
                left: 85px;
                margin: 0;
                line-height: 24px;
                font-size: 20px;
            }
            .gac-sub-input {
                position: absolute;
                top: 20px;
                right: 15px;
                margin: 0;
                padding: 0;
                font-size: 20px;
                line-height: 30px;
                input {
                    font-size: 16px;
                }
            }
            .gac-total {
                position: absolute;
                top: 50px;
                right: 15px;
                margin: 0 !important;
                padding: 0;
            }
            .gac-sub-hours {
                flex-direction: column;
                align-items: flex-start;
                strong,
                .gac-sub-level-hours {
                    line-height: 22px;
                    font-size: 14px;
                }
                .gac-or-svg {
                    margin: 8px 0;
                }
            }
            .gac-subscribe-btn {
                right: 15px;
                width: 130px;
                height: 40px;
                line-height: 38px;
                font-size: 14px;
            }
        }
    }

    /* Design plans */
    .gac-design-plans {
        padding-bottom: 0;
    }
    /* Payment confirm */
    .gac-payment-confirm-page {
        &__title {
            padding: 40px 70px;
            text-align: center;
        }
        .gac-payment-confirm-wrap {
            flex-direction: column;
            width: 343px;
            padding: 0 0 78px;
            &__item {
                margin: 0 0 20px;
                flex: 1 1 auto;
            }
            &__method {
                margin: 0;
            }
        }
        .gac-prepay-item {
            display: flex;
            align-items: center;
            padding: 0 20px 0 16px;
            .gac-prepay-wrap__icon {
                padding: 0;
                margin-right: 16px;
            }
            &__title {
                margin-bottom: 0;
                font-size: 28px;
            }
            &__amount {
                margin-left: auto;
            }
        }
        .gac-success-page {
            &__img {
                padding: 0 0 36px;
                &.writing,
                &.null {
                    transform: scale(0.92);
                }
            }
        }
    }

    .gac-subscriptions-page,
    .gac-subscription-single {
        padding-bottom: 30px;
        .gac-project-wrapper .gac-project-sidebar .gac-project-pages {
            display: flex;
        }
    }
}
@media only screen and (max-width: 701px) {
    /* Company new */
    .gac-company-new-page {
        &__title {
            padding: 40px 15px 18px;
            text-align: center;
            span {
                display: inline-block;
            }
        }
        &__title-v2 {
            padding-top: 20px;
            font-size: 26px;
        }
        &.apply-designers-page {
            .gac-company-new-page__svg {
                transform: scale(0.8);
            }
            .gac-company-new-page__agreement {
                display: block;
                position: relative;
                line-height: 22px;
                padding-left: 39px !important;
                label {
                    left: 16px;
                    margin-top: 3px;
                }
            }
        }
        &__agreement {
            width: 100% !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
            font-size: 14px !important;
            text-align: left !important;
            a {
                font-size: 14px !important;
            }
        }
    }
    .gac-company-new-form,
    .gac-company-new-textarea {
        width: 100%;
        padding: 0 16px;
        &__row {
            flex: 0 0 100%;
            &.gac-country-row {
                & > div:first-child {
                    flex: 1 1 100%;
                    max-width: calc(100% - 96px);
                }
            }
            &.gac-company-row {
                & > div:first-child {
                    flex: 0 0 calc(50% - 6px);
                    max-width: calc(50% - 6px);
                }
                & > div:last-child {
                    flex: 0 0 calc(50% - 5px);
                }
                .gac-select .gac-select-menu {
                    width: 180px;
                }
            }
        }
        &__title {
            padding: 30px 0 20px;
        }
    }
}
@media only screen and (max-width: 666px) {
    .gac-payments-page {
        .gac-search {
            flex-wrap: wrap;
            .gac-search-input-wrap {
                width: 100%;
                margin-bottom: 16px;
            }
            .gac-range-wrap {
                width: auto;
                flex: 1 1 auto;
                margin-left: 0;
                .gac-daypicker-wrap {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    .gac-design-images ul {
        justify-content: space-between;
        li {
            flex: calc(50% - 4px) 0 0;
            margin-right: 0;
            padding: 0 10px;
        }
    }
    .gac-brand-profiles ul {
        justify-content: space-between;
        li {
            flex: calc(50% - 4px) 0 0;
            margin-right: 0;
        }
    }
    .gac-design-desc {
        &__wrap {
            grid-template-columns: repeat(2, 1fr);
            .gac-design-desc-item:nth-child(3) {
                grid-column-start: auto;
                grid-column-end: auto;
                grid-row-start: auto;
                grid-row-end: auto;
            }
        }
    }
}
@media only screen and (max-width: 609px) {
    /* Project details */
    .gac-project-wrapper {
        .gac-project-sidebar {
            .gac-project-pages {
                div.uni-before,
                div.uni-after {
                    visibility: visible;
                }
            }
        }
    }
    /* Order page */
    .gac-new-project .gac-form-row .gac-form-field .gac-form-radio.gac-radio-job-group-writing {
        flex-wrap: wrap;
        .gac-radio-item {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                width: 33.33%;
                margin-bottom: 6px;
            }
            &:nth-child(4) {
                border-left: 1px solid $border-color;
            }
            &:nth-child(4),
            &:nth-child(5) {
                width: 50%;
            }
        }
    }
}
@media only screen and (max-width: 599px) {
    .gac-form-level .gac-hint .gac-hint-wrap .gac-hint-text {
        width: 269px;
    }
    .gac-row-match {
        .gac-hint {
            .gac-hint-wrap {
                .gac-hint-text {
                    width: 345px;
                    left: auto;
                    right: 0;
                    transform: translateX(0);
                    &:before {
                        left: auto;
                        right: 6px;
                        margin-right: -1px;
                    }
                }
            }
        }
    }
    .gac-row-order_on {
        .gac-hint {
            .gac-hint-wrap {
                .gac-hint-text {
                    width: 345px;
                    left: auto;
                    right: -85px;
                    transform: translateX(0);
                    &:before {
                        left: auto;
                        right: 84px;
                    }
                }
            }
        }
    }

    /* Design plans */
    .gac-design-plans-page .gac-radio-group {
        width: 100%;
        .gac-item {
            width: 33.3333%;
        }
    }
    .gac-order-page {
        .gac-new-project {
            .gac-form-row {
                &.gac-row-multiple.gac-row-order_on .gac-form-field:nth-child(2n) .gac-daypicker-wrap {
                    left: auto;
                    right: 0;
                }
                .gac-form-field {
                    &.gac-field-order_on {
                        flex: 0 0 50%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 572px) {
    /* Modals */
    .gac-modal {
        &.gac-writers-modal {
            .gac-writers-text-wrap {
                .gac-writers-text:last-child {
                    margin-top: 10px;
                }
            }
        }
        &.gac-rate-writer-modal {
            .gac-writer-score {
                flex-wrap: wrap;
            }
            .gac-writer-score .gac-score-item {
                margin-bottom: 5px;
                margin-left: 0 !important;
                margin-right: 5px;
            }
        }
    }
    .gac-new-project .gac-form-row .gac-form-field .gac-form-radio .gac-radio-item {
        width: auto;
        padding: 0 8px;
    }
}
@media only screen and (max-width: 499px) {
    /* 404 page */
    .gac-404-page {
        .gac-404-page-content {
            .gac-404-page-text {
                margin-bottom: -30px !important;
                font-size: 22px;
            }
            .gac-404-page-img-2 {
                transform: scale(0.5) !important;
            }
        }
    }
    /* Messages center */
    .gac-messages-center {
        &__wrap {
            width: 375px;
        }
    }
    /* Settings */
    .gac-account-wrapper {
        .gac-create-account-from {
            .gac-form-own-balance__currency {
                margin-top: 20px;
                margin-bottom: 0;
                flex: 0 0 100%;
            }
        }
    }
    /* Order page */
    .gac-attachment .gac-dropzone {
        margin-right: 20px;
        font-size: 14px;
        span {
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }
    }
    .gac-brief-example {
        font-size: 14px;
        span {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            background-position: -65px -740px;
        }
    }
    /* Modals */
    .gac-cancel-subscription {
        .gac-btn-wrap {
            .gac-btn {
                margin-right: 5px !important;
            }
            .gac-btn-v2 {
                margin-left: 5px !important;
            }
            .gac-btn,
            .gac-btn-v2 {
                font-size: 14px;
                padding: 0 12px;
            }
        }
    }
}
@media only screen and (max-width: 479px) {
    /* Images */
    .gac-free-images,
    .gac-library {
        .gac-free-images-pagination-wrap {
            .gac-free-images-pagination {
                .gac-btn.gac-btn-s {
                    min-width: auto;
                    padding: 0 14px;
                    font-size: 14px;
                }
            }
        }
    }
    /* Calendar */
    .gac-calendar {
        .gac-calendar-nav {
            .gac-calendar-mobile-nav {
                .gac-calendar-mobile-nav__header,
                .gac-calendar-mobile-nav__slider,
                .gac-calendar-mobile-nav__grid {
                    margin-left: -10px;
                    margin-right: -10px;
                }
            }
        }
    }
    /* Order */
    .gac-order-page {
        .gac-new-project {
            .gac-form-row {
                .gac-form-field {
                    &.gac-writer-level {
                        width: 100%;
                    }
                    .gac-form-radio#level {
                        width: 100%;
                        .gac-radio-item {
                            & > i {
                                display: none;
                            }
                            span {
                                width: 100%;
                                margin: 0;
                            }
                            flex-direction: column;
                            width: auto;
                            flex: 0 0 33.3333%;
                            line-height: 22px;
                            text-align: center;
                        }
                    }
                    .gac-form-input.gac-input-planned_publish {
                        width: auto;
                        flex: 1 1 50%;
                    }
                    .gac-timepicker {
                        width: auto;
                        flex: 1 1 50%;
                    }
                    .gac-form-radio.gac-radio-job-group-seo {
                        flex-wrap: wrap;
                        .gac-radio-item {
                            &:nth-child(1),
                            &:nth-child(2) {
                                width: 50%;
                                margin-bottom: 6px;
                            }
                            &:nth-child(3) {
                                width: 50%;
                                border-left: 1px solid $border-color;
                            }
                            &:nth-child(4) {
                                width: 50%;
                                border-left: 0;
                            }
                        }
                    }
                }
            }
            #currency {
                width: 100%;
            }
        }
    }
    .gac-input-written-by {
        .DayPickerInput-Overlay {
            left: auto;
            right: 0;
        }
    }
    .gac-design-images ul li {
        padding-left: 0;
        padding-right: 0;
        p {
            white-space: nowrap;
        }
    }
    .gac-design-desc {
        &__wrap {
            display: block;
            .gac-design-desc-item {
                margin-bottom: 16px;
            }
        }
    }
    .gac-design-list ul {
        display: block;
    }
    /* Settings */
    .gac-account-wrapper {
        .gac-create-account-from {
            .gac-form-row {
                .gac-form-field {
                    &.gac-billing-state {
                        max-width: calc(50% - 12px);
                    }
                    &.gac-zip-code {
                        max-width: calc(50% - 12px);
                    }
                    .gac-form-input#cname {
                        margin-right: 100px;
                        .gac-validate-cname-btn {
                            right: -100px;
                            min-width: 86px;
                            padding: 0 14px;
                            font-size: 14px;
                        }
                    }
                }
            }

            .gac-form-btns {
                flex-wrap: wrap;
                &.gac-two {
                    .gac-btn {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                    .gac-btn-v2 {
                        width: 100%;
                    }
                }
            }
        }
    }
    .gac-payments-info {
        .gac-payments-info-item {
            display: block;
            margin-bottom: 10px;
            .gac-add-bundle {
                top: 6px;
            }
        }
    }
    .gac-account-wrapper {
        .gac-account-sidebar {
            .gac-account-pages {
                position: relative;
                &.gac-start {
                    justify-content: flex-start;
                    &:before {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 40px;
                        height: 100%;
                        background: linear-gradient(to right, rgba(250, 252, 254, 0) 0%, rgba(250, 252, 254, 0.8) 100%);
                        content: "";
                        z-index: 1;
                    }
                }
                &.gac-center {
                    justify-content: center;
                    &:before {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 40px;
                        height: 100%;
                        background: linear-gradient(to right, rgba(250, 252, 254, 0) 0%, rgba(250, 252, 254, 0.8) 100%);
                        content: "";
                        z-index: 1;
                    }
                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        height: 100%;
                        background: linear-gradient(to left, rgba(250, 252, 254, 0) 0%, rgba(250, 252, 254, 0.8) 100%);
                        content: "";
                        z-index: 1;
                    }
                }
                &.gac-end {
                    justify-content: flex-end;
                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        height: 100%;
                        background: linear-gradient(to left, rgba(250, 252, 254, 0) 0%, rgba(250, 252, 254, 0.8) 100%);
                        content: "";
                        z-index: 1;
                    }
                }
            }
        }
        .gac-create-account-from .gac-form-row.gac-row-with-logo {
            display: block;
        }
    }
    .gac-field-favicon {
        .gac-hint .gac-hint-wrap .gac-hint-text {
            width: 290px;
            left: 91px;
            &:before {
                left: 53px;
                margin-left: 0;
            }
        }
    }
    /* Project details */
    .gac-project-wrapper {
        .gac-project-content {
            .gac-project-brief {
                .gac-brief-wrap {
                    .gac-form-row {
                        .gac-form-field {
                            .gac-form-radio {
                                width: 100%;
                                .gac-radio-item {
                                    width: auto;
                                    flex: 0 0 50%;
                                }
                            }
                        }
                    }
                }
            }
            .gac-project-overview {
                .gac-overview-wrap {
                    .gac-overview-field {
                        .gac-with-status {
                            justify-content: flex-end;
                            .gac-extend-date {
                                width: 100%;
                                justify-content: flex-end;
                                .gac-extend-date-line {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .gac-project-sharing {
                .gac-activity .gac-activity-list .gac-activity-row {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 8px;
                    .gac-activity-time {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .gac-project-ideas-wrap {
        .gac-project-idea {
            padding: 16px 0 0 0;
            .gac-idea-wrap {
                .gac-idea-status {
                    left: 0;
                }
                .gac-idea-title-wrap {
                    margin-left: 30px;
                    margin-bottom: 10px;
                }
                .gac-idea-desc {
                    margin-bottom: 16px;
                }
            }
            .gac-idea-keywords {
                margin-bottom: 16px;
            }
            .gac-idea-btns {
                justify-content: space-between;
                div {
                    margin-left: 0 !important;
                    margin-bottom: 8px !important;
                }
            }
        }
    }

    /* Messages */
    .gac-status-changed {
        .gac-status-text {
            left: 26px;
            padding: 9px 16px;
            font-size: 13px;
            transform: translateX(0);
        }
    }
    /* Modals */
    .gac-modal {
        &.gac-edit-project-modal {
            .gac-modal-btns {
                .gac-btn {
                    min-width: 110px;
                }
            }
        }
    }
    .gac-modal-create-brand .gac-btn-wrap {
        flex-direction: column;
        .gac-btn {
            margin: 0 0 10px 0;
        }
        .gac-btn-v2 {
            margin: 0;
        }
    }
    /* Notification */
    .gac-notification {
        &__wrap {
            max-width: calc(100% - 20px);
        }
    }
    /* Subscription */
    .gac-sub-btns {
        .gac-btn {
            width: 100%;
        }
    }
    /* Plans page */
    .gac-plans-page .gac-selectv2-menu {
        left: -50%;
    }
    .gac-prepay-wrap {
        max-width: calc(100% - 32px);
    }
}
@media only screen and (max-width: 410px) {
    .gac-daypicker-wrap {
        .DayPicker-Month {
            .DayPicker-Weekdays {
                .DayPicker-Weekday {
                    margin: 0 2px;
                }
            }
            .DayPicker-Week {
                .DayPicker-Day {
                    margin: 0 2px;
                }
            }
        }
    }
    .gac-new-project .gac-form-row .gac-form-field .gac-form-radio.gac-radio-job-group-writing {
        .gac-radio-item {
            &:nth-child(1),
            &:nth-child(2) {
                width: 50%;
                margin-bottom: 6px;
            }
            &:nth-child(3) {
                width: 50%;
                border-left: 1px solid $border-color;
            }
            &:nth-child(4) {
                border-left: 0;
            }
            &:nth-child(5) {
                width: 100%;
                border-left: 1px solid $border-color;
            }
        }
    }

    /* Images */
    .gac-free-images .gac-images-search .gac-search-input-wrap .gac-search-input,
    .gac-library .gac-images-search .gac-search-input-wrap .gac-search-input {
        padding-right: 50px;
    }
    .gac-search__total {
        display: none;
    }
}
@media only screen and (max-width: 399px) {
    /* Login */
    .gac-login-page {
        .gac-login-form {
            .gac-login-footer {
                a {
                    &:first-child {
                        margin-right: 10px;
                    }
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    /* Project details */
    .gac-project-wrapper {
        .gac-project-content {
            .gac-project-overview {
                .gac-overview-wrap {
                    .gac-overview-field {
                        .gac-overview-data {
                            .gac-daypicker-container {
                                position: relative;
                                &:before {
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    margin-top: -6px;
                                    width: 13px;
                                    height: 13px;
                                    background: url("../../images/sprite.svg") -165px -490px;
                                    content: "";
                                }
                                input {
                                    width: 113px;
                                    background-image: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 374px) {
    .gac-row-match .gac-hint .gac-hint-wrap .gac-hint-text {
        width: 290px;
    }
    .gac-row-order_on .gac-hint .gac-hint-wrap .gac-hint-text {
        width: 290px;
        right: -58px;
        &:before {
            left: auto;
            right: 57px;
        }
    }
    .gac-order-page {
        .gac-new-project {
            .gac-form-row {
                &.gac-form-level {
                    .gac-form-field-content .gac-hint .gac-hint-wrap .gac-hint-text {
                        width: 290px;
                    }
                }
                &.gac-used-services-row {
                    .gac-hint .gac-hint-wrap .gac-hint-text {
                        width: 330px;
                        left: -66px;
                    }
                }
                .gac-form-field {
                    &.gac-field-word-count .gac-word-count-wrap .gac-word-count-item {
                        margin-right: 1px;
                        padding: 10px 4px 11px;
                    }
                    &.gac-field-order_on {
                        input {
                            background-position: 132px center;
                        }
                    }
                }
            }
            .gac-form-btns span {
                min-width: auto;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
        .gac-confirm .gac-cart-money-back {
            padding-left: 40px;
            i {
                left: 10px;
            }
        }
    }
    /* Daypicker */
    .gac-daypicker-wrap {
        .DayPicker-wrapper {
            padding: 14px 9px 14px;
        }
        .DayPicker-Month {
            .DayPicker-Weekdays {
                .DayPicker-Weekday {
                    margin: 0 1px;
                }
            }
            .DayPicker-Week {
                .DayPicker-Day {
                    margin: 0 1px;
                }
            }
        }
        .DayPicker-NavButton {
            top: 10px;
            &.DayPicker-NavButton--prev {
                right: 54px;
            }
            &.DayPicker-NavButton--next {
                right: 24px;
            }
        }
    }
    /* Settings  */
    .gac-general-settings {
        .gac-account-wrapper {
            .gac-account-sidebar {
                .gac-account-pages {
                    li {
                        &.gac-active {
                            padding-left: 40px;
                            i {
                                left: 12px;
                            }
                        }
                        a {
                            padding-left: 40px;
                            i {
                                left: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 359px) {
    /* Modals */
    .gac-modal {
        &.gac-writers-modal {
            .gac-writers-text-wrap {
                .gac-select {
                    width: 89px;
                    margin-left: auto;
                    .gac-select-value {
                        padding: 0 30px 0 10px;
                    }
                    .gac-select-menu {
                        left: auto;
                        right: -1px;
                    }
                    &:after {
                        right: 14px;
                    }
                }
                .gac-select + .gac-writers-text {
                    display: none;
                }
            }
        }
    }
}

// MaxHeight
@media only screen and (max-height: 829px) {
    .gac-empty-state-v2 {
        .gac-empty-state-img {
            img {
                width: auto;
                max-height: 200px;
            }
        }
    }
}
@media only screen and (max-height: 829px) and (min-width: 768px) {
    .gac-modal.gac-create-account-modal {
        top: 15px;
        margin-top: 0 !important;
        max-height: calc(100vh - 30px);
        overflow: auto;
        padding-left: 88px;
        padding-right: 88px;
    }
}
@media only screen and (max-height: 799px) {
    /* 404 page */
    .gac-404-page {
        .gac-404-page-content {
            .gac-404-page-img-1 {
                margin-bottom: 20px;
                transform: scale(0.7);
            }
            .gac-404-page-text {
                margin-bottom: 10px;
                font-size: 22px;
            }
            .gac-404-page-img-2 {
                transform: scale(0.7);
            }
        }
    }
    /* Modals */
    .gac-modal-subscription {
        .gac-pause-desc .gac-start-date-wrap {
            .gac-daypicker-wrap {
                .DayPicker-wrapper {
                    padding: 14px 9px 6px;
                }
                .DayPicker-Month {
                    .DayPicker-Weekdays {
                        .DayPicker-Weekday {
                            margin: 0 1px;
                        }
                    }
                    .DayPicker-Week {
                        .DayPicker-Day {
                            margin: 0 1px;
                            height: 34px;
                            line-height: 34px;
                        }
                    }
                }
                .DayPicker-NavButton {
                    top: 10px;
                    &.DayPicker-NavButton--prev {
                        right: 54px;
                    }
                    &.DayPicker-NavButton--next {
                        right: 24px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-height: 729px) {
    .gac-project-details.gac-empty-content {
        height: auto;
    }
}
